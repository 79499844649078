
<template>
  <svg width="200px" height="200px" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 64 (93537) - https://sketch.com -->
    <title>toolicon/Crop_Manager</title>
    <desc>Created with Sketch.</desc>
    <g id="toolicon/Crop_Manager" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon id="Fill-71" fill="#E8E6D4" points="-4.26325641e-14 200.013 200.013 200.013 200.013 -4.26325641e-14 -4.26325641e-14 -4.26325641e-14"></polygon>
        <path d="M117.355,44.9595 C118.503,42.8075 122.14,42.2125 122.462,41.6965 C122.463,41.6945 122.467,41.6885 122.468,41.6865 C122.791,41.1715 121.732,37.6425 123.164,35.6705 C124.596,33.6975 131.366,34.3425 130.529,40.2685 C130.595,43.1765 128.348,44.1635 130.623,45.7545 C131.546,46.2065 133.49,48.0845 133.631,51.8745 C133.641,52.1315 133.633,60.2925 133.631,61.2215 L131.984,61.2195 C131.987,58.7205 131.992,52.1775 131.984,51.9205 C131.837,47.9775 129.832,47.6775 129.832,47.6775 C127.751,46.8975 126.582,48.3005 124.72,49.5555 C119.755,52.9065 116.209,47.1085 117.355,44.9595" id="Fill-73" fill="#004132"></path>
        <path d="M148.187,42.52 C147.04,40.37 143.403,39.774 143.08,39.258 C143.079,39.256 143.075,39.25 143.073,39.249 C142.75,38.734 143.81,35.206 142.378,33.233 C140.947,31.259 134.176,31.904 135.013,37.832 C134.947,40.739 137.194,41.726 134.919,43.317 C133.995,43.768 132.052,45.646 131.91,49.437 C131.901,49.695 131.909,62.72 131.91,63.65 L134.044,63.649 C134.041,61.149 134.038,49.739 134.044,49.483 C134.192,45.539 136.684,44.752 136.684,44.752 C138.766,43.973 138.96,45.863 140.823,47.118 C145.787,50.469 149.333,44.67 148.187,42.52" id="Fill-74" fill="#257150"></path>
        <path d="M109.1484,165.0649 C109.1484,165.0649 109.0514,157.7789 105.4804,154.7159 C101.7464,151.5159 94.7254,141.9509 97.6894,140.1239 C99.9504,138.7309 106.6594,145.6849 108.9324,154.3799 C109.5834,156.8749 109.1484,165.0649 109.1484,165.0649" id="Fill-75" fill="#004132"></path>
        <path d="M121.5635,140.1245 C119.3015,138.7305 112.5935,145.6855 110.3205,154.3805 C110.2935,154.4845 110.2645,154.5915 110.2345,154.6965 C110.0525,154.5285 109.8465,154.4245 109.6255,154.4245 C108.9015,154.4245 108.3135,155.4475 108.3135,156.7125 L108.3135,167.7835 L110.9395,167.7835 L110.9395,159.6845 C111.4715,157.8435 112.3445,155.9415 113.7725,154.7165 C117.5065,151.5165 124.5255,141.9505 121.5635,140.1245" id="Fill-76" fill="#257150"></path>
        <path d="M56.727,62.5688 C56.727,62.5688 54.346,54.3198 52.653,50.5648 C50.961,46.8098 48.956,42.4868 47.264,40.8928 C45.572,39.2998 43.19,38.6178 43.19,38.6178 C43.19,38.6178 44.631,41.0638 45.384,41.8608 C46.136,42.6568 48.956,45.6158 48.956,45.6158 C48.956,45.6158 49.645,50.1098 51.087,53.3528 C52.528,56.5948 56.727,63.6498 56.727,63.6498 L56.727,62.5688 Z" id="Fill-77" fill="#004132"></path>
        <path d="M56.1264,76.5757 C56.1264,76.5757 55.6034,67.7147 56.1264,63.2847 C56.6494,58.8537 57.6954,52.8407 57.6954,52.8407 C57.6954,52.8407 58.2454,63.0847 58.2454,66.4077 C58.2454,69.7297 57.6954,76.5757 57.6954,76.5757 L56.1264,76.5757 Z" id="Fill-78" fill="#257150"></path>
        <path d="M57.5264,67.7134 C57.5264,67.7134 61.6254,59.4004 62.8904,57.5624 C64.1544,55.7254 69.3164,48.9734 69.3164,48.9734 C69.3164,48.9734 66.8364,55.7714 64.9644,58.8484 C63.0924,61.9264 57.5264,67.7134 57.5264,67.7134" id="Fill-79" fill="#257150"></path>
        <path d="M147.8652,111.4536 C146.1712,114.0416 145.8712,119.8496 145.8712,119.8496 L147.8162,119.8496 C148.0842,115.9746 148.8882,113.7106 149.4252,112.8166 C149.6362,112.4636 150.0612,112.0556 150.4972,112.0716 C159.7352,112.4296 162.5682,104.8166 162.9372,102.0926 C163.5982,97.2046 160.9462,90.9416 160.9462,90.9416 C160.9462,90.9416 160.1002,92.0696 157.3442,94.1366 C155.2372,95.7166 150.9312,97.3186 148.0872,99.5366 C145.2422,101.7546 143.2652,105.7566 145.1462,109.2276 C145.6282,110.0956 146.5442,111.0596 146.5442,111.0596 C147.3152,109.0836 149.1962,107.5406 149.6782,107.1056 C151.7512,105.2736 153.8722,103.5386 155.5602,101.3206 C156.7652,99.7776 158.0192,97.9456 158.5012,96.0166 C157.9702,98.4756 156.7652,100.9346 155.3672,103.0086 C153.3902,106.1896 149.9462,108.2766 147.8652,111.4536" id="Fill-80" fill="#257150"></path>
        <path d="M53.3696,153.9595 L53.3696,146.9805 L55.5776,146.9805 C59.3446,146.9805 62.3976,144.0405 62.3976,140.4145 L62.3976,137.9025 L59.7886,137.9025 C56.8316,137.9025 54.3146,139.7155 53.3696,142.2485 L53.3696,136.2815 L55.5776,136.2815 C59.3446,136.2815 62.3976,133.3425 62.3976,129.7165 L62.3976,127.2045 L59.7886,127.2045 C56.8316,127.2045 54.3146,129.0175 53.3696,131.5505 L53.3696,125.5835 L55.5776,125.5835 C59.3446,125.5835 62.3976,122.6445 62.3976,119.0185 L62.3976,116.5065 L59.7886,116.5065 C56.0226,116.5065 52.9696,119.4455 52.9696,123.0715 L52.0086,123.1645 C52.0086,119.4455 48.9546,116.5065 45.1886,116.5065 L42.5796,116.5065 L42.5796,119.0185 C42.5796,122.6445 45.6326,125.5835 49.3996,125.5835 L51.6076,125.5835 L51.6076,131.5505 C50.6626,129.0175 48.1456,127.2045 45.1886,127.2045 L42.5796,127.2045 L42.5796,129.7165 C42.5796,133.3425 45.6326,136.2815 49.3996,136.2815 L51.6076,136.2815 L51.6076,142.2485 C50.6626,139.7155 48.1456,137.9025 45.1886,137.9025 L42.5796,137.9025 L42.5796,140.4145 C42.5796,144.0405 45.6326,146.9805 49.3996,146.9805 L51.6076,146.9805 L51.6076,153.9595 L53.3696,153.9595 Z" id="Fill-81" fill="#257150"></path>
        <path d="M125.5312,79.2319 L121.5242,81.6279 L135.3092,87.2679 L136.8642,72.4559 L133.1522,74.6749 C126.9752,65.0489 116.6412,58.2449 104.4222,57.0229 L103.6622,65.9529 C112.9062,66.9189 120.7512,72.0089 125.5312,79.2319 M90.3562,67.5769 L91.7952,71.5589 L99.9642,59.1049 L85.7202,54.7509 L87.3202,59.1749 C76.8082,63.0089 68.3012,71.2779 64.3002,81.9179 L72.6582,84.7819 C75.8192,76.7289 82.3362,70.4849 90.3562,67.5769 M70.6372,97.2109 L75.1412,96.8189 L65.2022,85.7259 L57.3362,98.3739 L61.8982,97.9749 C62.5672,108.9279 67.8332,118.8059 75.9392,125.4899 L81.3232,118.7129 C75.0672,113.4689 71.0482,105.7499 70.6372,97.2109 M98.0072,125.5949 C96.4332,125.4609 94.9052,125.1939 93.4182,124.8299 L94.5312,120.2169 L80.8522,126.1109 L90.3412,137.5909 L91.3992,133.2039 C93.3042,133.6679 95.2622,134.0079 97.2772,134.1799 C104.9862,134.8359 112.3542,133.1759 118.7132,129.7949 L114.7892,122.0969 C109.8262,124.7859 104.0522,126.1089 98.0072,125.5949 M138.9462,90.3429 L130.1662,91.2679 C130.5082,93.5419 130.6032,95.8869 130.4002,98.2779 C129.9052,104.1029 127.7642,109.3879 124.4872,113.7359 L121.2102,111.0969 L120.6962,125.9819 L135.1302,122.3079 L131.2672,119.1969 C135.6532,113.5099 138.5132,106.5419 139.1662,98.8539 C139.4132,95.9569 139.3232,93.1089 138.9462,90.3429" id="Fill-82" fill="#B4AF9B"></path>
        <path d="M99.7163,86.8198 L87.0723,99.4638 L89.2623,101.6538 L101.9063,89.0098 L104.3903,91.4938 L105.2943,83.3928 L97.1933,84.2978 L99.7163,86.8198 Z M106.4703,108.9488 L109.5673,108.9488 L109.5673,91.1518 L106.4703,91.1518 L106.4703,108.9488 Z M101.2633,108.9488 L104.3603,108.9488 L104.3603,97.3148 L101.2633,97.3148 L101.2633,108.9488 Z M90.8473,108.9488 L93.9443,108.9488 L93.9443,105.4068 L90.8473,105.4068 L90.8473,108.9488 Z M96.0553,108.9488 L99.1523,108.9488 L99.1523,102.4598 L96.0553,102.4598 L96.0553,108.9488 Z" id="Fill-83" fill="#233737"></path>
    </g>
</svg>
</template>

<script>
export default {
  name: 'CropManagerIcon',
};
</script>

<style scoped>

</style>
