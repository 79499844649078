
<template>
  <svg width="200px" height="200px" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Tool Icons/Pest-and-Disease-Radar</title>
    <g id="Tool-Icons/Pest-and-Disease-Radar" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon id="Fill-1" fill="#E8E6D4" points="0 200 200 200 200 0 0 0"></polygon>
        <g id="Radar" transform="translate(28.005000, 28.000000)">
            <path d="M144.9421,76.5314 C144.9421,114.1744 114.4261,144.6904 76.7831,144.6904 C39.1401,144.6904 8.6241,114.1744 8.6241,76.5314 C8.6241,38.8884 39.1401,8.3724 76.7831,8.3724 C114.4261,8.3724 144.9421,38.8884 144.9421,76.5314" id="Fill-2" fill="#D9D6BE"></path>
            <path d="M115.2831,76.5314 C115.2831,97.7944 98.0461,115.0314 76.7831,115.0314 C55.5201,115.0314 38.2831,97.7944 38.2831,76.5314 C38.2831,55.2684 55.5201,38.0314 76.7831,38.0314 C98.0461,38.0314 115.2831,55.2684 115.2831,76.5314" id="Fill-4" fill="#BBBBAF"></path>
            <path d="M86.3982,76.5314 C86.3982,81.8414 82.0932,86.1464 76.7832,86.1464 C71.4732,86.1464 67.1682,81.8414 67.1682,76.5314 C67.1682,71.2214 71.4732,66.9164 76.7832,66.9164 C82.0932,66.9164 86.3982,71.2214 86.3982,76.5314" id="Fill-6" fill="#5F696E"></path>
            <path d="M111.0755,9.7088 L77.1835,76.5318 L143.6285,42.2828 C136.4555,28.3128 125.0405,16.8898 111.0755,9.7088" id="Fill-8" fill="#5F696E"></path>
            <polygon id="Fill-10" fill="#BE5F46" points="31.958 100.473 51.366 100.473 51.366 81.065 31.958 81.065"></polygon>
            <polygon id="Fill-12" fill="#BE5F46" points="120.163 87.366 139.571 87.366 139.571 67.958 120.163 67.958"></polygon>
            <polygon id="Fill-13" fill="#BE5F46" points="89.842 124.87 105.369 124.87 105.369 109.343 89.842 109.343"></polygon>
            <polygon id="Fill-14" fill="#BE5F46" points="45 141.956 55 141.956 55 131.956 45 131.956"></polygon>
            <polygon id="Fill-15" fill="#BE5F46" points="0 61.953 61.953 61.953 61.953 0 0 0"></polygon>
            <path d="M50.275,8.5065 C50.661,8.4795 51.028,8.6875 51.201,9.0355 C51.374,9.3825 51.322,9.7975 51.066,10.0905 C47.947,13.6865 44.204,18.9145 42.942,27.4995 C42.005,33.8795 40.259,45.7525 27.973,46.6225 C23.429,46.9435 19.457,45.9635 16.405,43.8085 C14.681,47.6785 13.852,50.8795 13.961,52.4215 C13.998,52.9495 13.6,53.4085 13.072,53.4455 C12.543,53.4835 12.085,53.0845 12.047,52.5565 C11.705,47.7275 17.853,33.3525 28.906,23.8315 C29.033,23.7205 29.156,23.6105 29.285,23.5015 C29.699,23.1525 30.121,22.8115 30.549,22.4775 C30.979,22.1385 31.421,21.8095 31.869,21.4855 C32.167,21.2705 32.461,21.0515 32.766,20.8435 C33.564,20.3005 34.38,19.7735 35.234,19.2825 C35.692,19.0195 35.851,18.4305 35.587,17.9705 C35.326,17.5145 34.738,17.3575 34.274,17.6215 C22.953,24.1315 16.61,35.3265 14.41,39.8355 C14.258,40.1525 13.923,40.3415 13.595,40.3745 C13.246,40.3915 12.914,40.2165 12.732,39.9185 C11.547,37.9885 10.862,35.8025 10.693,33.4225 C10.225,26.8055 13.145,11.1345 50.275,8.5065 M31.54,28.6615 C29.198,28.8275 26.856,28.9935 27.021,31.3355 C27.07,32.0185 26.919,32.7155 26.743,33.4145 L26.59,34.0135 C26.214,35.5115 25.951,37.0015 27.519,38.3615 C30.026,40.5375 32.202,38.0295 32.037,35.6875 C31.871,33.3455 36.721,35.3565 36.555,33.0145 C36.389,30.6725 33.882,28.4965 31.54,28.6615" id="Fill-16" fill="#FFFFFF"></path>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  name: 'PestAndDiseaseRadarIcon',
};
</script>

<style scoped>

</style>
