
<template>
  <svg
  fill="none"
  height="300"
  viewBox="0 0 300 300"
  width="300"
  xmlns="http://www.w3.org/2000/svg">
  <rect
    fill="#E5E5E5"
    height="300"
    width="300" />
  <rect
    fill="#257150"
    height="300"
    width="300" />
  <circle
    cx="150"
    cy="150"
    fill="white"
    r="105" />
  <circle
    cx="150"
    cy="150"
    fill="#FF6C00"
    r="56"
    stroke="#257150"
    stroke-width="20" />
</svg>
</template>

<script>
export default {
  name: 'ConvisoIcon',
};
</script>

<style scoped>

</style>
