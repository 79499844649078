
<template>
  <svg width="200px" height="200px" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>tool-icon/Field-Scout</title>
    <defs>
        <path d="M20.474375,0 C9.1516264,0 0,9.40275661 0,21.0365674 C0,23.7082956 0.7592708,27.1017464 1.64,29.4511944 L20.5,69 L39.36,29.4511944 C40.2456738,27.1008208 41,23.7094737 41,21.0365674 C41,9.40275661 31.7972712,0 20.474375,0 Z M20.474375,10.0975524 C26.3622784,10.0975524 31.16,14.9870396 31.16,21.0365674 C31.16,27.0861793 26.3622784,31.9755825 20.474375,31.9755825 C14.5865536,31.9755825 9.84,27.0861793 9.84,21.0365674 C9.84,14.9870396 14.5865536,10.0975524 20.474375,10.0975524 Z" id="path-1"></path>
    </defs>
    <g id="tool-icon/Field-Scout" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon id="Background" fill="#E8E6D4" points="0 200 200 200 200 -8.52651283e-14 0 -8.52651283e-14"></polygon>
        <polygon id="Rectangle" fill="#407165" points="61.7272727 95.5 73.3181818 95.5 57.8636364 141.5 37 141.5"></polygon>
        <polygon id="Rectangle" fill="#407165" points="75.6363636 95.5 88 95.5 81.8181818 141.5 62.5 141.5"></polygon>
        <polygon id="Rectangle" fill="#407165" transform="translate(137.840909, 118.500000) scale(-1, 1) translate(-137.840909, -118.500000) " points="144.409091 95.5 156 95.5 140.545455 141.5 119.681818 141.5"></polygon>
        <polygon id="Rectangle" fill="#407165" transform="translate(117.750000, 118.500000) scale(-1, 1) translate(-117.750000, -118.500000) " points="118.136364 95.5 130.5 95.5 124.318182 141.5 105 141.5"></polygon>
        <polygon id="Rectangle" fill="#407165" points="90.3181818 95.5 102.681818 95.5 106.545455 141.5 86.4545455 141.5"></polygon>
        <g id="Group" transform="translate(118.000000, 104.000000)">
            <polygon id="Fill-5" fill="#233737" points="8.17124146e-14 58.5344828 43.4571429 58.5344828 43.4571429 3.55271368e-15 8.17124146e-14 3.55271368e-15"></polygon>
            <polygon id="Fill-5" fill="#FFFFFF" points="4 48.5299988 39.4599991 48.5299988 39.4599991 4 4 4"></polygon>
            <polygon id="Fill-23" fill="#FF6C00" points="19.6901471 20 15 14.6873103 16.905 13.2332414 19.7933824 16.5055172 28.1836765 8 30 9.54841379"></polygon>
            <polygon id="Fill-23-Copy" fill="#FF6C00" points="19.6901471 32 15 26.6873103 16.905 25.2332414 19.7933824 28.5055172 28.1836765 20 30 21.5484138"></polygon>
            <polygon id="Fill-23-Copy-2" fill="#FF6C00" points="19.6901471 44 15 38.6873103 16.905 37.2332414 19.7933824 40.5055172 28.1836765 32 30 33.5484138"></polygon>
            <circle id="Oval" fill="#919B9B" cx="22" cy="53" r="3"></circle>
        </g>
        <g id="icon_search_location_black-2" transform="translate(63.000000, 35.000000)">
            <mask id="mask-2" fill="white">
                <use xlink:href="#path-1"></use>
            </mask>
            <use id="icon_search_location_black" fill="#3A7391" fill-rule="nonzero" xlink:href="#path-1"></use>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  name: 'FieldScoutIcon',
};
</script>

<style scoped>

</style>
