
<template>
  <svg width="200px" height="200px" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Tool Icons/Field Zoning</title>
    <g id="Tool-Icons/Field-Zoning" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="zoning_KWS-icon-Zoning">
            <polygon id="Background" fill="#E8E6D4" points="0 0 200 0 200 200 0 200"></polygon>
            <polygon id="Rectangle-Copy-2_00000144317954328486222860000004967404220462466946_" fill="#AFB4B6" points="100 92 168 129 100 166.1 32 129"></polygon>
            <polygon id="Rectangle-Copy-2_00000101087939710748759050000006604446169673385129_" fill="#878F92" points="100 68 168 105 100 142.1 32 105"></polygon>
            <g id="Top-map" transform="translate(100.000000, 78.650001) scale(-1, 1) rotate(-180.000000) translate(-100.000000, -78.650001) translate(32.000000, 41.300001)">
                <polygon id="Rectangle-Copy_00000093159085528710137000000000787148076091619759_" fill="#FAB900" points="68 0 136 37.3 68 74.7 0 37.3"></polygon>
                <path d="M123.1,30.3 L136,37.4 L110.9,51.2 C110.9,51.2 108.7,45.4 105.8,41.7 C102.9,38 109.1,34.4 115.7,34.3 C120.9,34.2 121.1,32.2 123.1,30.3 Z" id="Path_00000155848709024478127570000005126676538793816502_" fill="#BE5F46"></path>
                <path d="M68,0 L106.3,21 C92.6,24.6 80.1,40.1 98,50.7 C100.2,52 102.1,53.6 103.1,55.3 L68,74.7 L16.5,46.4 C18.2,39.2 20.3,36.1 33.7,33.8 C47.8,31.5 50.9,21.7 46.3,13 L46.3,13 C46.2,12.8 46,12.3 45.9,12.1 L68,0 Z" id="Path_00000123438872055967427100000000415628080126906285_" fill="#96A02D"></path>
                <path d="M65.7,34.2 C72.1,34.2 72.2,38.5 72.6,40.5 C74.5,51.8 89.6,52.3 89.5004944,62.9 L68,74.7 L33.2,55.5 C33.9,55.2 40.1,52.2 46.2,48 C54.8,42 59.3,34.2 65.7,34.2 Z M77.7,13.4 C85.2,20.9 76.8,25.6 70.6,23.6 C63.5,21.3 69.4,18.5 65.6,14.9 C61.2,10.7 70.8,6.4 77.7,13.4 Z" id="Shape_00000065069198848492288530000011158785036125431731_" fill="#257150"></path>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  name: 'FieldZoningIcon',
};
</script>

<style scoped>

</style>
