
<template>
  <svg width="200px" height="200px" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 64 (93537) - https://sketch.com -->
    <title>Notification/E-Mail-Service</title>
    <desc>Created with Sketch.</desc>
    <g id="Notification/E-Mail-Service" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon id="Fill-1-Copy" fill="#E8E6D4" points="0 200 200 200 200 0 0 0"></polygon>
        <path d="M100.1806,109.8918 L100.2116,109.8918 C101.2976,109.8918 102.3706,109.5538 103.2396,108.9388 L103.4746,108.7768 C103.5256,108.7418 103.5786,108.7078 103.6326,108.6698 L106.3756,106.2388 L171.8486,48.3378 C171.7136,48.1368 171.4826,47.9998 171.2366,47.9998 L28.7606,47.9998 C28.5836,47.9998 28.4196,48.0688 28.2886,48.1838 L96.7886,108.6888 C97.6526,109.4548 98.8846,109.8918 100.1806,109.8918" id="Fill-1" fill="#FF6C00"></path>
        <path d="M81.7654,103.6964 L93.0034,113.6304 C94.9534,115.3404 97.5064,116.2814 100.2014,116.2814 C102.8854,116.2814 105.4414,115.3344 107.3954,113.6164 L107.3954,113.6164 L118.2804,103.9874 L171.9994,150.2424 L171.9994,150.3794 C171.9994,150.8244 171.6494,151.2004 171.2394,151.2004 L171.2394,151.2004 L28.7704,151.2004 C28.3534,151.2004 27.9994,150.8244 27.9994,150.3824 L27.9994,150.3824 L27.9994,150.2334 L81.7654,103.6964 Z M172.0004,56.4891 L172.0004,142.0521 L122.9804,99.8371 L172.0004,56.4891 Z M27.9999,56.2043 L77.0699,99.5493 L27.9999,142.0293 L27.9999,56.2043 Z" id="Combined-Shape" fill="#AFB4B6"></path>
    </g>
</svg>
</template>

<script>
export default {
  name: 'CultiventEmailServiceIcon',
};
</script>

<style scoped>

</style>
