
<template>
  <svg width="200px" height="200px" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>KWS-icon-Digital4Cast</title>
    <g id="Digital4Cast" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="tool-icon/VRS-Copy-25">
            <polygon id="Hintergrund" fill="#E8E6D4" points="0 0 200 0 200 200 0 200"></polygon>
            <g id="drone" transform="translate(46.666667, 36.000000)">
                <rect id="Rectangle-Copy-6" fill="#878F92" x="32" y="16" width="42.6666667" height="4"></rect>
                <rect id="Rectangle-Copy-4" fill="#5A6969" x="24" y="0" width="5.33333333" height="18.6666667"></rect>
                <rect id="Rectangle-Copy-8" fill="#5A6969" x="77.3333333" y="0" width="5.33333333" height="18.6666667"></rect>
                <rect id="Rectangle-Copy-9" fill="#5A6969" x="68" y="0" width="24" height="2.66666667"></rect>
                <rect id="Rectangle" fill="#878F92" x="20" y="9.33333333" width="13.3333333" height="16"></rect>
                <rect id="Rectangle-Copy-7" fill="#878F92" x="73.3333333" y="9.33333333" width="13.3333333" height="16"></rect>
                <polygon id="Rectangle-Copy-5" fill="#5A6969" points="14.6666667 0 38.6666667 0 38.6666667 2.66666667 14.6666667 2.66666667"></polygon>
                <polygon id="Rectangle-Copy-10" fill="#5A6969" points="42.6666667 13.3333333 64 13.3333333 60.952381 29.3333333 45.7142857 29.3333333"></polygon>
            </g>
            <g id="Group" transform="translate(84.000000, 70.666667)" fill="#64AAB9">
                <path d="M16.6720867,20 C10.9066964,20.000248 5.21287379,18.6990464 0,16.1899497 L1.63685637,12.7001651 C11.1738165,17.2346599 22.1920372,17.2346599 31.7289973,12.7001651 L33.3333333,16.1899497 C28.1245903,18.6998947 22.4340983,20.0011812 16.6720867,20 Z" id="Path"></path>
                <path d="M16.6720867,13.0315191 C11.9127283,13.0395348 7.21085295,11.9721236 2.90514905,9.90612881 L4.5203252,6.38321344 C12.2147746,10.0644832 21.1185587,10.0644832 28.8130081,6.38321344 L30.4390244,9.90612881 C26.1329691,11.9710773 21.4313272,13.0384354 16.6720867,13.0315191 Z" id="Path"></path>
                <path d="M16.6720867,5.99669353 C12.9247864,5.99909992 9.22322913,5.1581556 5.83197832,3.533959 L7.45799458,0 C13.29773,2.77974276 20.0464434,2.77974276 25.8861789,0 L27.5121951,3.533959 C24.1210606,5.15850209 20.4194262,5.99946392 16.6720867,5.99669353 Z" id="Path"></path>
            </g>
            <polygon id="Rectangle-Copy-3" fill="#AFB4B6" points="100 138.666667 162.666667 160 100 181.333333 37.3333333 160"></polygon>
            <polygon id="Rectangle-Copy-2" fill="#878F92" points="100 121.333333 162.666667 142.666667 100 164 37.3333333 142.666667"></polygon>
            <g id="top-map" transform="translate(37.333333, 104.000000)">
                <polygon id="Rectangle-Copy" fill="#257150" points="62.6666667 0 125.333333 21.3333333 62.6666667 42.6666667 0 21.3333333"></polygon>
                <path d="M110.713208,16.3558038 L125.333333,21.3333333 L100.612181,29.7499149 C98.9007424,27.5814264 100.262973,23.804502 107.391067,17.9267346 C108.352307,17.1341036 109.496626,16.6784495 110.713208,16.3558038 Z" id="Rectangle-Copy-14" fill="#FAB900"></path>
                <path d="M69.0384632,2.16916351 L100.198818,12.7768273 C98.8404673,13.8180929 96.0185278,14.8752308 94.5789621,15.4808408 C89.4976604,17.6184901 83.1563827,16.938121 78.7396996,20.236921 C76.3871094,21.9940597 76.4594063,25.8893133 76.7284226,28.8133254 C76.892462,30.5963143 77.801947,32.3004795 78.7204324,33.9833865 L79.063848,34.6135925 C79.4461209,35.3192918 79.8137123,36.0225052 80.1118903,36.727511 L62.6666667,42.6666667 L18.2218088,27.5340432 C32.7721374,3.91482948 49.1515508,35.0395315 62.6808501,16.4773996 C65.5566048,12.5318783 65.9706996,5.41084398 69.0384632,2.16916351 Z" id="Rectangle-Copy-12" fill="#96A02D"></path>
                <path d="M62.386091,32.2477562 C68.8982599,34.6644426 71.8924902,37.1084855 72.4565181,39.3349491 L62.6666667,42.6666667 L33.4348295,32.7165669 C36.9720164,31.6797753 40.478529,30.4161719 44.1374628,30.2795991 C50.2513575,30.0513929 56.6501713,30.1191384 62.386091,32.2477562 Z" id="Rectangle-Copy-16" fill="#FAB900"></path>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  name: 'DigitalForecastIcon',
};
</script>

<style scoped>

</style>
