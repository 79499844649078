<template>
  <div class="notification"
       :class="{ 'read': notification.readState === 'read' && !sticky }"
  >
    <!-- :style="{ backgroundColor: getBackgroundColour() }"-->
    <a v-if="notification.url && notification.url.length > 0 && this.sticky"
       :href=notification.url
       target="_blank"
       @click="clickLink"
       class="notification__content--stickylink">
    </a>
    <div class="notification__head"
         @click="changeNotificationState">

      <div class="notification__head--wrapper">
        <notification-icon className='notification__head--image'
                           :icon=this.notification.icon
                           :color="this.notification.iconColor"
                           :service=service
        >
        </notification-icon>
        <div class="notification__head--text"
             :class="{open:open}"
        >
          <div class="notification__head--text_origin">
            {{ renderDate(notification.createdAt) }} &verbar; {{ getServiceTranslation(service) }}
            <span v-if="['cultivent_email_service', 'mykws_email_service', 'kws_news'].includes(service)"
                  v-html="getCropTranslation(notification.crop)"
            ></span>
            <span v-if="notification.flagType !== 'normal'"
                  class="notification__head--text_flag"
                  :class="notification.flagType"
            >
              {{ this.translations["flag.important"] }}
            </span>
          </div>
          <div class="notification__head--text_title"
               :class="{open:open}"
          >
            {{ notification.title }}
          </div>
          <div class="notification__content"
               :class="{open:open}"
          >
            <vue-markdown>
              {{ notification.content }}
            </vue-markdown>
            <a v-if="notification.url && notification.url.length > 0 && !this.sticky"
               :href=notification.url
               target="_blank"
               @click="clickLink"
               class="notification__content--link">
              <template v-if="notification.urlLabel">
                {{ notification.urlLabel }}
              </template>
              <template v-else>
                {{ translations["Blank label_url standardtext"]["notification_action_btn_default"] }}
              </template>
            </a>
            <span v-else-if="this.sticky"
                  class="notification__content--link">
              {{ translations["sticky.url.link-title"] }}
            </span>
          </div>
        </div>
        <div class="notification__head--caret"
             v-if="!sticky"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1"
               class="notification__head--caret_icon"
               :class="{open:open}"
          >
            <g id="Icon/Up" stroke="none" stroke-width="1" fill="#333333" fill-rule="evenodd" transform="translate(11.768877, 12.000000) rotate(90.000000) translate(-11.768877, -12.000000)">
              <path d="M7.66818747,2 L17.5377542,11.8695667 L17.281,12.125 L17.2860482,12.1304333 L7.41648151,22 L6,20.5835185 L14.709,11.874 L6.25170597,3.41648151 L7.66818747,2 Z" id="notif-caret"/>
            </g>
          </svg>
        </div>
        <div class="notification__head--colorborder"
             :class="'crop_fill_color_' + notification.crop.toLowerCase()"
             v-else
        >

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationIcon from './NotificationIcon';

export default {
  name: 'Notification',
  components: {
    NotificationIcon,
  },
  props: {
    notification: Object,
    translations: Object,
    sticky: Boolean,
  },
  data() {
    return {
      open: false,
      errorMessage: '',
      service: '',
    };
  },
  methods: {
    renderDate(isoTimestamp) {
      const date = new Date(isoTimestamp);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      if (month < 10) {
        month = '0' + month;
      }
      let dt = date.getDate();
      if (dt < 10) {
        dt = '0' + dt;
      }
      return dt + '.' + month + '.' + year;
    },
    changeNotificationState() {
      if (!this.sticky) {
        this.updateReadState();
        if (!this.open) {
          this.$root.$emit('closeNotifications', false);
          window.dataLayer?.push({
            tracking: {
              action: 'open',
              headline: this.notification.title,
              meta: this.renderDate(this.notification.createdAt) + ' | ' + this.notification.sender,
            },
            event: 'farmers_cockpit_notification',
          });
        }
        this.open = !this.open;
      }
    },
    updateReadState() {
      if (this.notification.readState === 'unread' && this.open) {
        let notificationsUrl = '/v1/notifications/' + this.notification.id;
        if(this.$axios.defaults.baseURL) {
          notificationsUrl = this.$axios.defaults.baseURL + notificationsUrl;
        }
        this.$axios.patch(notificationsUrl, {
          readState: 'read',
        })
            .then((response) => {
              // TODO is that correct? ask for state ok?
              this.notification.readState = 'read';
            })
            .catch((error) => {
              this.errorMessage = error;
            });
      }
    },
    clickLink() {
      window.dataLayer?.push({
        tracking: {
          action: 'link_click',
          headline: this.notification.title,
          meta: this.renderDate(this.notification.createdAt) + ' | ' + this.notification.sender,
          url: this.notification.url,
        },
        event: 'farmers_cockpit_notification',
      });
    },
    getServiceTranslation(service) {
      let serviceName = '';
      if (Object.keys(this.translations).length === 0) {
        return serviceName;
      }
      Object.entries(this.translations).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        if (key === 'sender.name.' + service) {
          serviceName = value;
        }
      });
      return serviceName;
    },
    getCropTranslation(cropAbbr) {
      let cropName = '';
      if (!cropAbbr) {
        return cropName;
      }
      if (Object.keys(this.translations.crop).length === 0) {
        return cropName;
      }
      Object.entries(this.translations.crop).forEach(entry => {
        const key = entry[0];
        const value = entry[1];
        if (key === 'crop.' + cropAbbr.toLowerCase()) {
          cropName = '&ndash; &lsqb;' + value + '&rsqb;';
        }
      });
      return cropName;
    },
  },
  mounted() {
    this.$root.$on('closeNotifications', data => {
      if (this.open) {
        this.updateReadState();
      }
      if (!this.sticky) {
        this.open = data;
      }
    });
  },
  created() {
    this.service = this.notification.sender.toLowerCase();
    this.open = this.sticky;

    if (this.sticky && this.notification.content.startsWith('http')) {
      this.notification.content = '<a href="' + this.notification.content + '">' + this.translations['sticky.url.link-title'] + '</a>';
    }
  },
};
</script>

<style lang="scss">
.notification {
  display: table;
  width: 100%;
  background: white;
  margin-bottom: 2px;
  padding: 4px;
  position: relative;

  &:hover {
    background: rgba(255, 255, 255, 0.5);
  }

  &.unread {
    color: #333333;
  }
  &.read {
    color: #878F92;
  }

  &__head {
    display: table;
    width: 100%;

    &--wrapper {
      display: table-row;
      font-size: 0;
    }

    &--image,
    &--text,
    &--colorborder,
    &--caret {
      display: table-cell;
      vertical-align: top;
    }

    &--image {
      width: 50px;
      height: 50px;

      svg {
        width: 50px;
        height: 50px;
      }
    }

    &--text {
      padding: 0 4px;
      font-size: 14px;
      max-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.open {
        overflow: visible;
        white-space: normal;
      }

      &_flag {
        color: white;
        padding: 4px 6px 3px;
        font-size: 12px;
        margin-left: 8px;

        &.important {
          background: #ff6c00; //kws orange
        }
      }

      &_origin {
        margin-top: 5px;
      }

      &_title {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        margin-top: 5px;
        font-weight: bold;

        &:hover {
          text-decoration: underline;
        }

        &.open {
          overflow: visible;
        }
      }
    }

    &--caret {
      width: 38px;
      height: 24px;
      padding-top: 13px;
      text-align: center;

      &_icon {
        transition-property: transform;
        transition-duration: 0.4s;

        &.open {
          transform: rotate(-180deg)
        }
      }
    }

    &--colorborder {
      width: 3px;
      background: #FFFFFF;
      height: 100%;
      position: relative;
    }
  }

  &__content {
    display: none;
    height: 0;
    margin-top: 5px;

    &.open {
      display: block;
      height: auto;
    }

    &--link {
      color: #ff6c00; //kws orange
      text-decoration: none;
      font-weight: bold;
    }
  }

  .notification__content--stickylink {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
</style>
