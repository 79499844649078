
<template>
  <svg width="200px" height="200px" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 64 (93537) - https://sketch.com -->
    <title>Crossicon/Market</title>
    <desc>Created with Sketch.</desc>
    <g id="Crossicon/Market" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="Rectangle-Copy-15" fill="#E8E6D4" x="0" y="0" width="200" height="200"></rect>
        <path d="M145.01,70.11 L151.587,76.688 L151.587594,159.950999 L139.262577,159.950999 L139.262,75.913 L145.01,70.11 Z M130.018,85.247 L130.018813,159.950999 L117.693795,159.950999 L117.693,97.692 L130.018,85.247 Z M107.129,108.359 L107.129495,159.950999 L94.8044768,159.950999 L94.804,113.682 L101.86,113.68 L107.129,108.359 Z M84.24,113.685 L84.2401759,159.950999 L71.9151581,159.950999 L71.915,113.688 L84.24,113.685 Z M63.111,113.92 L63.111574,159.950999 L50.7865562,159.950999 L50.786,126.362 L63.111,113.92 Z" id="Shape" fill="#AFB4B6"></path>
        <polygon id="Path" fill="#FF6C00" points="159.69 38.07 128.25 46.33 134.78 52.86 93.87 94.18 55.35 94.18 39.63 110.04 44.41 125.75 61.27 108.73 99.79 108.72 145 63.08 151.43 69.51"></polygon>
    </g>
</svg>
</template>

<script>
export default {
  name: 'MarketIcon',
};
</script>

<style scoped>

</style>
