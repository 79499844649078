<template>
  <div id="notifications"
    :class="{ error: error }"
  >
    <div v-if="loading"
         class="loader"
    >
    </div>
    <div v-if="error"
         class="error"
    >
      <div class="error__headline h3">{{ errorHeadline }}</div>
      <p class="error__subline">{{  errorSubline }}</p>
      <button class="error__button button button--medium button--secondary"
              @click="retry"
      >
        {{ errorButton }}
      </button>
    </div>
    <template v-else-if="translations">
      <Notification v-for="notification in stickynotifications"
                    v-bind:key="notification.id"
                    v-bind:notification="notification"
                    v-bind:translations="translations"
                    v-bind:sticky="true"
      />
      <Notification v-for="notification in notifications"
           v-bind:key="notification.id"
           v-bind:notification="notification"
           v-bind:translations="translations"
      />
    </template>
  </div>
</template>

<script>
import Notification from './components/Notification.vue';

export default {
  name: 'Notifications',
  components: {
    Notification,
  },
  data() {
    return {
      loading: true,
      error: false,
      stickynotifications: [],
      notifications: [],
      translations: [],
      language: '',
      errorHeadline: 'No notifications found',
      errorSubline: 'The notification service is probably busy or offline',
      errorButton: 'Retry',

    };
  },
  created() {
    if (/^(.*;)?\s*Authorization\s*=/.test(document.cookie)) {
      this.$axios.defaults.headers.common.Authorization = 'Bearer ' + this.readCookie('Authorization');
    }

    const notificationElement = document.querySelector('#notifications');
    if (notificationElement) {
      let ajaxUrl = notificationElement.dataset.ajaxUrl;
      if (ajaxUrl) {
        if (ajaxUrl.endsWith('/')) {
          ajaxUrl = ajaxUrl.substring(0, ajaxUrl.length - 1);
        }
        this.$axios.defaults.baseURL = ajaxUrl;
      }
    }

    const language = document.getElementsByTagName('html')[0].getAttribute('lang');
    if (language.length > 0) {
      this.language = language;
    }

    this.fetchNotifications();
    this.fetchTranslations();
  },
  methods: {
    retry() {
      this.loading = true;
      this.error = false;

      if (this.notifications.length === 0) {
        this.fetchNotifications();
      }
      if (this.translations.length === 0) {
        this.fetchTranslations();
      }
    },
    fetchNotifications() {
      let stickyNotificationsUrl = '/v1/notifications/sticky?language=' + this.language;
      let notificationsUrl = '/v1/notifications?language=' + this.language;
      if (this.$axios.defaults.baseURL) {
        stickyNotificationsUrl = this.$axios.defaults.baseURL + stickyNotificationsUrl;
        notificationsUrl = this.$axios.defaults.baseURL + notificationsUrl;
      }

      const requestOne = this.$axios.get(stickyNotificationsUrl);
      const requestTwo = this.$axios.get(notificationsUrl);

      this.$axios
          .all([
            requestOne,
            requestTwo,
          ])
          .then(this.$axios.spread((...responses) => {
            const responseOne = responses[0];
            const responseTwo = responses[1];

            this.stickynotifications = responseOne.data.slice(0, 3);
            this.notifications = responseTwo.data;

            this.loading = false;
          })).catch(errors => {
        this.loading = false;
        this.error = true;
        console.log(errors);
      });
    },
    fetchTranslations() {
      let translationsUrl = '/v1/translation?language=' + this.language;
      if(this.$axios.defaults.baseURL) {
        translationsUrl = this.$axios.defaults.baseURL + translationsUrl;
      }
      this.$axios.get( translationsUrl)
        .then((response) => {
          this.translations = response.data;
          this.errorHeadline = this.translations['error.offline.headline'];
          this.errorSubline = this.translations['error.offline.subline'];
          this.errorButton = this.translations['error.offline.button'];
        })
        .catch(error => {
          this.loading = false;
          this.error = true;
          console.log(error);
        });
    },
    readCookie(name) {
      const nameEQ = name + '=';
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
@import "assets/sticky-colors.scss";
#notifications {
  width: 100%;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

  * {
    box-sizing: border-box;
  }

  .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(./assets/loader_large.gif);
    width: 150px;
    height: 150px;
  }

  &.error {
    height: 90%;
  }

  .error {
    width: 80%;
    position: relative;
    top: 35%;
    text-align: center;
    margin: 10px auto;

    &__button {
      margin-top: 25px;
    }
  }
}
</style>
