
<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200">
  <g fill="none" fill-rule="evenodd">
    <polygon fill="#E8E6D4" points="0 200 200 200 200 0 0 0"/>
    <g transform="translate(37 33)">
      <polygon fill="#FAB900" points="85.928 0 81.626 12.88 70.869 4.609 72.196 18.101 58.975 14.916 65.696 26.687 52.3 29.142 63.253 37.156 52 44.822 65.294 47.692 58.123 59.245 71.457 56.479 69.616 69.925 80.683 61.991 84.487 75 91.378 63.281 100.167 73.609 101.688 60.127 113.943 65.982 109.831 53.07 123.439 53.443 114.402 43.329 127 38.156 114.609 32.592 124.025 22.769 110.418 22.714 115.022 9.937 102.547 15.407 101.542 1.884 92.363 11.928"/>
      <g transform="translate(0 25)">
        <path fill="#FFF" d="M118.995658,46.2275908 C118.995658,35.8894793 110.370761,27.5088262 99.7275147,27.5088262 C98.6245165,27.5088262 97.5535556,27.6203558 96.5009017,27.7910643 C93.6266989,20.9672734 86.7318158,16.1555676 78.6721484,16.1555676 C77.6629737,16.1555676 76.6789711,16.2511644 75.715564,16.3968358 C72.4157229,7.13532633 63.3674761,0.482243995 52.7173648,0.482243995 C41.5432148,0.482243995 32.1356927,7.79995173 29.2775086,17.7647805 C28.0806868,17.5986241 26.8609814,17.5075796 25.6206805,17.5075796 C11.4693507,17.5075796 0,28.6537117 0,42.4014426 C0,56.1446212 11.4693507,67.2884772 25.6206805,67.2884772 C28.3850411,67.2884772 31.0441364,66.8582916 33.5407568,66.070756 C37.4882089,70.5023507 43.312131,73.3179042 49.8179898,73.3179042 C56.3032531,73.3179042 62.1088682,70.5182835 66.0586087,66.10945 C69.2943752,68.0031771 73.0770641,69.1161971 77.1297816,69.1161971 C82.8804756,69.1161971 88.0911124,66.9129183 91.9538945,63.343971 C94.3315193,64.3682225 96.9585773,64.9463555 99.7275147,64.9463555 C110.370761,64.9463555 118.995658,56.5657024 118.995658,46.2275908"/>
        <path fill="#3A7391" d="M17.961708 87.1641908L18.046378 87.1641908 11.442119 73.318587 4.84014838 87.1641908 4.92481837 87.1641908C4.7028457 87.8333685 4.5769849 88.5435161 4.5769849 89.2878056 4.5769849 93.0365659 7.64798822 96.0797309 11.442119 96.0797309 15.2339614 96.0797309 18.3072531 93.0365659 18.3072531 89.2878056 18.3072531 88.5435161 18.1813923 87.8333685 17.961708 87.1641908M38.5593987 98.5447627L38.6440687 98.5447627 32.0375213 84.699159 25.4355507 98.5447627 25.5202207 98.5447627C25.298248 99.2139404 25.1723872 99.9240881 25.1723872 100.668377 25.1723872 104.417138 28.2456789 107.460303 32.0375213 107.460303 35.8316521 107.460303 38.9026554 104.417138 38.9026554 100.668377 38.9026554 99.9240881 38.779083 99.2139404 38.5593987 98.5447627M61.4408907 93.9948101L61.5255606 93.9948101 54.9213016 80.1469302 48.319331 93.9948101 48.404001 93.9948101C48.1820283 94.6617116 48.0561675 95.3718593 48.0561675 96.1161487 48.0561675 99.8671852 51.1271709 102.908074 54.9213016 102.908074 58.713144 102.908074 61.7864357 99.8671852 61.7864357 96.1161487 61.7864357 95.3718593 61.6628633 94.6617116 61.4408907 93.9948101"/>
      </g>
    </g>
  </g>
</svg>
</template>

<script>
export default {
  name: 'SuKwsWeatherIcon',
};
</script>

<style scoped>

</style>
