<template>
  <div :class="className">
    <component :is="currentIcon" :fill="color"></component>
  </div>
</template>

<script>
import BeetseedIcon from './icons/BeetseedIcon.vue';
import ConsultantsIcon from './icons/ConsultantsIcon.vue';
import ConvisoIcon from './icons/ConvisoIcon.vue';
import CornseedIcon from './icons/CornseedIcon.vue';
import CropManagerIcon from './icons/CropManagerIcon.vue';
import CultiventEmailServiceIcon from './icons/CultiventEmailServiceIcon.vue';
import DigitalForecastIcon from './icons/DigitalForecastIcon.vue';
import DiseaseIcon from './icons/DiseaseIcon.vue';
import DroneImageAnalyzerIcon from './icons/DroneImageAnalyzerIcon.vue';
import DrymatterIcon from './icons/DrymatterIcon.vue';
import EmailIcon from './icons/EmailIcon.vue';
import F4NBiomassIcon from './icons/F4NBiomassIcon.vue';
import FieldManagementIcon from './icons/FieldManagementIcon.vue';
import FieldScoutIcon from './icons/FieldScoutIcon.vue';
import FieldVitalityCheckCerealIcon from './icons/FieldVitalityCheckCerealIcon.vue';
import FieldVitalityCheckCornIcon from './icons/FieldVitalityCheckCornIcon.vue';
import FieldVitalityCheckOilseedRapeIcon from './icons/FieldVitalityCheckOilseedRapeIcon.vue';
import FieldVitalityCheckSugarbeetIcon from './icons/FieldVitalityCheckSugarbeetIcon.vue';
import FieldVitalityIcon from './icons/FieldVitalityIcon.vue';
import FieldZoningIcon from './icons/FieldZoningIcon.vue';
import FrostAlertIcon from './icons/FrostAlertIcon.vue';
import FrostwarningIcon from './icons/FrostwarningIcon.vue';
import InfestationIcon from './icons/InfestationIcon.vue';
import InformationIcon from './icons/InformationIcon.vue';
import KwsNewsIcon from './icons/KwsNewsIcon.vue';
import LeafScanIcon from './icons/LeafScanIcon.vue';
import MarketIcon from './icons/MarketIcon.vue';
import MarketingIcon from './icons/MarketingIcon.vue';
import MykwsEmailServiceIcon from './icons/MykwsEmailServiceIcon.vue';
import MykwsIcon from './icons/MykwsIcon.vue';
import NewsIcon from './icons/NewsIcon.vue';
import PestAndDiseaseRadarIcon from './icons/PestAndDiseaseRadarIcon.vue';
import PestIcon from './icons/PestIcon.vue';
import RapeseedIcon from './icons/RapeseedIcon.vue';
import SampleServiceIcon from './icons/SampleServiceIcon.vue';
import SamplingServiceIcon from './icons/SamplingServiceIcon.vue';
import SatDmMonitoringIcon from './icons/SatDmMonitoringIcon.vue';
import SaveMykwsEmailServiceIcon from './icons/SaveMykwsEmailServiceIcon.vue';
import ShopIcon from './icons/ShopIcon.vue';
import SoilSampleIcon from './icons/SoilSampleIcon.vue';
import SuKwsMarketIcon from './icons/SuKwsMarketIcon.vue';
import SuKwsWeatherIcon from './icons/SuKwsWeatherIcon.vue';
import VariableRateNitrogenIcon from './icons/VariableRateNitrogenIcon.vue';
import VariableRateSowingCerealIcon from './icons/VariableRateSowingCerealIcon.vue';
import VariableRateSowingIcon from './icons/VariableRateSowingIcon.vue';
import VariableRateSowingMaizeIcon from './icons/VariableRateSowingMaizeIcon.vue';
import VariableRateSowingOilseedrapeIcon from './icons/VariableRateSowingOilseedrapeIcon.vue';
import VariableRateSowingSugarbeetIcon from './icons/VariableRateSowingSugarbeetIcon.vue';
import VariableRateSowingSugarbeetNewIcon from './icons/VariableRateSowingSugarbeetNewIcon.vue';

const iconMapping = {
  '_save_mykws_email_service': SaveMykwsEmailServiceIcon,
  'beetseed': BeetseedIcon,
  'consultants': ConsultantsIcon,
  'conviso': ConvisoIcon,
  'cornseed': CornseedIcon,
  'crop_manager': CropManagerIcon,
  'cultivent_email_service': CultiventEmailServiceIcon,
  'digital_forecast': DigitalForecastIcon,
  'disease': DiseaseIcon,
  'drone_image_analyzer': DroneImageAnalyzerIcon,
  'drymatter': DrymatterIcon,
  'e-mail': EmailIcon,
  'f4n_biomass': F4NBiomassIcon,
  'field_management': FieldManagementIcon,
  'field_scout': FieldScoutIcon,
  'field_vitality': FieldVitalityIcon,
  'field_vitality_check_cereal': FieldVitalityCheckCerealIcon,
  'field_vitality_check_corn': FieldVitalityCheckCornIcon,
  'field_vitality_check_oilseed_rape': FieldVitalityCheckOilseedRapeIcon,
  'field_vitality_check_sugarbeet': FieldVitalityCheckSugarbeetIcon,
  'field_zoning': FieldZoningIcon,
  'frost_alert': FrostAlertIcon,
  'frostwarning': FrostwarningIcon,
  'infestation': InfestationIcon,
  'information': InformationIcon,
  'kws_news': KwsNewsIcon,
  'leaf_scan': LeafScanIcon,
  'market': MarketIcon,
  'marketing': MarketingIcon,
  'mykws': MykwsIcon,
  'mykws_email_service': MykwsEmailServiceIcon,
  'news': NewsIcon,
  'pest': PestIcon,
  'pest_and_disease_radar': PestAndDiseaseRadarIcon,
  'rapeseed': RapeseedIcon,
  'sample_service': SampleServiceIcon,
  'sampling_service': SamplingServiceIcon,
  'sat_dm_monitoring': SatDmMonitoringIcon,
  'shop': ShopIcon,
  'soil_sample': SoilSampleIcon,
  'su_kws_market': SuKwsMarketIcon,
  'su_kws_weather': SuKwsWeatherIcon,
  'variable_rate_nitrogen': VariableRateNitrogenIcon,
  'variable_rate_sowing': VariableRateSowingIcon,
  'variable_rate_sowing_cereal': VariableRateSowingCerealIcon,
  'variable_rate_sowing_maize': VariableRateSowingMaizeIcon,
  'variable_rate_sowing_oilseedrape': VariableRateSowingOilseedrapeIcon,
  'variable_rate_sowing_sugarbeet': VariableRateSowingSugarbeetIcon,
  'variable_rate_sowing_sugarbeet_new': VariableRateSowingSugarbeetNewIcon,
};

export default {
  props: {
    service: {
      type: String,
      required: true,
      default: 'email',
    },
    icon: {
      type: String,
    },
    className: {
      type: String,
    },
    color: {
      type: String,
    },
  },
  computed: {
    currentIcon() {
      if (this.icon && iconMapping[this.icon]) {
        return iconMapping[this.icon];
      }
      if (this.service && iconMapping[this.service]) {
        return iconMapping[this.service];
      }
      return EmailIcon;
    },
  },
};
</script>

<style lang="scss">
/* Your styles here */
</style>
