
<template>
  <svg width="200px" height="200px" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 64 (93537) - https://sketch.com -->
    <title>toolicon/Field_Vitality_Check</title>
    <desc>Created with Sketch.</desc>
    <g id="toolicon/Field_Vitality_Check" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="KWS-icon-Field-Vitality-Check-RGB">
            <polygon id="Path" fill="#E8E6D4" points="0 0 200 0 200 200 0 200"></polygon>
            <polygon id="Path" fill="#96A02D" fill-rule="nonzero" points="69.91 118.46 86.55 89.82 100.39 138.22 111.53 118.41 139.96 118.75 129.44 80 62.4 80 51.26 118.74"></polygon>
            <polygon id="Path" fill="#96A02D" fill-rule="nonzero" points="141.64 124.95 115.12 124.63 98.51 154.14 84.6 105.47 73.5 124.58 49.48 124.95 38.56 162.94 151.94 162.94"></polygon>
            <g id="Group" transform="translate(38.000000, 52.000000)" fill-rule="nonzero">
                <path d="M31.91,66.46 L32.02,66.27 C31.4,65.19 30.79,64.04 30.12,62.78 C27,57 26.59,52 23.06,52 C20.7066667,52 18.5,53.3866667 16.44,56.16 L13.37,66.76 L31.91,66.46 Z" id="Path" fill="#257150"></path>
                <path d="M63.54,84.16 C62.8971681,83.6890184 62.1985265,83.2993914 61.46,83 L62.39,86.23 L63.54,84.16 Z" id="Path" fill="#257150"></path>
                <path d="M60.51,102.14 L54.62,81.53 C50.52,81.03 46.13,80.53 42.47,77.73 C40.1175337,75.9634102 37.9317445,73.9851538 35.94,71.82 L35.5,72.58 L11.57,73 L0.57,111 L86.16,111 C77.6933333,101.273333 71.58,94.0166667 67.82,89.23 L60.51,102.14 Z" id="Path" fill="#257150"></path>
                <path d="M20.29,82.18 C23.89,80.56 26.5366667,80.56 28.23,82.18 C30.78,84.6 34.84,84.18 38.07,86.18 C41.3,88.18 41.68,92.34 40.62,95.89 C39.56,99.44 23.42,102.56 19.62,100.13 C15.82,97.7 13.08,95.63 15.8,90.43 C17.6,86.9366667 19.0966667,84.1866667 20.29,82.18 L20.29,82.18 Z" id="Path" fill="#004132"></path>
                <path d="M39.07,54.14 L48.55,37.82 L55.13,60.82 C60.63,61.13 66.79,60.82 70.44,61.89 C73.1426265,62.6240047 75.5329855,64.2175774 77.25,66.43 L101.96,66.73 L91.48,28.13 L91.48,28 L24.48,28 L22.98,33.23 C24.24,35.56 30.56,46.73 39.07,54.14 Z" id="Path" fill="#DCB900"></path>
                <path d="M46.6,53.47 L44.07,57.83 C45.0618725,58.4339492 46.0943371,58.9685587 47.16,59.43 C47.5746604,59.6005959 47.9986768,59.7474992 48.43,59.87 L46.6,53.47 Z" id="Path" fill="#DCB900"></path>
                <path d="M103.64,73 L81.12,72.73 C81.74,73.8 82.41,74.9 83.18,76.02 C88.1466667,83.18 97.36,91 110.82,99.48 L103.64,73 Z" id="Path" fill="#DCB900"></path>
                <path d="M48.55,37.82 L50.37,44.17 C50.6684293,44.3843382 50.9789423,44.5813303 51.3,44.76 C64.53,52.31 80.86,44.76 87.47,44.76 C91.89,44.76 95.5566667,47.8333333 98.47,53.98 L91.41,27.98 L37.62,27.98 C40.1963782,32.6021179 43.2533038,36.9394663 46.74,40.92 L48.55,37.82 Z" id="Path" fill="#FAB900"></path>
                <path d="M50.85,28 C52.1433333,33.3333333 56.1166667,36.7833333 62.77,38.35 C72.77,40.73 75.56,35.76 81.3,35.76 C85.12,35.76 89.99,38.24 94.69,39.96 L91.44,27.96 L50.85,28 Z" id="Path" fill="#CC3333"></path>
                <path d="M65.83,5.89 C68.0065201,7.14705791 70.6274078,7.37766674 72.99,6.52 L73.41,7.99 C70.6472743,8.88979887 67.632365,8.57357257 65.1164667,7.12011204 C62.6005685,5.6666515 60.8205789,3.21281487 60.22,0.37 L61.69,7.10542736e-15 C62.1345585,2.47750543 63.6494262,4.63271583 65.83,5.89 L65.83,5.89 Z M74.06,10.3 L74.55,12.04 C70.6751348,13.2445759 66.4743149,12.7892172 62.9475651,10.7823242 C59.4208154,8.77543111 56.8836308,5.39653574 55.94,1.45 L57.68,1 C58.4507006,4.50810336 60.6752493,7.52372154 63.799509,9.29566124 C66.9237686,11.0676009 70.6536369,11.4290605 74.06,10.29 L74.06,10.3 Z M75.28,14.63 L75.77,16.36 C70.709912,17.8968404 65.2413007,17.2957196 60.635865,14.6964253 C56.0304292,12.097131 52.6895022,7.72617434 51.39,2.6 L53.12,2.16 C54.2499028,6.84925522 57.2798414,10.8570114 61.4834483,13.2224888 C65.6870553,15.5879661 70.6853171,16.0978841 75.28,14.63 L75.28,14.63 Z" id="Shape" fill="#5F696E"></path>
            </g>
            <polygon id="Path" fill="#B4CDD7" fill-rule="nonzero" transform="translate(128.419837, 48.599791) rotate(-63.000000) translate(-128.419837, -48.599791) " points="124.969837 40.8297911 131.869837 40.8297911 131.869837 56.369791 124.969837 56.369791"></polygon>
            <polygon id="Path" fill="#B4CDD7" fill-rule="nonzero" transform="translate(142.881258, 55.971747) rotate(-63.000000) translate(-142.881258, -55.971747) " points="139.431258 48.2017468 146.331258 48.2017468 146.331258 63.7417467 139.431258 63.7417467"></polygon>
            <polygon id="Path" fill="#B4CDD7" fill-rule="nonzero" transform="translate(124.970224, 55.368369) rotate(-63.000000) translate(-124.970224, -55.368369) " points="121.520224 47.5983692 128.420224 47.5983692 128.420224 63.1383692 121.520224 63.1383692"></polygon>
            <polygon id="Path" fill="#B4CDD7" fill-rule="nonzero" transform="translate(139.418195, 62.734695) rotate(-63.000000) translate(-139.418195, -62.734695) " points="135.968195 54.9646951 142.868194 54.9646951 142.868194 70.504695 135.968195 70.504695"></polygon>
            <polygon id="Path" fill="#5F696E" points="117.8 43.19 122.45 41.68 115.86 54.6 114.36 49.96"></polygon>
            <polygon id="Path" fill="#B4CDD7" fill-rule="nonzero" transform="translate(93.900106, 39.535272) rotate(-63.000000) translate(-93.900106, -39.535272) " points="90.4501058 31.7652722 97.3501057 31.7652722 97.3501057 47.3052721 90.4501058 47.3052721"></polygon>
            <polygon id="Path" fill="#B4CDD7" fill-rule="nonzero" transform="translate(79.438685, 32.173316) rotate(-63.000000) translate(-79.438685, -32.173316) " points="75.9886851 24.4033165 82.8886851 24.4033165 82.8886851 39.9433164 75.9886851 39.9433164"></polygon>
            <polygon id="Path" fill="#B4CDD7" fill-rule="nonzero" transform="translate(97.354259, 32.767784) rotate(-63.000000) translate(-97.354259, -32.767784) " points="93.9042588 24.997784 100.804259 24.997784 100.804259 40.5377839 93.9042588 40.5377839"></polygon>
            <polygon id="Path" fill="#B4CDD7" fill-rule="nonzero" transform="translate(82.892838, 25.405828) rotate(-63.000000) translate(-82.892838, -25.405828) " points="79.4428382 17.6358283 86.3428381 17.6358283 86.3428381 33.1758282 79.4428382 33.1758282"></polygon>
            <rect id="Rectangle" fill="#233737" fill-rule="nonzero" transform="translate(111.155516, 44.065262) rotate(-63.000000) translate(-111.155516, -44.065262) " x="106.320516" y="39.2302617" width="9.66999997" height="9.66999997" rx="2"></rect>
            <polygon id="Path" fill="#5F696E" points="104.51 44.94 99.87 46.45 106.45 33.53 107.96 38.17"></polygon>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  name: 'FieldVitalityIcon',
};
</script>

<style scoped>

</style>
