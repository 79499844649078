import { createApp } from 'vue';
import axios from 'axios';
import Notifications from './Notifications.vue';
import VueMarkdown from 'vue-markdown/src/VueMarkdown';
import InlineSvg from 'vue-inline-svg';

// Create Vue app
const app = createApp(Notifications);

// Set axios as a global property
app.config.globalProperties.$axios = axios;

// Register components globally
app.component('vue-markdown', VueMarkdown);
app.component('inline-svg', InlineSvg);

// Mount the app
app.mount('#notifications');
