
<template>
  <svg width="34" height="50" viewBox="0 0 34 50" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_123_113)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17 1C8.154 1 1 8.355 1 17.45C1 29.788 17 48 17 48C17 48 33 29.788 33 17.45C33 8.355 25.846 1 17 1Z" class="coloricon" fill="#000000"/>
    <path d="M17 0C26.399 0 34 7.825 34 17.5C34 30.625 17 50 17 50L16.691 49.641C14.389 46.94 0 29.575 0 17.5C0 7.825 7.601 0 17 0ZM17 2C8.706 2 2 8.939 2 17.521C2 20.213 2.82 23.442 4.367 27.075C5.779 30.392 7.75 33.941 10.137 37.602C11.955 40.39 13.906 43.079 15.857 45.566L16.429 46.29L17 47L17.571 46.29L18.143 45.566C20.094 43.079 22.045 40.39 23.863 37.602C26.25 33.941 28.221 30.392 29.633 27.075C31.18 23.442 32 20.213 32 17.521C32 8.939 25.294 2 17 2Z" fill="white"/>
    <path d="M8 10.5C8 10.1022 8.15804 9.72064 8.43934 9.43934C8.72064 9.15804 9.10218 9 9.5 9H12.5V10.5H9.5V13.5H8V10.5ZM26 25.5C26 25.8978 25.842 26.2794 25.5607 26.5607C25.2794 26.842 24.8978 27 24.5 27H21.5V25.5H24.5V22.5H26V25.5ZM9.5 27C9.10218 27 8.72064 26.842 8.43934 26.5607C8.15804 26.2794 8 25.8978 8 25.5V22.5H9.5V25.5H12.5V27H9.5ZM24.5 9C24.8978 9 25.2794 9.15804 25.5607 9.43934C25.842 9.72064 26 10.1022 26 10.5V13.5H24.5V10.5H21.5V9H24.5Z" fill="white"/>
    <path d="M20.25 15.6316C15.3 16.6842 14.145 19.9316 13.001 22.6526L14.0405 23L14.563 21.7895C14.827 21.8789 15.102 21.9474 15.3 21.9474C21.35 21.9474 23 13 23 13C22.45 14.0526 18.6 14.1842 15.85 14.7105C13.1 15.2368 12 17.4737 12 18.5263C12 19.5789 12.9625 20.5 12.9625 20.5C14.75 15.6316 20.25 15.6316 20.25 15.6316Z" fill="white"/>
  </g>
  <defs>
    <clipPath id="clip0_123_113">
      <rect width="34" height="50" fill="white"/>
    </clipPath>
  </defs>
</svg>
</template>

<script>
export default {
  name: 'LeafScanIcon',
};
</script>

<style scoped>

</style>
