
<template>
  <!-- Generator: Adobe Illustrator 23.0.4, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 200 200" xml:space="preserve">
<g id="KWS_x5F_icon_x5F_Beet_x5F_seed_x5F_service_x5F_1_Background">
	<polygon id="Hintergrund" class="st0" points="0,0 200,0 200,200 0,200 	"/>
</g>
<g id="KWS_x5F_icon_x5F_Beet_x5F_seed_x5F_service_x5F_1">
	<g>
		<path class="st1" d="M92.03564,76.97607c0.00446-0.00195,0.00885-0.00421,0.01331-0.00616
			c-0.09424,0.04144-0.18573,0.0871-0.27905,0.13c0.01025-0.0047,0.02039-0.00958,0.03064-0.01422
			C91.87927,77.04968,91.95636,77.01105,92.03564,76.97607z"/>
		<path class="st1" d="M93.57599,76.38275c0.01184-0.00403,0.0238-0.00787,0.03564-0.01184
			c-0.10986,0.03687-0.22083,0.07098-0.32977,0.1098c0.00531-0.00189,0.0108-0.00348,0.01611-0.00537
			C93.38989,76.44269,93.4834,76.414,93.57599,76.38275z"/>
		<path class="st1" d="M94.98285,75.96655c0.00671-0.00165,0.01318-0.00366,0.0199-0.00537
			c-0.12561,0.03149-0.24933,0.06696-0.37384,0.10089c0.01068-0.00293,0.02142-0.00586,0.03223-0.00873
			C94.76837,76.02429,94.87482,75.99371,94.98285,75.96655z"/>
		<path class="st1" d="M96.54901,75.64502c0.0191-0.00305,0.03833-0.00549,0.05743-0.00854
			c-0.09558,0.0152-0.19244,0.02594-0.28754,0.04254c0.00574-0.00104,0.0116-0.00153,0.0174-0.00256
			C96.40668,75.66425,96.47839,75.65643,96.54901,75.64502z"/>
		<path class="st1" d="M86.01154,81.23999c0.00073-0.00079,0.00134-0.00146,0.00208-0.00226
			c-0.08844,0.09412-0.1745,0.19006-0.26093,0.28607c0.00067-0.00073,0.0014-0.00159,0.00208-0.00232
			C85.83984,81.42706,85.92444,81.33264,86.01154,81.23999z"/>
		<path class="st1" d="M89.33948,78.43933c0.00226-0.00153,0.00446-0.00311,0.00671-0.00464
			c-0.04956,0.03247-0.09656,0.06812-0.14581,0.10107c0.008-0.00537,0.01575-0.01105,0.02374-0.01642
			C89.26312,78.49329,89.30029,78.46503,89.33948,78.43933z"/>
		<path class="st1" d="M87.02295,80.2403c0.00464-0.00427,0.00958-0.00824,0.01428-0.01251
			c-0.03308,0.03009-0.06805,0.05798-0.10089,0.08826c0.00098-0.00085,0.00195-0.00171,0.00293-0.00256
			C86.96649,80.28833,86.99561,80.26526,87.02295,80.2403z"/>
		<path class="st1" d="M88.24384,79.22333c0.00244-0.00183,0.00482-0.00385,0.00726-0.00574
			c-0.09949,0.07611-0.198,0.15277-0.29584,0.23083c0.00201-0.00165,0.00409-0.00317,0.0061-0.00476
			C88.05481,79.36914,88.14886,79.29602,88.24384,79.22333z"/>
		<path class="st1" d="M90.78247,77.5899c0.00647-0.00342,0.01288-0.0069,0.01935-0.01038
			c-0.1076,0.05725-0.21545,0.11353-0.32172,0.17285c0.00403-0.0022,0.00806-0.00421,0.01202-0.00647
			C90.58813,77.69244,90.68549,77.64166,90.78247,77.5899z"/>
		<path class="st1" d="M81.4538,90.19281c0.04138-0.328,0.09558-0.65485,0.17621-0.97644
			C81.54968,89.53809,81.4953,89.86469,81.4538,90.19281z"/>
		<path class="st1" d="M98.12939,75.45819c0.00885-0.00067,0.01758-0.00165,0.02643-0.00232
			c-0.14624,0.01086-0.29144,0.02448-0.43671,0.03864c0.01025-0.00098,0.02063-0.00177,0.03094-0.00275
			C97.87634,75.47968,98.00244,75.46777,98.12939,75.45819z"/>
		<path class="st1" d="M73.3399,70.65924c0.8526-0.89343,1.68274-1.75,2.46283-2.5105
			c-0.24878,0.18573-0.45135,0.41162-0.69537,0.63861C75.00476,68.88245,74.29358,69.63788,73.3399,70.65924z"/>
		<path class="st1" d="M117.73291,91.41949c0.013-0.32666-0.00458-0.65308-0.03522-0.97858c0.00006,0.00024,0,0.00043,0,0.00061
			C117.72827,90.76678,117.74585,91.09296,117.73291,91.41949z"/>
		<path class="st1" d="M114.63232,83.10034c-3.34644-4.6557-8.84229-7.70398-15.06952-7.70398
			c-0.03192,0-0.06287,0.00385-0.09473,0.00403c0.0318-0.00018,0.06293-0.00281,0.09473-0.00281
			C105.79004,75.39758,111.28589,78.44507,114.63232,83.10034z"/>
		<path class="st1" d="M117.66998,90.19141c-0.02747-0.21692-0.06403-0.43213-0.10883-0.64655
			c0.04474,0.21423,0.0813,0.42932,0.10876,0.64594C117.66998,90.19098,117.66998,90.19116,117.66998,90.19141z"/>
		<path class="st1" d="M84.85498,82.59503c-0.12671,0.16412-0.24207,0.33685-0.36316,0.50531
			c0.12115-0.16852,0.23657-0.34131,0.36334-0.50549C84.85516,82.59491,84.85498,82.59497,84.85498,82.59503z"/>
		<path class="st1" d="M112.54724,95.20715c-3.62598,0-10.02991-1.31964-12.98523-1.31964
			c-2.95374,0-9.35925,1.31964-12.98517,1.31964c-3.31915,0-4.74365-0.56116-5.1167-3.04785
			c-0.01752-0.11713-0.02209-0.23431-0.03345-0.3515c0.01117,0.11694,0.01526,0.23425,0.03265,0.35077
			c0.37311,2.48663,1.79755,3.04932,5.1167,3.04932c3.62592,0,10.03143-1.31964,12.98676-1.31964
			c2.95374,0,9.35773,1.31964,12.98364,1.31964c1.24805,0,2.22784-0.07996,2.98517-0.31146
			C114.77441,95.12744,113.79486,95.20715,112.54724,95.20715z"/>
		<path class="st1" d="M81.42645,90.43915c-0.03174,0.33527-0.04779,0.67151-0.03339,1.00793l0,0
			C81.37787,91.1106,81.39508,90.77417,81.42645,90.43915z"/>
		<path class="st1" d="M102.69135,121.93298c0.11224-0.02173,0.22522-0.03937,0.33765-0.06073
			C102.9173,121.88452,102.80493,121.90405,102.69135,121.93298z"/>
		<path class="st1" d="M123.32306,67.98224c1.85168,1.81653,3.98346,4.16748,5.97858,6.16974
			c-2.17395-2.34967-4.80627-5.17407-5.0119-5.36462C123.96387,68.48541,123.71521,68.18158,123.32306,67.98224z"/>
		<path class="st1" d="M85.07416,82.32166c0.00049-0.00061,0.00098-0.00122,0.00146-0.00183
			c-0.06482,0.08075-0.13245,0.15894-0.19586,0.24072c0.00006-0.00012,0.00024-0.00024,0.00031-0.00037
			C84.94293,82.47913,85.00995,82.40167,85.07416,82.32166z"/>
		<path class="st2" d="M89.33948,78.43933c-0.03918,0.0257-0.07635,0.05396-0.11536,0.08002
			C89.26312,78.49335,89.30029,78.46503,89.33948,78.43933z"/>
		<path class="st2" d="M90.78247,77.5899c-0.09698,0.05176-0.19434,0.10254-0.29034,0.15601
			C90.58801,77.6925,90.68555,77.64166,90.78247,77.5899z"/>
		<path class="st2" d="M92.03564,76.97607c-0.07928,0.03497-0.15637,0.07361-0.23511,0.10962
			C91.87921,77.04968,91.95636,77.01099,92.03564,76.97607z"/>
		<path class="st2" d="M98.79498,91.44708c0.00623-1.0863-0.00848-2.50897-0.03894-2.88531
			c-0.01697-0.21429-0.03387-0.42859-0.10022-0.63824c-0.00922-0.02777-0.01849-0.05396-0.02924-0.08014
			c0.01904-0.04333,0.05121-0.09698,0.08527-0.14789c-0.03436,0.05121-0.06689,0.10522-0.08594,0.1485
			c-0.07086-0.18036-0.18805-0.32532-0.3576-0.42242c-0.43628-0.25128-0.85406-0.53186-1.25336-0.8371
			c-0.43475-0.33148-0.84174-0.69836-1.20251-1.1131c-0.20502-0.23584-0.41315-0.46863-0.59662-0.72144
			c-0.24664-0.3407-0.47479-0.69684-0.64594-1.07916c-0.16339-0.36536-0.23584-0.75696-0.21887-1.16089
			c0.00305-0.09247,0.01538-0.18494,0.04163-0.27283c0.04932-0.16345,0.11407-0.19427,0.28979-0.13873
			c0.26056,0.08325,0.51489,0.18652,0.75385,0.32062c0.47174,0.26672,0.94659,0.5257,1.40289,0.81555
			c0.27441,0.17419,0.54419,0.35608,0.79706,0.55963c0.30371,0.24353,0.6228,0.46399,0.88953,0.75073
			c0.25433,0.26978,0.47943,0.55963,0.63049,0.90344c0.05084,0.11542,0.14313,0.27228,0.21704,0.4079
			c0.0368-0.1026,0.07513-0.20245,0.11194-0.29443c0.02008-0.03705,0.0401-0.07404,0.05707-0.11255
			c0.21582-0.47485,0.54572-0.8587,0.94196-1.18707c0.40082-0.33606,0.81244-0.65826,1.25336-0.94043
			c0.59351-0.38074,1.21014-0.73071,1.8407-1.04828c0.15259-0.07709,0.31604-0.13721,0.4856-0.1665
			c0.14185-0.02466,0.16498-0.00616,0.20813,0.1264c0.07709,0.23126,0.05701,0.4671,0.02313,0.69989
			c-0.06012,0.39313-0.18964,0.75543-0.38849,1.10693c-0.55963,0.99127-1.33044,1.78674-2.20453,2.48975
			c-0.3808,0.30676-0.78778,0.58423-1.21948,0.8186c-0.33606,0.18341-0.49176,0.47174-0.52722,0.83398
			c-0.0694,0.69684-0.07556,2.69482-0.07245,2.99231c0.0011,0.07306,0.00256,0.16632,0.00433,0.27228h17.10718
			c-0.03528-0.45642-0.10724-0.91064-0.21936-1.3562c-1.99487-7.93475-9.2652-13.81763-17.93073-13.81763
			c-5.59149,0-10.6026,2.45215-13.98926,6.32159c-0.12677,0.16418-0.24219,0.33698-0.36334,0.50549
			c-1.30951,1.82184-2.30029,3.88202-2.86133,6.11371c-0.00018,0.00073-0.00031,0.00159-0.00049,0.00232
			c-0.08063,0.32159-0.13483,0.64844-0.17621,0.97644c-0.01038,0.08197-0.01953,0.16406-0.02734,0.24634
			c-0.03137,0.33502-0.04858,0.67145-0.03339,1.00793H98.79498z"/>
		<path class="st2" d="M88.24384,79.22333c-0.09497,0.07269-0.18903,0.14581-0.28247,0.22034
			C88.05475,79.36926,88.14899,79.29602,88.24384,79.22333z"/>
		<path class="st2" d="M85.75476,81.52148c0.08502-0.09436,0.1698-0.1889,0.25677-0.28149
			C85.92444,81.33264,85.83984,81.42706,85.75476,81.52148z"/>
		<path class="st2" d="M87.02295,80.2403c-0.02734,0.02496-0.05646,0.04803-0.08368,0.07318
			C86.96649,80.28833,86.99554,80.26526,87.02295,80.2403z"/>
		<path class="st2" d="M98.97998,87.17883c-0.02173,0.06726-0.04279,0.12573-0.06409,0.18091
			C98.93732,87.30444,98.95831,87.24609,98.97998,87.17883z"/>
		<path class="st2" d="M94.98285,75.96655c-0.10803,0.02716-0.21448,0.05774-0.32172,0.08679
			C94.76831,76.02429,94.87488,75.99371,94.98285,75.96655z"/>
		<path class="st2" d="M117.69769,90.44153c0-0.00018,0.00006-0.00037,0-0.00061c-0.00787-0.08331-0.01721-0.16644-0.02771-0.24951
			c0-0.00024,0-0.00043-0.00006-0.00061C117.68048,90.27429,117.68982,90.35779,117.69769,90.44153z"/>
		<path class="st2" d="M96.54901,75.64502c-0.07062,0.01141-0.14233,0.01923-0.21271,0.03143
			C96.40662,75.66425,96.47845,75.65643,96.54901,75.64502z"/>
		<path class="st2" d="M85.07416,82.32166c-0.06421,0.08002-0.13123,0.15747-0.19409,0.23853
			C84.94287,82.47913,85.01001,82.40161,85.07416,82.32166z"/>
		<path class="st2" d="M93.57599,76.38275c-0.09259,0.03125-0.1861,0.05994-0.27802,0.09259
			C93.38983,76.44275,93.48346,76.414,93.57599,76.38275z"/>
		<path class="st2" d="M98.72589,87.6756c-0.00336,0.00482-0.00635,0.00751-0.00964,0.01239
			C98.71954,87.68311,98.72253,87.68042,98.72589,87.6756z"/>
		<path class="st2" d="M98.12939,75.45819c-0.12695,0.00958-0.25305,0.02148-0.37933,0.03357
			C97.87622,75.47961,98.0025,75.46777,98.12939,75.45819z"/>
		<path class="st3" d="M117.48474,92.95038c-0.02252,0.07343-0.05292,0.1355-0.07843,0.20447
			C117.43176,93.08606,117.46228,93.02362,117.48474,92.95038z"/>
		<path class="st3" d="M117.21259,93.59558c-0.04077,0.07428-0.08765,0.14111-0.13354,0.20911
			C117.12463,93.73682,117.172,93.66968,117.21259,93.59558z"/>
		<path class="st3" d="M86.57684,95.20715c3.62592,0,10.03143-1.31964,12.98517-1.31964c2.95532,0,9.35925,1.31964,12.98523,1.31964
			c1.24762,0,2.22717-0.07971,2.98438-0.31073c0.41211-0.12592,0.74365-0.31085,1.03082-0.53851
			c0.17932-0.36499,0.30603-0.79852,0.38538-1.32245c0.07776-0.52722,0.08765-1.05939,0.04669-1.58838H99.88733
			c0.00494,0.30292,0.01227,0.73236,0.02051,1.21838c1.18793,0.00574,2.1333,0.10278,2.1333,0.22241
			c0,0.12335-1.00208,0.22205-2.24152,0.22205c-1.23798,0-2.24158-0.09869-2.24158-0.22205
			c0-0.08618,0.49548-0.16022,1.21326-0.19751c0.00159-0.05347,0.00323-0.1214,0.00482-0.15735
			c0.01044-0.23236,0.0163-0.63275,0.01886-1.08594H81.39307l0,0c0.00519,0.12048,0.02209,0.24048,0.03363,0.36072
			c0.01135,0.11719,0.01593,0.23438,0.03345,0.3515C81.83319,94.646,83.25769,95.20715,86.57684,95.20715z"/>
		<path class="st3" d="M116.83722,94.11279c-0.05798,0.06213-0.11841,0.12189-0.18256,0.17761
			C116.71851,94.2348,116.77954,94.17474,116.83722,94.11279z"/>
		<path class="st4" d="M119.6087,93.60925c0-10.91479-8.9754-19.76379-20.0459-19.76379
			c-11.07355,0-20.04742,8.849-20.04742,19.76379c0,10.91473,8.97388,19.76221,20.04742,19.76221
			C110.6333,113.37146,119.6087,104.52399,119.6087,93.60925z M99.56281,93.88824c-2.95532,0-9.36084,1.31964-12.98676,1.31964
			c-3.31915,0-4.74359-0.56268-5.1167-3.04932c-0.0174-0.11652-0.02148-0.23383-0.03265-0.35077
			c-0.01154-0.12024-0.02844-0.24023-0.03363-0.36072c-0.0144-0.33643,0.00165-0.67267,0.03339-1.00793
			c0.00781-0.08228,0.01697-0.16437,0.02734-0.24634c0.0415-0.32812,0.09589-0.65472,0.17621-0.97644
			c0.00018-0.00073,0.00031-0.00159,0.00049-0.00232c0.56104-2.23169,1.55182-4.29187,2.86133-6.11371
			c0.12109-0.16846,0.23645-0.34119,0.36316-0.50531c-0.00934,0.01062-0.01941,0.02057-0.02869,0.03125
			c0.01721-0.0224,0.03613-0.0434,0.05347-0.06573c0.06342-0.08179,0.13104-0.15997,0.19586-0.24072
			c0.21814-0.27185,0.44385-0.53705,0.67706-0.79602c0.08643-0.09601,0.17249-0.19196,0.26093-0.28607
			c0.29767-0.31683,0.60309-0.6264,0.92273-0.92169c0.03284-0.03027,0.06781-0.05817,0.10089-0.08826
			c0.29663-0.27008,0.60388-0.52869,0.91803-0.77936c0.09784-0.07806,0.19635-0.15472,0.29584-0.23083
			c0.30908-0.23651,0.62494-0.46466,0.94928-0.68182c0.04926-0.03296,0.09625-0.0686,0.14581-0.10107
			c0.36823-0.24139,0.7478-0.46686,1.13391-0.68231c0.10626-0.05933,0.21411-0.1156,0.32172-0.17285
			c0.31732-0.16888,0.63995-0.3288,0.96808-0.47961c0.09332-0.04291,0.18481-0.08856,0.27905-0.13
			c0.4035-0.17731,0.81476-0.34015,1.23291-0.4892c0.10895-0.03882,0.21991-0.07294,0.32977-0.1098
			c0.33496-0.11237,0.67395-0.21521,1.01727-0.30884c0.12451-0.03394,0.24823-0.0694,0.37384-0.10089
			c0.43323-0.1084,0.87128-0.20453,1.31616-0.28217c0.09509-0.0166,0.19196-0.02734,0.28754-0.04254
			c0.36743-0.05829,0.73828-0.10541,1.11267-0.14197c0.14526-0.01416,0.29047-0.02777,0.43671-0.03864
			c0.43378-0.03223,0.87067-0.05328,1.31226-0.05548c0.03186-0.00018,0.06281-0.00403,0.09473-0.00403
			c6.22723,0,11.72308,3.04828,15.06952,7.70398c1.30945,1.82166,2.30017,3.88171,2.86121,6.1134
			c0.02734,0.1095,0.04462,0.22076,0.06763,0.33112c0.0448,0.21442,0.08136,0.42963,0.10883,0.64655
			c0.0105,0.08307,0.01984,0.1662,0.02771,0.24951c0.03064,0.3255,0.04822,0.65192,0.03522,0.97858
			c-0.00983,0.24719-0.03271,0.4942-0.06976,0.7403c-0.04321,0.29095-0.10461,0.55023-0.17841,0.79059
			c-0.02246,0.07324-0.05298,0.13568-0.07843,0.20447c-0.05774,0.15588-0.12,0.30652-0.19373,0.44073
			c-0.04059,0.0741-0.08795,0.14124-0.13354,0.20911c-0.07404,0.1098-0.15405,0.21393-0.24182,0.30811
			c-0.05768,0.06195-0.11871,0.12201-0.18256,0.17761c-0.03729,0.03241-0.06671,0.07294-0.10614,0.10321
			c0.00543-0.01074,0.00854-0.02478,0.01392-0.03571c-0.28717,0.22766-0.61871,0.4126-1.03082,0.53851
			c-0.75732,0.23151-1.73712,0.31146-2.98517,0.31146C108.92053,95.20789,102.51654,93.88824,99.56281,93.88824z"/>
		<path class="st5" d="M98.84442,76.27325c8.66553,0,15.93585,5.88287,17.93073,13.81763
			c0.11212,0.44556,0.18408,0.89978,0.21936,1.3562c0.04095,0.52899,0.03107,1.06116-0.04669,1.58838
			c-0.07935,0.52393-0.20605,0.95746-0.38538,1.32245c-0.00537,0.01093-0.00848,0.02496-0.01392,0.03571
			c0.03943-0.03027,0.06885-0.0708,0.10614-0.10321c0.06415-0.05573,0.12457-0.11548,0.18256-0.17761
			c0.08777-0.09418,0.16779-0.1983,0.24182-0.30811c0.0459-0.06799,0.09277-0.13483,0.13354-0.20911
			c0.07373-0.13422,0.13599-0.28485,0.19373-0.44073c0.02551-0.06897,0.05591-0.13104,0.07843-0.20447
			c0.07379-0.24036,0.13519-0.49963,0.17841-0.79059c0.03705-0.24609,0.05994-0.4931,0.06976-0.7403
			c0.01294-0.32654-0.00464-0.65271-0.03522-0.97797c-0.00787-0.08374-0.01721-0.16724-0.02777-0.25073
			c-0.02747-0.21661-0.06403-0.4317-0.10876-0.64594c-0.02301-0.11035-0.04028-0.22162-0.06763-0.33112
			c-0.56104-2.23169-1.55176-4.29175-2.86121-6.1134c-3.34644-4.65527-8.84229-7.70276-15.06952-7.70276
			c-0.0318,0-0.06293,0.00262-0.09473,0.00281c-0.44159,0.0022-0.87848,0.02325-1.31226,0.05548
			c-0.00885,0.00067-0.01758,0.00165-0.02643,0.00232c-0.12689,0.00958-0.25317,0.02142-0.37933,0.03357
			c-0.01031,0.00098-0.02069,0.00177-0.03094,0.00275c-0.37439,0.03656-0.74524,0.08368-1.11267,0.14197
			c-0.0191,0.00305-0.03833,0.00549-0.05743,0.00854c-0.07056,0.01141-0.1424,0.01923-0.21271,0.03143
			c-0.0058,0.00104-0.01166,0.00153-0.0174,0.00256c-0.44489,0.07764-0.88293,0.17377-1.31616,0.28217
			c-0.00671,0.00171-0.01318,0.00372-0.0199,0.00537c-0.10797,0.02716-0.21454,0.05774-0.32172,0.08679
			c-0.0108,0.00287-0.02155,0.0058-0.03223,0.00873c-0.34332,0.09363-0.68231,0.19647-1.01727,0.30884
			c-0.01184,0.00397-0.0238,0.00781-0.03564,0.01184c-0.09253,0.03125-0.18616,0.06-0.27802,0.09259
			c-0.00531,0.00189-0.0108,0.00348-0.01611,0.00537c-0.41815,0.14905-0.82941,0.31189-1.23291,0.4892
			c-0.00446,0.00195-0.00885,0.00421-0.01331,0.00616c-0.07928,0.03491-0.15643,0.07361-0.23511,0.10962
			c-0.01025,0.00464-0.02039,0.00952-0.03064,0.01422c-0.32812,0.15082-0.65076,0.31073-0.96808,0.47961
			c-0.00647,0.00348-0.01288,0.00696-0.01935,0.01038c-0.09692,0.05176-0.19446,0.1026-0.29034,0.15601
			c-0.00397,0.00226-0.008,0.00427-0.01202,0.00647c-0.38611,0.21545-0.76569,0.44092-1.13391,0.68231
			c-0.00226,0.00153-0.00446,0.00311-0.00671,0.00464c-0.03918,0.0257-0.07635,0.05402-0.11536,0.08002
			c-0.008,0.00537-0.01575,0.01105-0.02374,0.01642c-0.32434,0.21716-0.6402,0.44531-0.94928,0.68182
			c-0.00244,0.00189-0.00482,0.00391-0.00726,0.00574c-0.09485,0.07269-0.18909,0.14594-0.28247,0.22034
			c-0.00201,0.00159-0.00409,0.00311-0.0061,0.00476c-0.31415,0.25067-0.6214,0.50928-0.91803,0.77936
			c-0.0047,0.00427-0.00964,0.00824-0.01428,0.01251c-0.0274,0.02496-0.05646,0.04803-0.08368,0.07318
			c-0.00098,0.00085-0.00195,0.00171-0.00293,0.00256c-0.31964,0.29529-0.62506,0.60486-0.92273,0.92169
			c-0.00073,0.00079-0.00134,0.00146-0.00208,0.00226c-0.08698,0.09259-0.17175,0.18713-0.25677,0.28149
			c-0.00067,0.00073-0.0014,0.00159-0.00208,0.00232c-0.23322,0.25897-0.45892,0.52417-0.67706,0.79602
			c-0.00049,0.00061-0.00098,0.00122-0.00146,0.00183c-0.06415,0.07996-0.13129,0.15747-0.19409,0.23853
			c-0.00006,0.00012-0.00024,0.00024-0.00031,0.00037c-0.01733,0.02234-0.03625,0.04333-0.05347,0.06573
			c0.00928-0.01068,0.01935-0.02063,0.02869-0.03125c0-0.00006,0.00018-0.00012,0.00018-0.00018
			C88.24182,78.7254,93.25293,76.27325,98.84442,76.27325z"/>
		<path class="st5" d="M98.76685,92.83673c0.00153-0.06238,0.00293-0.09662,0.00446-0.14636
			c-0.71777,0.03729-1.21326,0.11133-1.21326,0.19751c0,0.12335,1.0036,0.22205,2.24158,0.22205
			c1.23944,0,2.24152-0.09869,2.24152-0.22205c0-0.11963-0.94537-0.21667-2.1333-0.22241
			c0.00098,0.05811,0.00195,0.11151,0.00293,0.17126H98.76685z"/>
		<path class="st6" d="M98.72589,87.6756c0.03302-0.04755,0.06763-0.09338,0.09491-0.12207
			c0.02643-0.02753,0.06012-0.10358,0.09509-0.19379c0.0213-0.05518,0.04236-0.11365,0.06409-0.18091
			c0.04205-0.13037,0.08301-0.27234,0.11371-0.39923c0.05701-0.23431,0.28363-0.88641,0.27905-0.92188
			c0.00018-0.00055,0.00037-0.00098,0.00055-0.00153c-0.07391-0.13562-0.1662-0.29248-0.21704-0.4079
			c-0.15106-0.34381-0.37616-0.63367-0.63049-0.90344c-0.26672-0.28674-0.58582-0.5072-0.88953-0.75073
			c-0.25287-0.20355-0.52264-0.38544-0.79706-0.55963c-0.4563-0.28986-0.93115-0.54883-1.40289-0.81555
			c-0.23895-0.13409-0.49329-0.23737-0.75385-0.32062c-0.17572-0.05554-0.24048-0.02472-0.28979,0.13873
			c-0.02625,0.08789-0.03857,0.18036-0.04163,0.27283c-0.01697,0.40393,0.05548,0.79553,0.21887,1.16089
			c0.17114,0.38232,0.39929,0.73846,0.64594,1.07916c0.18347,0.25281,0.3916,0.4856,0.59662,0.72144
			c0.36078,0.41473,0.76776,0.78162,1.20251,1.1131c0.39929,0.30524,0.81708,0.58582,1.25336,0.8371
			c0.16956,0.09711,0.28674,0.24207,0.3576,0.42242c0.01904-0.04327,0.05157-0.09729,0.08594-0.1485
			c0.00171-0.00262,0.00262-0.00488,0.00439-0.00751C98.71954,87.68311,98.72253,87.68042,98.72589,87.6756z"/>
		<path class="st7" d="M99.95544,88.1825c0.03546-0.36224,0.19116-0.65057,0.52722-0.83398
			c0.4317-0.23438,0.83868-0.51184,1.21948-0.8186c0.87408-0.703,1.6449-1.49847,2.20453-2.48975
			c0.19885-0.3515,0.32837-0.71381,0.38849-1.10693c0.03387-0.23279,0.05396-0.46863-0.02313-0.69989
			c-0.04315-0.13257-0.06628-0.15106-0.20813-0.1264c-0.16956,0.0293-0.33301,0.08942-0.4856,0.1665
			c-0.63055,0.31757-1.24719,0.66754-1.8407,1.04828c-0.44092,0.28217-0.85254,0.60437-1.25336,0.94043
			c-0.39624,0.32837-0.72614,0.71222-0.94196,1.18707c-0.01697,0.03851-0.03699,0.0755-0.05707,0.11255
			c-0.0368,0.09198-0.07513,0.19183-0.11194,0.29443c-0.00018,0.00055-0.00037,0.00098-0.00055,0.00153
			c0.00458,0.03546-0.22205,0.68756-0.27905,0.92188c-0.0307,0.12689-0.07166,0.26886-0.11371,0.39923
			c-0.02167,0.06726-0.04266,0.12561-0.06409,0.18091c-0.03497,0.09021-0.06866,0.16626-0.09509,0.19379
			c-0.02728,0.02869-0.06189,0.07452-0.09491,0.12207c-0.00336,0.00482-0.00635,0.00751-0.00964,0.01239
			c-0.00177,0.00262-0.00269,0.00488-0.00439,0.00751c-0.03406,0.0509-0.06622,0.10455-0.08527,0.14789
			c0.01074,0.02618,0.02002,0.05237,0.02924,0.08014c0.06635,0.20966,0.08325,0.42395,0.10022,0.63824
			c0.03046,0.37634,0.04517,1.79901,0.03894,2.88531c-0.00256,0.45319-0.00842,0.85358-0.01886,1.08594
			c-0.00159,0.03595-0.00323,0.10388-0.00482,0.15735c-0.00153,0.04974-0.00293,0.08398-0.00446,0.14636h1.14392
			c-0.00098-0.05975-0.00195-0.11316-0.00293-0.17126c-0.00824-0.48602-0.01556-0.91547-0.02051-1.21838
			c-0.00177-0.10596-0.00323-0.19922-0.00433-0.27228C99.87988,90.87732,99.88605,88.87933,99.95544,88.1825z"/>
		<path class="st8" d="M88.69641,115.35663c-2.42035-1.34583-5.23846-4.12543-7.68658-5.78113
			c-2.92291-1.97791-4.10382-3.83868-5.46204-7.54785l-1.75745-10.39832c0.57812-2.33716,1.57404-4.58179,2.18756-7.70819
			c3.22357-4.16553,5.39728-8.85669,5.52527-8.96155c2.36792-1.21637,5.37415-2.815,7.86847-3.83868
			c3.90192-0.57501,6.0155,0.15417,8.50366-2.74719c0.83606-0.97595,1.06891-1.91034,1.0426-2.60901
			c-0.10291-0.1546-0.10901-0.3446-0.185-0.50922c-0.07312-0.15698-0.15338-0.30188-0.31006-0.34692
			c-0.06781,0.19489-0.16107,0.37964-0.32861,0.53778c-0.13416,0.1264-0.31293,0.19574-0.49792,0.19574
			c-0.65521,0.00159-1.30273-0.06165-1.95483-0.1449c-0.63666-0.08173-1.18396-0.30988-1.70966-0.64746
			c-0.35864-0.23065-0.58228-0.53174-0.62817-0.95294c-0.15796-0.0022-0.29767-0.00116-0.40582,0.00513
			c-0.3808-0.24823-0.82013-0.32068-1.25336-0.32373c-0.86639-0.00775-6.09717,0.30212-6.66449,0.61511
			c-0.45013,0.24817-3.79395,1.63257-4.45068,1.90082c-0.6814,0.27905-3.30219,1.5-4.17474,1.7652
			c-0.22406,0.06793-0.39508,0.17242-0.55182,0.28943c-0.78009,0.7605-1.61023,1.61707-2.46283,2.5105
			c-2.16937,2.32343-5.60773,6.03839-6.0918,6.59631c-0.15106,0.17419-2.34326,1.70508-2.52057,1.74207
			c-0.36536,0.07709-1.69275,0.71991-2.04578,0.83557c-0.16956,0.05548-2.22607,1.45221-2.84894,2.00409
			c-0.29291,0.25903-1.26569,1.14081-1.36896,1.13928c0.13196-0.25665,0.40936-0.62317,0.56042-0.82562
			c-0.00397,0.00342-0.00812,0.00623-0.01208,0.00964c-7.14697,6.18811-14.09515,9.87726-20.33105,18.06183
			c-5.4682,7.17786-23.68146,36.16058-23.68146,36.16058l26.8075,0.00769c0,0,5.2851-13.52319,10.46808-17.13373
			c3.21277-2.23694,2.0072-1.53546,6.41473-0.67059c7.46307,1.46606,11.11829-0.81403,16.25659,0.59198
			c4.69891,1.28418,11.68561,6.40857,16.7514,4.77441c0.31281-0.1012,0.65649-0.30682,1.02722-0.54492
			c-0.12872-0.06219-0.26013-0.11426-0.41272-0.10132c-0.03082-0.2821,0.17725-0.44397,0.3114-0.64746
			c0.44397-0.67987,1.11462-0.96973,1.8808-0.98975c0.38696-0.0108,0.79242,0.02777,1.18243,0.08325
			c0.5658,0.08173,1.12384,0.21277,1.68811,0.30371c0.28363,0.0462,0.51794,0.14954,0.70911,0.36841
			c0.39929,0.45172,0.71228,0.93115,0.63672,1.57404c-0.0108,0.09253,0.02161,0.18964,0.03394,0.28522
			c-0.00018,0.00012-0.00043,0.00012-0.00061,0.00024c0.87213,0.08868,0.66565-2.17212,0.65637-2.30164
			C99.0069,116.34174,92.00476,117.19733,88.69641,115.35663z"/>
		<path class="st9" d="M98.72107,123.99872c0.07556-0.64288-0.23743-1.12231-0.63672-1.57404
			c-0.19116-0.21887-0.42548-0.3222-0.70911-0.36841c-0.56427-0.09094-1.12231-0.22198-1.68811-0.30371
			c-0.39001-0.05548-0.79547-0.09406-1.18243-0.08325c-0.76617,0.02002-1.43683,0.30988-1.8808,0.98975
			c-0.13416,0.20349-0.34222,0.36536-0.3114,0.64746c0.15259-0.01294,0.284,0.03912,0.41272,0.10132
			c0.117,0.05658,0.23181,0.12158,0.35809,0.1546c0.88489,0.22815,1.77289,0.42859,2.67474,0.59198
			c0.8371,0.15106,1.67419,0.22357,2.52057,0.21893c0.16315,0,0.32935,0.00909,0.47577-0.08917
			c0.00018-0.00012,0.00043-0.00012,0.00061-0.00024C98.74268,124.18835,98.71027,124.09125,98.72107,123.99872z"/>
		<path class="st9" d="M97.9848,63.66882c-0.02869-0.00397-0.05725-0.00916-0.08594-0.01331
			c0.10248,0.02295,0.19592,0.04517,0.30627,0.06879C98.13739,63.6991,98.06439,63.68005,97.9848,63.66882z"/>
		<path class="st9" d="M98.41846,64.90704c-0.02161-0.00769-0.04474-0.01233-0.06628-0.01385
			c-0.02161-0.00311-0.04474-0.00311-0.07092-0.00153c-0.02161,0.00305-0.04626,0.00769-0.0694,0.01538
			c-0.00464-0.06012-0.01385-0.12024-0.02466-0.18036c-0.00153-0.00616-0.00311-0.01233-0.00311-0.01849
			c-0.02002-0.33148-0.24664-0.42706-0.51953-0.46405c-0.56201-0.07788-3.12091-0.32996-4.3609-0.34735
			c0.0459,0.4212,0.26953,0.72229,0.62817,0.95294c0.5257,0.33759,1.073,0.56573,1.70966,0.64746
			c0.6521,0.08325,1.29962,0.14648,1.95483,0.1449c0.185,0,0.36377-0.06934,0.49792-0.19574
			c0.16754-0.15814,0.2608-0.3429,0.32861-0.53778c-0.00183-0.00055-0.00256-0.00256-0.00439-0.00305V64.90704z"/>
		<path class="st10" d="M59.83282,81.83728c0.62286-0.55188,2.67938-1.94861,2.84894-2.00409
			c0.35303-0.11566,1.68042-0.75848,2.04578-0.83557c0.17731-0.03699,2.36951-1.56787,2.52057-1.74207
			c0.48407-0.55792,3.92242-4.27289,6.0918-6.59631c0.95367-1.02136,1.66486-1.77679,1.76746-1.87189
			c0.24402-0.22699,0.44659-0.45288,0.69537-0.63861c0.15674-0.117,0.32776-0.2215,0.55182-0.28943
			c0.87256-0.2652,3.49335-1.48615,4.17474-1.7652c0.65674-0.26825,4.00055-1.65265,4.45068-1.90082
			c0.56732-0.31299,5.7981-0.62286,6.66449-0.61511c0.43323,0.00305,0.87256,0.0755,1.25336,0.32373
			c0.10815-0.00629,0.24786-0.00732,0.40582-0.00513c1.23999,0.0174,3.79889,0.26947,4.3609,0.34735
			c0.27289,0.03699,0.49951,0.13257,0.51953,0.46405c0,0.00616,0.00159,0.01233,0.00311,0.01849
			c0.0108,0.06012,0.02002,0.12024,0.02466,0.18036c0.02313-0.00769,0.04779-0.01233,0.0694-0.01538
			c0.02618-0.00159,0.04932-0.00159,0.07092,0.00153c0.02155,0.00153,0.04468,0.00616,0.06628,0.01385v-0.00153
			c0.00183,0.00049,0.00256,0.0025,0.00439,0.00305c0.15668,0.04504,0.23694,0.18994,0.31006,0.34692
			c0.07599,0.16461,0.08209,0.35461,0.185,0.50922c0.00983,0.01477,0.00964,0.03333,0.02161,0.0473
			c0.17572-0.06476,0.13873-0.21741,0.14026-0.34998c0.00159-0.41315,0.00159-0.44244,0-0.85559
			c-0.00153-0.3916-0.46399-0.79395-0.83862-0.87408c-0.01306-0.00281-0.02307-0.00531-0.03601-0.00806
			c-0.11035-0.02362-0.2038-0.04584-0.30627-0.06879c-0.90759-0.20288-1.5603-0.38647-2.539-0.63696
			c-1.29657-0.33301-2.61005-0.59509-3.91272-0.90802c-0.78931-0.18964-1.7251-0.04779-2.52521-0.10944
			c-0.26514-0.02161-3.87103,0.42853-4.90698,0.61353c-1.3736,0.2467-9.38239,3.56738-10.06689,3.95892
			c-1.0144,0.58276-1.7652,1.63727-2.42194,2.30939c-0.12329,0.1264-6.00464,7.75287-6.44861,7.96716
			c-0.17114,0.08325-3.84485,2.64697-4.08997,3.03241c-0.54419,0.85406-1.33044,1.4137-1.9317,2.22614
			c-0.00842,0.01135-0.02197,0.02948-0.03156,0.0423c-0.15106,0.20245-0.42847,0.56897-0.56042,0.82562
			C58.56714,82.97809,59.53992,82.09631,59.83282,81.83728z"/>
		<path class="st8" d="M160.33344,99.94641c-6.09943-8.00537-12.88074-11.71613-19.86255-17.66504
			c0.15381,0.20758,0.35724,0.48853,0.46234,0.69519c-0.10333,0.00153-1.07611-0.88025-1.36902-1.13928
			c-0.6228-0.55188-2.67932-1.94861-2.84894-2.00409c-0.35303-0.11566-1.68036-0.75848-2.0473-0.83557
			c-0.17572-0.03699-2.36792-1.56787-2.52057-1.74207c-0.27045-0.31177-1.46649-1.61273-2.84576-3.10358
			c-1.99512-2.00226-4.12689-4.35321-5.97858-6.16974c-0.08838-0.04492-0.17548-0.09058-0.2821-0.12292
			c-0.86945-0.2652-3.49176-1.48615-4.17474-1.7652c-0.65674-0.26825-3.99896-1.65265-4.44916-1.90082
			c-0.56732-0.31299-5.7981-0.62286-6.66449-0.61511c-0.43164,0.00305-0.87256,0.0755-1.25336,0.32373
			c-0.10834-0.00629-0.24823-0.00739-0.40656-0.00513c-0.0459,0.4212-0.26947,0.72229-0.62665,0.95294
			c-0.52722,0.33759-1.07452,0.56573-1.70966,0.64746c-0.6521,0.08325-1.29962,0.14648-1.95636,0.1449
			c-0.18341,0-0.36224-0.06934-0.4964-0.19574c-0.16766-0.15826-0.26086-0.3432-0.32874-0.53821
			c-0.15717,0.0448-0.23743,0.19012-0.31073,0.34735c-0.08325,0.18036-0.06934,0.39618-0.20654,0.55652
			c-0.17578-0.06476-0.13879-0.21741-0.14032-0.34998c-0.00153-0.41315-0.00153-0.44244,0-0.85559
			c0-0.0025,0.00195-0.00488,0.00201-0.00739c-0.33826,0.57733-0.47217,1.9903,0.82001,3.49872
			c2.48663,2.90137,4.60175,2.17218,8.50519,2.74719c2.49438,1.02368,5.49902,2.62231,7.86542,3.83868
			c0.12952,0.10486,2.30322,4.79602,5.52679,8.96155c0.61353,3.1264,1.60791,5.37103,2.18756,7.70813l-1.75745,10.39838
			c-1.35822,3.70917-2.53906,5.56989-5.46204,7.54785c-2.44812,1.6557-5.26624,4.43524-7.68658,5.78113
			c-3.30835,1.8407-10.31049,0.98511-10.71436,6.62592c-0.00928,0.12952-0.21582,2.39111,0.65674,2.3017
			c0.16614-0.25348,0.33099-0.47473,0.49487-0.67725c0.1095-0.33228,0.31042-0.62726,0.55713-0.90637
			c0.19269-0.21887,0.42548-0.3222,0.70917-0.36841c0.22394-0.03607,0.44666-0.08014,0.66962-0.12329
			c0.11359-0.02893,0.22595-0.04846,0.33765-0.06073c0.22681-0.04315,0.45361-0.08673,0.68237-0.11969
			c0.38849-0.05548,0.79236-0.09406,1.18085-0.08325c0.76624,0.02002,1.43683,0.30988,1.88239,0.98975
			c0.13257,0.20349,0.3407,0.36536,0.30981,0.64746c-0.13666-0.0116-0.25586,0.0304-0.37183,0.08368
			c0.21167,0.12225,0.41418,0.22522,0.604,0.28662c5.06586,1.63416,12.05255-3.49023,16.7514-4.77441
			c5.13831-1.40601,8.79352,0.87408,16.25659-0.59198c4.40594-0.86487,3.20044-1.56635,6.41473,0.67059
			c5.18298,3.61047,8.23273,17.13373,8.23273,17.13373l29.04285-0.00769
			C184.01489,136.10706,165.80164,107.12427,160.33344,99.94641z"/>
		<path class="st9" d="M106.7746,122.65906c-0.44556-0.67987-1.11615-0.96973-1.88239-0.98975
			c-0.38849-0.0108-0.79236,0.02777-1.18085,0.08325c-0.22876,0.03296-0.45557,0.07654-0.68237,0.11969
			c-0.11243,0.02136-0.2254,0.039-0.33765,0.06073c-0.22296,0.04315-0.44568,0.08722-0.66962,0.12329
			c-0.28369,0.0462-0.51648,0.14954-0.70917,0.36841c-0.2467,0.27911-0.44763,0.5741-0.55713,0.90637
			c-0.06775,0.20551-0.10846,0.422-0.07959,0.66766c0.0108,0.09253-0.02155,0.18964-0.03387,0.28522
			c0.14642,0.09863,0.31293,0.08942,0.47632,0.08942c0.8479,0.00464,1.68347-0.06787,2.52216-0.21893
			c0.90027-0.16339,1.78827-0.36383,2.67316-0.59198c0.14081-0.03687,0.26776-0.112,0.39899-0.17224
			c0.11597-0.05328,0.23517-0.09528,0.37183-0.08368C107.1153,123.02441,106.90717,122.86255,106.7746,122.65906z"/>
		<path class="st9" d="M101.4115,63.66882c-0.07983,0.01129-0.15295,0.0304-0.22083,0.05573
			c0.11133-0.02374,0.20563-0.04633,0.30908-0.0694C101.47034,63.65942,101.44092,63.66473,101.4115,63.66882z"/>
		<path class="st9" d="M101.73248,64.24414c-0.27441,0.03699-0.49945,0.13257-0.51953,0.46405
			c0,0.00616-0.00153,0.01233-0.00305,0.01849c-0.0108,0.06012-0.02008,0.12024-0.02466,0.18036
			c-0.02313-0.00769-0.04779-0.01233-0.0694-0.01538c-0.02618-0.00159-0.04932-0.00159-0.07092,0.00153
			c-0.02161,0.00153-0.04468,0.00616-0.06628,0.01385v-0.00153c-0.00159,0.00043-0.0022,0.0022-0.00378,0.00262
			c0.06787,0.19501,0.16107,0.37994,0.32874,0.53821c0.13416,0.1264,0.31299,0.19574,0.4964,0.19574
			c0.65674,0.00159,1.30426-0.06165,1.95636-0.1449c0.63513-0.08173,1.18243-0.30988,1.70966-0.64746
			c0.35718-0.23065,0.58075-0.53174,0.62665-0.95294C104.85242,63.91425,102.29437,64.16626,101.73248,64.24414z"/>
		<path class="st10" d="M100.31726,65.46204c0.00153,0.13257-0.03546,0.28522,0.14032,0.34998
			c0.13721-0.16034,0.12329-0.37616,0.20654-0.55652c0.0733-0.15723,0.15356-0.30255,0.31073-0.34735
			c0.00159-0.00043,0.0022-0.0022,0.00378-0.00262v0.00153c0.02161-0.00769,0.04468-0.01233,0.06628-0.01385
			c0.02161-0.00311,0.04474-0.00311,0.07092-0.00153c0.02161,0.00305,0.04626,0.00769,0.0694,0.01538
			c0.00458-0.06012,0.01385-0.12024,0.02466-0.18036c0.00153-0.00616,0.00305-0.01233,0.00305-0.01849
			c0.02008-0.33148,0.24512-0.42706,0.51953-0.46405c0.56189-0.07788,3.11993-0.3299,4.36017-0.34735
			c0.15833-0.00226,0.29822-0.00116,0.40656,0.00513c0.3808-0.24823,0.82172-0.32068,1.25336-0.32373
			c0.86639-0.00775,6.09717,0.30212,6.66449,0.61511c0.4502,0.24817,3.79242,1.63257,4.44916,1.90082
			c0.68298,0.27905,3.3053,1.5,4.17474,1.7652c0.10663,0.03235,0.19373,0.078,0.2821,0.12292
			c0.39215,0.19934,0.64081,0.50317,0.96667,0.80511c0.20563,0.19055,2.83795,3.01495,5.0119,5.36462
			c1.37927,1.49084,2.57532,2.79181,2.84576,3.10358c0.15265,0.17419,2.34485,1.70508,2.52057,1.74207
			c0.36694,0.07709,1.69427,0.71991,2.0473,0.83557c0.16962,0.05548,2.22614,1.45221,2.84894,2.00409
			c0.29291,0.25903,1.26569,1.14081,1.36902,1.13928c-0.1051-0.20667-0.30853-0.48761-0.46234-0.69519
			c-0.04932-0.06659-0.09406-0.12634-0.12811-0.17273c-0.60278-0.81244-1.38904-1.37207-1.93323-2.22614
			c-0.24512-0.38544-3.91882-2.94916-4.08997-3.03241c-0.44397-0.21429-6.32532-7.84076-6.44867-7.96716
			c-0.65674-0.67212-1.40594-1.72662-2.42188-2.30939c-0.68604-0.39154-8.6933-3.71222-10.06689-3.95892
			c-1.03442-0.185-4.64185-0.63513-4.90704-0.61353c-0.80164,0.06165-1.7359-0.0802-2.52673,0.10944
			c-1.30115,0.31293-2.61462,0.57501-3.91113,0.90802c-0.97815,0.25037-1.6308,0.43384-2.53748,0.6366
			c-0.10345,0.02307-0.19775,0.04565-0.30908,0.0694c-0.01251,0.00269-0.02209,0.00513-0.03473,0.00781
			c-0.37225,0.07965-0.82935,0.47748-0.83667,0.8667c-0.00006,0.0025-0.00201,0.00488-0.00201,0.00739
			C100.31573,65.01959,100.31573,65.04889,100.31726,65.46204z"/>
	</g>
</g>
</svg>
</template>

<script>
export default {
  name: 'BeetseedIcon',
};
</script>

<style scoped>
#KWS_x5F_icon_x5F_Beet_x5F_seed_x5F_service_x5F_1_Background .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#E8E6D4;}
	#KWS_x5F_icon_x5F_Beet_x5F_seed_x5F_service_x5F_1 .st1{fill:none;}
	#KWS_x5F_icon_x5F_Beet_x5F_seed_x5F_service_x5F_1 .st2{fill:#BAE2F1;}
	#KWS_x5F_icon_x5F_Beet_x5F_seed_x5F_service_x5F_1 .st3{fill:#CB8961;}
	#KWS_x5F_icon_x5F_Beet_x5F_seed_x5F_service_x5F_1 .st4{fill:#FF6C00;}
	#KWS_x5F_icon_x5F_Beet_x5F_seed_x5F_service_x5F_1 .st5{fill:#535453;}
	#KWS_x5F_icon_x5F_Beet_x5F_seed_x5F_service_x5F_1 .st6{fill:#005243;}
	#KWS_x5F_icon_x5F_Beet_x5F_seed_x5F_service_x5F_1 .st7{fill:#008260;}
	#KWS_x5F_icon_x5F_Beet_x5F_seed_x5F_service_x5F_1 .st8{fill:#D7C8A6;}
	#KWS_x5F_icon_x5F_Beet_x5F_seed_x5F_service_x5F_1 .st9{fill:#F1F0E4;}
	#KWS_x5F_icon_x5F_Beet_x5F_seed_x5F_service_x5F_1 .st10{fill:#B0A187;}
</style>
