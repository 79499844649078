
<template>
  <svg id="uuid-8fda9de2-979c-4163-8444-8e452ac796b1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width="155.12855"
     height="155.12855" viewBox="0 0 155.12855 155.12855">
    <rect id="uuid-657b51f9-665b-43e4-89aa-61b786ecfdad" data-name="Hellbeige" y="0" width="155.12855" height="155.12855"
          style="fill: #e8e6d4;"/>
    <polygon points="29.66562 87.28783 83.03458 87.28783 77.56427 119.4084 55.93834 113.56056 29.66562 87.28783" style="fill: #96825f;"/>
    <polygon points="125.46293 87.28783 77.56427 87.28783 77.56427 119.4084 99.1902 113.56056 125.46293 87.28783" style="fill: #645041;"/>
    <path d="M91.12219,81.35824c1.74692-1.13523,5.68296,.1923,7.36907,.45603,2.96608,.46505,4.85353,.33004,5.92637-3.6356,.11465-.43177-.16531-.79891,.57633-1.26514,.62153-.31398,1.26811-.34396,1.89392-.54433,1.44332-.45051,2.93769-2.1225,3.41714-3.69242,.40601-2.66519-3.70859-4.87334-3.78129-5.63935-.05648-.61435,.03123-1.06035,.59095-1.47456,1.18075-.87775,4.06371,.59754,4.48987-1.02282,.30461-1.15188,.04182-2.11731-.26025-3.07845-.10503-.33683-.24457-.69993-.22011-1.09778,.02011-.5402,.416-1.14636,.68597-1.6362,1.29434-2.41551,1.52588-4.89668,.39096-6.86416-.85571-1.47756-2.8503-1.53778-4.48502-.95496-1.02974,.36077-2.07105,1.06933-2.87703,1.99204-.45205,.31655-.90784,.64624-1.39428,.81654-.53436,.19324-1.10342,.0414-1.61677,.03918-1.45396,.00638-2.95652,1.42693-3.52473,2.9987-.40208,1.12083,1.96755,4.78922,.76007,5.27731-1.25519,.51329-5.771-5.23023-8.80223-1.18331-.91223,1.2305,.74809,3.5703-.10989,4.23674-.80783,.62413-1.3367,.20244-2.33729,.51611-.98208,.48428-1.40369,1.20973-1.77349,2.04049-.76798,1.71174-.16667,3.33963,.25798,4.77044,.38463,1.3061,3.72978,6.1849,1.4679,8.51083-1.62272,1.61304-1.95192,3.81966-2.33724,6.16741-.0165,.10052,.06038,.19275,.16225,.19359l2.63985,.02166c.08978,.00074,.16399-.06755,.16702-.15728,.02621-.77649,.28101-4.20319,2.72395-5.79072Z"
          style="fill: #257150; fill-rule: evenodd;"/>
    <path d="M64.00636,81.35824c-1.74692-1.13523-5.68296,.1923-7.36907,.45603-2.96608,.46505-4.85353,.33004-5.92637-3.6356-.11465-.43177,.16531-.79891-.57633-1.26514-.62153-.31398-1.26811-.34396-1.89392-.54433-1.44332-.45051-2.93769-2.1225-3.41714-3.69242-.40601-2.66519,3.70859-4.87334,3.78129-5.63935,.05648-.61435-.03123-1.06035-.59095-1.47456-1.18075-.87775-4.06371,.59754-4.48987-1.02282-.30461-1.15188-.04182-2.11731,.26025-3.07845,.10503-.33683,.24457-.69993,.22011-1.09778-.02011-.5402-.416-1.14636-.68597-1.6362-1.29434-2.41551-1.52588-4.89668-.39096-6.86416,.85571-1.47756,2.8503-1.53778,4.48502-.95496,1.02974,.36077,2.07105,1.06933,2.87703,1.99204,.45205,.31655,.90784,.64624,1.39428,.81654,.53436,.19324,1.10342,.0414,1.61677,.03918,1.45396,.00638,2.95652,1.42693,3.52473,2.9987,.40208,1.12083-1.96755,4.78922-.76007,5.27731,1.25519,.51329,5.771-5.23023,8.80223-1.18331,.91223,1.2305-.74809,3.5703,.10989,4.23674,.80783,.62413,1.3367,.20244,2.33729,.51611,.98208,.48428,1.40369,1.20973,1.77349,2.04049,.76798,1.71174,.16667,3.33963-.25798,4.77044-.38463,1.3061-3.72978,6.1849-1.4679,8.51083,1.62272,1.61304,1.95192,3.81966,2.33724,6.16741,.0165,.10052-.06038,.19275-.16225,.19359l-2.63985,.02166c-.08978,.00074-.16399-.06755-.16702-.15728-.02621-.77649-.28101-4.20319-2.72395-5.79072Z"
          style="fill: #5c957c; fill-rule: evenodd;"/>
    <path d="M79.28996,87.31068V48.14365c.03224,.02363,.06109,.0457,.09405,.06965,.84422,.61417,3.27506,1.74711,4.65205,3.14428l-.71322-3.73973c.17495-.27733,.27594-.62661,.32067-1.00247,1.06777,.64916,3.0122,1.64928,4.18939,2.84376l-.71315-3.73971c.62983-.99836,.3862-2.89404-.51891-3.82202-1.62965-1.67026-5.83356-3.8896-5.83356-3.8896h-5.8023s-4.20391,2.21934-5.83356,3.8896c-.90511,.92799-1.14874,2.82366-.51891,3.82202l-.71315,3.73971c1.17719-1.19447,3.12156-2.1946,4.18939-2.84376,.04474,.37582,.14572,.72514,.32067,1.00247l-.71322,3.73973c1.0439-1.05917,2.69205-1.96588,3.79684-2.61046v38.56356h3.79691Z"
          style="fill: #96a02d;"/>
    <path d="M88.65502,30.63295c-.45403-.26212-1.03455-.10656-1.29668,.34744l-1.48988,2.58061c-.74389-.19111-1.61402-.25733-2.54575-.18079-.07528-.10227-.15409-.20214-.23584-.29979v-3.87129c0-.52424-.42499-.94923-.94926-.94923-.52421,0-.94919,.42499-.94919,.94923v2.33806c-.96483-.51765-2.08446-.81638-3.28068-.81638-1.23056,0-2.38067,.31529-3.36387,.86079v-2.38247c0-.52424-.42499-.94923-.94919-.94923-.52427,0-.94926,.42499-.94926,.94923v3.97662c-.04748,.05937-.09608,.11802-.14127,.17904-.71329-.04201-1.3855,.00186-1.98891,.12083l-1.44639-2.50523c-.26214-.454-.84265-.60956-1.29668-.34744-.45396,.26212-.60956,.84265-.34742,1.29666l1.3131,2.27434c-.45821,.33011-.78019,.74715-.91316,1.24341-.4794,1.78902,1.63174,3.91692,4.74237,4.81674,1.18543,1.54332,3.13568,2.55051,5.34068,2.55051,2.16654,0,4.08717-.97236,5.27789-2.47027l.08614-.02269c3.22313-.86365,5.43885-3.04593,4.94898-4.87428-.10615-.39608-.33369-.74118-.65292-1.03296l1.43462-2.48479c.26214-.45401,.10654-1.03455-.34742-1.29666Z"
          style="fill: #64aab9;"/>
    <circle id="uuid-9c49eae4-1ed4-42b1-9faf-0a5351893636" data-name="Oval-7" cx="82.13762" cy="29.20912" r="1.89844"
            style="fill: #3a7391;"/>
    <circle id="uuid-c2afa1f7-e29d-4872-9794-55f9ae934d07" data-name="Oval-7-Copy" cx="73.59465" cy="29.20912" r="1.89844"
            style="fill: #3a7391;"/>
    <circle id="uuid-a2a84fa1-fc6f-4f6c-9b17-c9dcb565e9b0" data-name="Oval-7-Copy-2" cx="67.89933" cy="31.10756" r="1.89844"
            style="fill: #3a7391;"/>
    <circle id="uuid-15b4783c-9e1a-464b-9386-a73d65ebd05d" data-name="Oval-7-Copy-4" cx="88.78216" cy="31.10756" r="1.89844"
            style="fill: #3a7391;"/>
    <polygon points="99.1902 113.56056 77.56427 100.02021 55.93834 113.56056 77.56427 135.18649 99.1902 113.56056" style="fill: #b4af9b;"/>
    <path d="M47.46396,33.07859h-4.10547c-.29688-1.89062-2.10645-3.37598-4.21289-3.37598-3.45703,0-5.23975,2.72755-5.23975,6.6167,0,3.61914,1.70166,6.45508,5.23975,6.45508,2.43066,0,4.02393-1.56641,4.375-4.32129h4.10547c-.43213,4.86133-3.75439,7.88623-8.48047,7.88623-5.88818,0-9.47998-4.375-9.47998-10.02002,0-5.69873,3.51074-10.18164,9.47998-10.18164,4.32129,0,7.83203,2.59277,8.31836,6.94092Z"
          style="fill: #004132;"/>
    <path d="M113.39018,26.13767l8.04834,12.93701h.0542v-12.93701h3.97021v19.28369h-4.24023l-8.02148-12.90967h-.0542v12.90967h-3.96973V26.13767h4.21289Z"
          style="fill: #004132;"/>
</svg>
</template>

<script>
export default {
  name: 'F4NBiomassIcon',
};
</script>

<style scoped>

</style>
