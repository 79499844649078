
<template>
  <svg width="200px" height="200px" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 64 (93537) - https://sketch.com -->
    <title>toolicon/Field_Vitality_Check_Corn</title>
    <desc>Created with Sketch.</desc>
    <g id="toolicon/Field_Vitality_Check_Corn" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="Rectangle" fill="#E8E6D4" x="0" y="0" width="200" height="200"></rect>
        <polygon id="Path" fill="#96A02D" fill-rule="nonzero" points="90.65 118.08 104.54 94.18 116.09 134.58 125.39 118.04 149.12 118.33 140.35 85.98 84.38 85.98 75.08 118.32"></polygon>
        <polygon id="Path" fill="#96A02D" fill-rule="nonzero" points="150.53 123.5 128.38 123.24 114.53 147.87 102.91 107.24 93.64 123.19 73.59 123.5 64.48 155.22 159.13 155.22"></polygon>
        <path d="M90.65,118.08 L90.74,117.93 C90.22,117.02 89.74,116.07 89.15,115.01 C86.57,110.16 86.21,106.01 83.26,106.01 C81.26,106.01 79.45,107.17 77.74,109.48 L75.17,118.33 L90.65,118.08 Z" id="Path" fill="#257150"></path>
        <path d="M117.06,132.86 C116.525419,132.453224 115.940592,132.117117 115.32,131.86 L116.09,134.55 L117.06,132.86 Z" id="Path" fill="#257150"></path>
        <path d="M114.53,147.87 L109.61,130.67 C106.18,130.25 102.52,129.86 99.47,127.49 C97.4961995,126.021716 95.6648083,124.371121 94,122.56 L93.63,123.19 L73.63,123.5 L64.44,155.22 L135.89,155.22 C128.85,147.073333 123.76,141 120.62,137 L114.53,147.87 Z" id="Path" fill="#257150"></path>
        <path d="M81,131.21 C84,129.876667 86.21,129.876667 87.63,131.21 C89.76,133.21 93.15,132.86 95.84,134.51 C98.53,136.16 98.84,139.66 97.97,142.62 C97.1,145.58 83.61,148.19 80.42,146.16 C77.23,144.13 74.96,142.4 77.23,138.06 C78.7,135.16 80,132.88 81,131.21 Z" id="Path" fill="#004132"></path>
        <path d="M96.63,107.8 L104.54,94.18 L110,113.4 C114.59,113.65 119.73,113.4 122.78,114.29 C125.037947,114.901837 127.035286,116.232226 128.47,118.08 L149.09,118.33 L140.35,86.09 L140.35,85.98 L84.35,85.98 L83.1,90.35 C84.24,92.29 89.52,101.61 96.63,107.8 Z" id="Path" fill="#DCB900"></path>
        <path d="M102.91,107.24 L100.8,110.88 C101.628295,111.382309 102.490354,111.826703 103.38,112.21 C103.724289,112.360603 104.078443,112.487564 104.44,112.59 L102.91,107.24 Z" id="Path" fill="#DCB900"></path>
        <path d="M150.53,123.51 L131.73,123.28 C132.25,124.17 132.8,125.09 133.45,126.02 C137.603333,132.02 145.293333,138.55 156.52,145.61 L150.53,123.51 Z" id="Path" fill="#DCB900"></path>
        <path d="M104.54,94.18 L106.06,99.48 C106.32,99.66 106.58,99.83 106.83,99.97 C117.83,106.27 131.51,99.97 137.03,99.97 C140.71,99.97 143.78,102.536667 146.24,107.67 L140.35,86 L95.41,86 C97.5571974,89.8481549 100.101039,93.4611478 103,96.78 L104.54,94.18 Z" id="Path" fill="#FAB900"></path>
        <path d="M106.46,86 C107.54,90.4266667 110.873333,93.3066667 116.46,94.64 C124.79,96.64 127.14,92.48 131.93,92.48 C135.12,92.48 139.18,94.54 143.11,95.98 L140.4,85.98 L106.46,86 Z" id="Path" fill="#CC3333"></path>
        <path d="M118,62.09 C119.82115,63.1502655 122.018801,63.3480541 124,62.63 L124.34,63.85 C122.035246,64.6002542 119.520253,64.3358207 117.421942,63.1226156 C115.323632,61.9094105 113.839681,59.8617279 113.34,57.49 L114.57,57.18 C114.929356,59.2440366 116.185641,61.0423911 118,62.09 L118,62.09 Z" id="Path" fill="#5F696E"></path>
        <path d="M124.85,65.78 L125.26,67.22 C122.02742,68.2296672 118.520797,67.8525699 115.576802,66.1786824 C112.632807,64.5047949 110.515477,61.684242 109.73,58.39 L111.19,58.03 C111.829876,60.9573592 113.684386,63.4745182 116.290623,64.9531666 C118.89686,66.4318151 122.00892,66.7324392 124.85,65.78 L124.85,65.78 Z" id="Path" fill="#5F696E"></path>
        <path d="M125.87,69.39 L126.28,70.83 C122.058382,72.1132239 117.495466,71.6121666 113.652913,69.44341 C109.81036,67.2746534 107.023227,63.6273052 105.94,59.35 L107.39,58.99 C108.332849,62.9002146 110.859675,66.2420576 114.364991,68.2147464 C117.870307,70.1874352 122.03822,70.6131935 125.87,69.39 L125.87,69.39 Z" id="Path" fill="#5F696E"></path>
        <rect id="Rectangle" fill="#B4CDD7" fill-rule="nonzero" transform="translate(138.489349, 54.348890) rotate(-63.000000) translate(-138.489349, -54.348890) " x="135.609349" y="47.8688901" width="5.76" height="12.96"></rect>
        <rect id="Rectangle" fill="#B4CDD7" fill-rule="nonzero" transform="translate(150.545254, 60.490853) rotate(-63.000000) translate(-150.545254, -60.490853) " x="147.665254" y="54.0108531" width="5.76" height="12.96"></rect>
        <rect id="Rectangle" fill="#B4CDD7" fill-rule="nonzero" transform="translate(135.607133, 59.995495) rotate(-63.000000) translate(-135.607133, -59.995495) " x="132.727133" y="53.5154953" width="5.76" height="12.96"></rect>
        <rect id="Rectangle" fill="#B4CDD7" fill-rule="nonzero" transform="translate(147.671949, 66.141998) rotate(-63.000000) translate(-147.671949, -66.141998) " x="144.791949" y="59.6619982" width="5.76" height="12.96"></rect>
        <polygon id="Path" fill="#5F696E" points="129.64 49.84 133.51 48.58 128.02 59.35 126.76 55.48"></polygon>
        <rect id="Rectangle" fill="#B4CDD7" fill-rule="nonzero" transform="translate(109.702953, 46.788800) rotate(-63.000000) translate(-109.702953, -46.788800) " x="106.822953" y="40.3087998" width="5.76" height="12.96"></rect>
        <rect id="Rectangle" fill="#B4CDD7" fill-rule="nonzero" transform="translate(97.651588, 40.647927) rotate(-63.000000) translate(-97.651588, -40.647927) " x="94.7715875" y="34.1679267" width="5.76" height="12.96"></rect>
        <rect id="Rectangle" fill="#B4CDD7" fill-rule="nonzero" transform="translate(112.585169, 41.142195) rotate(-63.000000) translate(-112.585169, -41.142195) " x="109.705169" y="34.6621946" width="5.76" height="12.96"></rect>
        <rect id="Rectangle" fill="#B4CDD7" fill-rule="nonzero" transform="translate(100.520353, 35.005692) rotate(-63.000000) translate(-100.520353, -35.005692) " x="97.6403534" y="28.5256917" width="5.76" height="12.96"></rect>
        <rect id="Rectangle" fill="#233737" fill-rule="nonzero" transform="translate(124.098421, 50.564390) rotate(-63.000000) translate(-124.098421, -50.564390) " x="120.068421" y="46.5343899" width="8.06" height="8.06" rx="1.67"></rect>
        <polygon id="Path" fill="#5F696E" points="118.55 51.3 114.68 52.56 120.17 41.78 121.43 45.66"></polygon>
        <path d="M43.69,111.63 C43.69,111.63 42.15,74 44.5,73.8 C44.98,73.8 44.9,76.56 45.68,78.49 C46.38,80.22 50.84,91.63 50.84,91.63 L50.84,133.05 L43.69,111.63 Z" id="Path" fill="#257150"></path>
        <path d="M50.16,133.38 L63.71,129.29 L64.06,122.64 L64.91,105.24 C64.68,88.01 59.21,74.41 55.91,74.4598626 C52.61,74.51 47.19,91.99 47.37,105.46 C47.5213407,113.915298 48.1154881,122.356863 49.15,130.75 L50.16,133.38 Z" id="Path" fill="#FAB900"></path>
        <path d="M49.68,94 L50.78,94 C51.47,94 52.01,93.19 52,92.21 C51.99,91.23 51.42,90.45 50.73,90.46 L49.63,90.46 C48.94,90.46 48.4,91.26 48.41,92.24 C48.42,93.22 49,94 49.68,94" id="Path" fill="#FADE64"></path>
        <path d="M54.56,105.87 L53.24,105.87 C52.41,105.87 51.75,106.87 51.76,108.02 C51.77,109.17 52.46,110.15 53.29,110.14 L54.62,110.14 C55.44,110.14 56.11,109.14 56.09,107.99 C56.07,106.84 55.39,105.86 54.56,105.88" id="Path" fill="#FADE64"></path>
        <path d="M50.16,122.28 L49.41,122.28 C49.97,123.58 50.57,124.77 51.17,125.88 C51.5000208,125.428345 51.6690325,124.879056 51.65,124.32 C51.65,123.18 50.97,122.26 50.16,122.32" id="Path" fill="#FADE64"></path>
        <path d="M49.82,105.93 L48.49,105.93 C47.66,105.93 47,106.93 47.02,108.09 C47.04,109.25 47.72,110.21 48.54,110.2 L49.88,110.2 C50.71,110.2 51.36,109.2 51.35,108.04 C51.34,106.88 50.65,105.92 49.82,105.93" id="Path" fill="#FADE64"></path>
        <path d="M48.91,121.08 L50,121.08 C50.82,121.08 51.49,120.08 51.47,118.92 C51.45,117.76 50.77,116.8 49.94,116.81 L48.61,116.81 C48.2047747,116.837847 47.8353894,117.052091 47.61,117.39 L48.1,118.83 C48.36,119.61 48.64,120.35 48.92,121.07" id="Path" fill="#FADE64"></path>
        <path d="M48.55,111.43 C47.72,111.43 47.06,112.43 47.08,113.58 C47.1,114.73 47.78,115.71 48.6,115.69 L49.93,115.69 C50.76,115.69 51.42,114.69 51.4,113.53 C51.38,112.37 50.71,111.41 49.87,111.42 L48.55,111.43 Z" id="Path" fill="#FADE64"></path>
        <path d="M56.52,75.75 L56.93,75.75 C57.19,75.75 57.39,75.45 57.39,75.08 C57.39,74.71 57.17,74.43 56.92,74.43 L56.5,74.43 C56.25,74.43 56.04,74.73 56.05,75.09 C56.06,75.45 56.26,75.75 56.52,75.75" id="Path" fill="#FADE64"></path>
        <path d="M54.91,86.38 L53.91,86.38 C53.29,86.38 52.8,87.11 52.82,87.98 C52.84,88.85 53.33,89.55 53.95,89.55 L54.95,89.55 C55.56,89.55 56.05,88.83 56.04,87.96 C56.03,87.09 55.52,86.38 54.91,86.39" id="Path" fill="#FADE64"></path>
        <path d="M53.4,121 L54.73,121 C55.55,121 56.22,120 56.2,118.84 C56.18,117.68 55.5,116.72 54.67,116.73 L53.35,116.73 C52.52,116.73 51.86,117.73 51.88,118.88 C51.9,120.03 52.58,121.01 53.4,120.99" id="Path" fill="#FADE64"></path>
        <path d="M53.12,104.6 L54.41,104.6 C55.21,104.6 55.86,103.6 55.84,102.5 C55.82,101.4 55.16,100.44 54.35,100.5 L53.07,100.5 C52.26,100.5 51.62,101.44 51.64,102.59 C51.66,103.74 52.32,104.65 53.12,104.59" id="Path" fill="#FADE64"></path>
        <path d="M53.29,111.36 C52.46,111.36 51.8,112.36 51.82,113.52 C51.84,114.68 52.52,115.64 53.35,115.63 L54.67,115.63 C55.5,115.63 56.16,114.63 56.15,113.47 C56.14,112.31 55.44,111.35 54.62,111.36 L53.29,111.36 Z" id="Path" fill="#FADE64"></path>
        <path d="M51.91,97.18 C51.91,98.26 52.55,99.13 53.32,99.12 L54.54,99.12 C55.3,99.12 55.91,98.22 55.89,97.12 C55.87,96.02 55.25,95.18 54.48,95.19 L53.26,95.19 C52.5,95.19 51.9,96.09 51.91,97.19" id="Path" fill="#FADE64"></path>
        <path d="M62.2,95.08 C61.45,95.08 60.84,95.98 60.85,97.08 C60.86,98.18 61.5,99.08 62.26,99.08 L63.48,99.08 C64.24,99.08 64.84,98.18 64.83,97.08 C64.82,95.98 64.19,95.08 63.43,95.14 L62.2,95.08 Z" id="Path" fill="#FADE64"></path>
        <path d="M56.7,76.06 C56.45,76.06 56.24,76.36 56.25,76.73 C56.26,77.1 56.46,77.38 56.72,77.38 L57.14,77.38 C57.39,77.38 57.59,77.08 57.59,76.72 C57.59,76.36 57.37,76.06 57.12,76.07 L56.7,76.06 Z" id="Path" fill="#FADE64"></path>
        <path d="M55.06,83 L54.24,83 C53.72,83 53.31,83.6 53.32,84.33 C53.33,85.06 53.75,85.65 54.32,85.64 L55.14,85.64 C55.66,85.64 56.07,85.04 56.06,84.31 C56.05,83.58 55.57,83 55.06,83" id="Path" fill="#FADE64"></path>
        <path d="M61.79,90.29 C61.1,90.29 60.56,91.09 60.57,92.07 C60.58,93.05 61.15,93.84 61.83,93.83 L62.94,93.83 C63.63,93.83 64.17,93.02 64.16,92.04 C64.15,91.06 63.58,90.28 62.89,90.29 L61.79,90.29 Z" id="Path" fill="#FADE64"></path>
        <path d="M63.1,110 L64.1,105.73 L63.1,105.73 C62.27,105.73 61.61,106.73 61.63,107.89 C61.65,109.05 62.3,110 63.1,110" id="Path" fill="#FADE64"></path>
        <path d="M64,100.31 L62.71,100.31 C61.9,100.31 61.26,101.31 61.28,102.4 C61.3,103.49 61.95,104.47 62.76,104.46 L64.05,104.46 C64.1922243,104.456213 64.3320032,104.42212 64.46,104.36 L65.2,101.19 C64.9999546,100.692287 64.5348264,100.351193 64,100.31" id="Path" fill="#FADE64"></path>
        <path d="M61.13,86.3 C60.52,86.3 60.03,87.02 60.04,87.9 C60.05,88.78 60.56,89.47 61.17,89.46 L62.17,89.46 C62.79,89.46 63.27,88.74 63.26,87.87 C63.25,87 62.75,86.29 62.13,86.3 L61.13,86.3 Z" id="Path" fill="#FADE64"></path>
        <path d="M61.72,113.39 C61.724424,113.817572 61.8270665,114.238406 62.02,114.62 L62.79,111.33 C62.0595419,111.746767 61.640907,112.552738 61.72,113.39" id="Path" fill="#FADE64"></path>
        <path d="M58.52,78.68 C58.52,79.18 58.81,79.57 59.16,79.57 L59.72,79.57 C60.07,79.57 60.35,79.16 60.34,78.66 C60.33,78.16 60.05,77.77 59.7,77.77 L59.14,77.77 C58.79,77.77 58.51,78.18 58.52,78.68" id="Path" fill="#FADE64"></path>
        <path d="M58.48,77.36 L58.89,77.36 C59.15,77.36 59.35,77.06 59.34,76.7 C59.33,76.34 59.13,76.04 58.87,76.04 L58.46,76.04 C58.21,76.04 58,76.34 58,76.7 C58,77.06 58.22,77.36 58.48,77.36" id="Path" fill="#FADE64"></path>
        <path d="M58.89,90.32 L57.79,90.32 C57.1,90.32 56.55,91.13 56.57,92.1 C56.59,93.07 57.14,93.87 57.83,93.86 L58.93,93.86 C59.62,93.86 60.17,93.05 60.15,92.07 C60.13,91.09 59.58,90.31 58.89,90.32" id="Path" fill="#FADE64"></path>
        <path d="M59,81.24 C59,81.87 59.37,82.38 59.82,82.37 L60.53,82.37 C60.97,82.37 61.32,81.85 61.31,81.22 C61.3,80.59 60.94,80.09 60.5,80.09 L59.79,80.09 C59.34,80.09 58.99,80.61 59,81.24" id="Path" fill="#FADE64"></path>
        <path d="M60.37,82.94 C59.86,82.94 59.45,83.54 59.46,84.27 C59.47,85 59.89,85.59 60.4,85.58 L61.23,85.58 C61.74,85.58 62.15,84.98 62.14,84.25 C62.13,83.52 61.71,82.93 61.2,82.93 L60.37,82.94 Z" id="Path" fill="#FADE64"></path>
        <path d="M54.78,93.92 C55.47,93.92 56.02,93.11 56,92.13 C55.98,91.15 55.42,90.37 54.74,90.38 L53.63,90.38 C52.95,90.38 52.4,91.19 52.42,92.17 C52.44,93.15 53,93.93 53.68,93.92 L54.78,93.92 Z" id="Path" fill="#FADE64"></path>
        <path d="M57.3,85.62 L58.13,85.62 C58.64,85.62 59.05,85.02 59.04,84.29 C59.03,83.56 58.61,83 58.1,83 L57.27,83 C56.76,83 56.35,83.6 56.36,84.33 C56.37,85.06 56.79,85.65 57.3,85.64" id="Path" fill="#FADE64"></path>
        <path d="M57.13,82.41 L57.84,82.41 C58.28,82.41 58.63,81.89 58.63,81.26 C58.63,80.63 58.25,80.12 57.81,80.13 L57.1,80.13 C56.65,80.13 56.3,80.65 56.31,81.28 C56.32,81.91 56.68,82.41 57.13,82.41" id="Path" fill="#FADE64"></path>
        <path d="M57.55,89.51 L58.55,89.51 C59.17,89.51 59.65,88.78 59.64,87.91 C59.63,87.04 59.13,86.34 58.51,86.34 L57.51,86.34 C56.89,86.34 56.4,87.06 56.42,87.94 C56.44,88.82 56.94,89.51 57.55,89.5" id="Path" fill="#FADE64"></path>
        <path d="M57.86,99.06 L59.09,99.06 C59.85,99.06 60.45,98.16 60.44,97.06 C60.43,95.96 59.8,95.11 59.04,95.12 L57.81,95.12 C57.06,95.12 56.45,96.02 56.46,97.12 C56.47,98.22 57.11,99.07 57.86,99.06" id="Path" fill="#FADE64"></path>
        <path d="M55.16,80.15 L54.45,80.15 C54.01,80.15 53.65,80.67 53.66,81.3 C53.67,81.93 54.04,82.44 54.48,82.43 L55.19,82.43 C55.63,82.43 55.98,81.91 55.98,81.28 C55.98,80.65 55.6,80.15 55.16,80.15" id="Path" fill="#FADE64"></path>
        <path d="M56.92,79.6 L57.48,79.6 C57.83,79.6 58.11,79.19 58.1,78.69 C58.09,78.19 57.81,77.8 57.46,77.8 L56.89,77.8 C56.55,77.8 56.27,78.21 56.27,78.71 C56.27,79.21 56.57,79.6 56.92,79.6" id="Path" fill="#FADE64"></path>
        <path d="M58.42,110.08 L59.75,110.08 C60.57,110.08 61.24,109.08 61.22,107.92 C61.2,106.76 60.52,105.8 59.69,105.81 L58.37,105.81 C57.54,105.81 56.88,106.81 56.89,107.96 C56.9,109.11 57.6,110.08 58.42,110.07" id="Path" fill="#FADE64"></path>
        <path d="M58.6,121 L59.92,121 C60.1675445,120.991404 60.4058378,120.903796 60.6,120.75 L61.25,117.97 C61.0970749,117.303386 60.5396788,116.80614 59.86,116.73 L58.54,116.73 C57.71,116.73 57.05,117.73 57.06,118.88 C57.07,120.03 57.77,121 58.6,121" id="Path" fill="#FADE64"></path>
        <path d="M60.24,122.23 C60.1197777,122.178759 59.9906773,122.15158 59.86,122.15 L58.57,122.15 C57.77,122.15 57.13,123.15 57.14,124.24 C57.15,125.33 57.82,126.31 58.63,126.3 L59.28,126.3 L60.24,122.23 Z" id="Path" fill="#FADE64"></path>
        <path d="M55,127.79 L53.68,127.79 C53.2793793,127.799303 52.9075059,128.000114 52.68,128.33 C53.19,129.14 53.68,129.87 54.21,130.55 L56.36,129.08 C56.12,128.3 55.58,127.76 54.96,127.77" id="Path" fill="#FADE64"></path>
        <path d="M58.11,104.54 L59.4,104.54 C60.21,104.54 60.85,103.54 60.83,102.45 C60.81,101.36 60.15,100.38 59.35,100.39 L58.06,100.39 C57.25,100.39 56.61,101.39 56.62,102.48 C56.63,103.57 57.31,104.55 58.11,104.54" id="Path" fill="#FADE64"></path>
        <path d="M58.51,115.56 L59.84,115.56 C60.66,115.56 61.32,114.56 61.31,113.4 C61.3,112.24 60.61,111.28 59.78,111.29 L58.45,111.29 C57.63,111.29 56.96,112.29 56.98,113.44 C57,114.59 57.68,115.56 58.51,115.55" id="Path" fill="#FADE64"></path>
        <path d="M54.78,122.22 L53.49,122.22 C52.68,122.22 52.05,123.16 52.06,124.31 C52.07,125.46 52.74,126.38 53.55,126.31 L54.83,126.31 C55.64,126.31 56.28,125.31 56.27,124.21 C56.26,123.11 55.59,122.15 54.78,122.21" id="Path" fill="#FADE64"></path>
        <path d="M53.29,77.43 L53.71,77.43 C53.96,77.43 54.17,77.13 54.16,76.77 C54.15,76.41 53.95,76.11 53.69,76.11 L53.28,76.11 C53.02,76.11 52.82,76.41 52.82,76.77 C52.82,77.13 53.04,77.43 53.29,77.43" id="Path" fill="#FADE64"></path>
        <path d="M54.76,79.63 L55.33,79.63 C55.67,79.63 55.95,79.22 55.95,78.72 C55.95,78.22 55.65,77.83 55.3,77.83 L54.74,77.83 C54.39,77.83 54.11,78.24 54.12,78.74 C54.13,79.24 54.41,79.63 54.76,79.63" id="Path" fill="#FADE64"></path>
        <path d="M55.06,75.77 L55.47,75.77 C55.73,75.77 55.93,75.47 55.93,75.1 C55.93,74.73 55.71,74.45 55.45,74.45 L55,74.45 C54.75,74.45 54.54,74.74 54.55,75.11 C54.56,75.48 54.76,75.77 55.02,75.77" id="Path" fill="#FADE64"></path>
        <path d="M52.52,79.66 L53.08,79.66 C53.43,79.66 53.71,79.25 53.7,78.75 C53.69,78.25 53.41,77.86 53.06,77.86 L52.5,77.86 C52.15,77.86 51.87,78.27 51.88,78.77 C51.89,79.27 52.17,79.66 52.52,79.66" id="Path" fill="#FADE64"></path>
        <path d="M55.05,77.4 L55.46,77.4 C55.72,77.4 55.92,77.1 55.92,76.73 C55.92,76.36 55.7,76.08 55.45,76.08 L55,76.08 C54.75,76.08 54.55,76.38 54.55,76.75 C54.55,77.12 54.76,77.41 55.02,77.4" id="Path" fill="#FADE64"></path>
        <path d="M48.92,99.18 L50.15,99.18 C50.91,99.18 51.51,98.29 51.5,97.18 C51.49,96.07 50.86,95.18 50.1,95.24 L48.87,95.24 C48.11,95.24 47.51,96.13 47.52,97.24 C47.53,98.35 48.17,99.24 48.92,99.24" id="Path" fill="#FADE64"></path>
        <path d="M48.5,104.66 L49.79,104.66 C50.6,104.66 51.24,103.66 51.22,102.56 C51.2,101.46 50.54,100.5 49.74,100.56 L48.44,100.56 C47.64,100.56 47,101.5 47.02,102.65 C47.04,103.8 47.69,104.72 48.5,104.65" id="Path" fill="#FADE64"></path>
        <path d="M51.17,85.7 L52,85.7 C52.51,85.7 52.92,85.1 52.91,84.37 C52.9,83.64 52.47,83 52,83.05 L51.17,83.05 C50.66,83.05 50.25,83.65 50.26,84.38 C50.27,85.11 50.69,85.7 51.26,85.69" id="Path" fill="#FADE64"></path>
        <path d="M51.75,82.48 L52.46,82.48 C52.9,82.48 53.25,81.96 53.25,81.33 C53.25,80.7 52.87,80.19 52.43,80.2 L51.72,80.2 C51.28,80.2 50.92,80.72 50.93,81.35 C50.94,81.98 51.31,82.48 51.75,82.48" id="Path" fill="#FADE64"></path>
        <path d="M50.35,89.6 L51.35,89.6 C51.95,89.6 52.44,88.88 52.43,88.01 C52.42,87.14 51.91,86.43 51.3,86.44 L50.3,86.44 C49.68,86.44 49.19,87.16 49.21,88.04 C49.23,88.92 49.72,89.61 50.34,89.6" id="Path" fill="#FADE64"></path>
        <path d="M74.83,84.13 C74.05,85.53 64.72,98.52 64.72,98.52 L56.66,130.58 C52.5471992,126.30408 49.4834153,121.131591 47.71,115.47 C42.91,100.67 36.79,82.55 33.89,77 C33.05,75.4 30.81,75.5 30.77,76 C30.73,76.5 31.96,76.85 32.58,78.33 C32.9856815,79.3400053 33.2872434,80.3887703 33.48,81.46 C34.0982608,84.2742288 34.4001336,87.1487286 34.38,90.03 C34.38,97.35 34.38,99.22 37.79,109.19 C41.86,121.12 47.4,135 49.1,136.72 C49.3443693,136.952902 49.6384264,137.127285 49.96,137.23 L49.96,139.61 C49.9546453,139.891144 50.0612199,140.162895 50.2562684,140.365445 C50.4513168,140.567996 50.7188542,140.684745 51,140.690193 L60.08,140.690193 C60.3680814,140.695489 60.6459843,140.583467 60.8497255,140.379726 C61.0534668,140.175984 61.1654888,139.898081 61.16,139.61 L61.16,137.22 C61.9631953,137.155598 62.7306581,136.86042 63.37,136.37 C65.98,134.13 64.04,124.37 65.27,120.32 C65.27,120.18 65.33,120.08 65.35,119.99 C67.7283729,115.990365 69.7977269,111.814884 71.54,107.5 C75.1,98.11 77.64,82.3 76.18,81.01 C75.7,80.54 75.6,82.73 74.83,84.13 Z" id="Path" fill="#257150"></path>
    </g>
</svg>
</template>

<script>
export default {
  name: 'FieldVitalityCheckCornIcon',
};
</script>

<style scoped>

</style>
