
<template>
  <svg width="200px" height="200px" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 64 (93537) - https://sketch.com -->
    <title>toolicon/VRS</title>
    <desc>Created with Sketch.</desc>
    <g id="toolicon/VRS" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon id="Fill-1" fill="#E8E6D4" points="0 200 200 200 200 0 0 0"></polygon>
        <path d="M151.1193,153.3004 C149.2413,153.3004 147.7163,151.7744 147.7163,149.8984 C147.7163,148.0224 149.2413,146.4954 151.1193,146.4954 C152.9933,146.4954 154.5203,148.0224 154.5203,149.8984 C154.5203,151.7744 152.9933,153.3004 151.1193,153.3004" id="Fill-2" fill="#FF6C00"></path>
        <path d="M139.7795,153.3004 C137.9005,153.3004 136.3755,151.7744 136.3755,149.8984 C136.3755,148.0224 137.9005,146.4954 139.7795,146.4954 C141.6525,146.4954 143.1795,148.0224 143.1795,149.8984 C143.1795,151.7744 141.6525,153.3004 139.7795,153.3004" id="Fill-4" fill="#FF6C00"></path>
        <path d="M128.4387,153.3004 C126.5607,153.3004 125.0357,151.7744 125.0357,149.8984 C125.0357,148.0224 126.5607,146.4954 128.4387,146.4954 C130.3127,146.4954 131.8387,148.0224 131.8387,149.8984 C131.8387,151.7744 130.3127,153.3004 128.4387,153.3004" id="Fill-6" fill="#FF6C00"></path>
        <path d="M117.0979,153.3004 C115.2199,153.3004 113.6949,151.7744 113.6949,149.8984 C113.6949,148.0224 115.2199,146.4954 117.0979,146.4954 C118.9719,146.4954 120.4979,148.0224 120.4979,149.8984 C120.4979,151.7744 118.9719,153.3004 117.0979,153.3004" id="Fill-8" fill="#FF6C00"></path>
        <path d="M105.757,153.3004 C103.879,153.3004 102.354,151.7744 102.354,149.8984 C102.354,148.0224 103.879,146.4954 105.757,146.4954 C107.631,146.4954 109.158,148.0224 109.158,149.8984 C109.158,151.7744 107.631,153.3004 105.757,153.3004" id="Fill-10" fill="#FF6C00"></path>
        <path d="M151.1193,132.8873 C149.2413,132.8873 147.7163,131.3613 147.7163,129.4853 C147.7163,127.6093 149.2413,126.0833 151.1193,126.0833 C152.9933,126.0833 154.5203,127.6093 154.5203,129.4853 C154.5203,131.3613 152.9933,132.8873 151.1193,132.8873" id="Fill-12" fill="#FF6C00"></path>
        <path d="M139.7795,132.8873 C137.9005,132.8873 136.3755,131.3613 136.3755,129.4853 C136.3755,127.6093 137.9005,126.0833 139.7795,126.0833 C141.6525,126.0833 143.1795,127.6093 143.1795,129.4853 C143.1795,131.3613 141.6525,132.8873 139.7795,132.8873" id="Fill-14" fill="#FF6C00"></path>
        <path d="M128.4387,132.8873 C126.5607,132.8873 125.0357,131.3613 125.0357,129.4853 C125.0357,127.6093 126.5607,126.0833 128.4387,126.0833 C130.3127,126.0833 131.8387,127.6093 131.8387,129.4853 C131.8387,131.3613 130.3127,132.8873 128.4387,132.8873" id="Fill-16" fill="#FF6C00"></path>
        <path d="M151.1193,117.0103 C149.2413,117.0103 147.7163,115.4843 147.7163,113.6083 C147.7163,111.7323 149.2413,110.2063 151.1193,110.2063 C152.9933,110.2063 154.5203,111.7323 154.5203,113.6083 C154.5203,115.4843 152.9933,117.0103 151.1193,117.0103" id="Fill-18" fill="#FF6C00"></path>
        <path d="M139.7795,117.0103 C137.9005,117.0103 136.3755,115.4843 136.3755,113.6083 C136.3755,111.7323 137.9005,110.2063 139.7795,110.2063 C141.6525,110.2063 143.1795,111.7323 143.1795,113.6083 C143.1795,115.4843 141.6525,117.0103 139.7795,117.0103" id="Fill-20" fill="#FF6C00"></path>
        <path d="M128.4387,117.0103 C126.5607,117.0103 125.0357,115.4843 125.0357,113.6083 C125.0357,111.7323 126.5607,110.2063 128.4387,110.2063 C130.3127,110.2063 131.8387,111.7323 131.8387,113.6083 C131.8387,115.4843 130.3127,117.0103 128.4387,117.0103" id="Fill-22" fill="#FF6C00"></path>
        <path d="M151.1193,105.67 C149.2413,105.67 147.7163,104.144 147.7163,102.268 C147.7163,100.392 149.2413,98.865 151.1193,98.865 C152.9933,98.865 154.5203,100.392 154.5203,102.268 C154.5203,104.144 152.9933,105.67 151.1193,105.67" id="Fill-24" fill="#FF6C00"></path>
        <path d="M139.7795,105.67 C137.9005,105.67 136.3755,104.144 136.3755,102.268 C136.3755,100.392 137.9005,98.865 139.7795,98.865 C141.6525,98.865 143.1795,100.392 143.1795,102.268 C143.1795,104.144 141.6525,105.67 139.7795,105.67" id="Fill-26" fill="#FF6C00"></path>
        <path d="M128.4387,105.67 C126.5607,105.67 125.0357,104.144 125.0357,102.268 C125.0357,100.392 126.5607,98.865 128.4387,98.865 C130.3127,98.865 131.8387,100.392 131.8387,102.268 C131.8387,104.144 130.3127,105.67 128.4387,105.67" id="Fill-28" fill="#FF6C00"></path>
        <polygon id="Fill-30" fill="#96A02D" points="128.4367 54.6827 86.4917 46.6997 45.4797 126.0827 115.9767 140.8267"></polygon>
        <path d="M45.4797,126.0831 L90.5807,135.5321 C92.4297,129.4891 92.2607,123.5431 90.0737,117.6951 C86.7927,108.9221 85.0107,111.0231 81.6697,103.5791 C78.3297,96.1331 81.6697,88.2241 81.6697,81.3621 C81.6697,74.5001 81.4717,71.6701 77.2887,70.6891 C75.9987,70.3861 75.0517,70.3261 74.0987,70.6891 L45.4797,126.0831 Z" id="Fill-32" fill="#257150"></path>
        <path d="M68.7863,100.7662 C70.5253,99.5352 71.3943,99.5352 71.3943,100.7662 C71.3943,102.6132 71.0903,107.9242 73.0603,109.7712 C75.0313,111.6182 77.3923,114.0662 74.3933,119.0322 C71.3943,123.9982 64.2993,123.9982 62.0973,120.8792 C59.8933,117.7602 57.6233,116.2192 59.2833,111.3572 C60.3903,108.1162 63.5583,104.5862 68.7863,100.7662" id="Fill-34" fill="#004132"></path>
        <path d="M86.4914,46.6998 L85.0584,49.4248 C85.5294,58.5398 86.3384,66.0138 87.2864,71.6998 C88.9294,81.5468 93.8584,85.1928 98.3384,85.8488 C102.8174,86.5038 117.2554,86.5978 117.2554,98.7358 C117.2554,104.7578 118.2684,108.3368 120.4984,109.6518 L128.4514,54.6378 L86.4914,46.6998 Z" id="Fill-36" fill="#DCB900"></path>
        <path d="M95.9504,48.4806 C95.2434,56.9576 95.9084,62.5316 97.9174,65.1446 C101.0964,69.2796 105.6394,69.4946 110.5534,71.9936 C115.4664,74.4926 119.1924,71.8336 123.2894,76.2766 C123.9774,77.0226 124.5534,77.7676 125.0144,78.5116 L128.4514,54.6386 L95.9504,48.4806 Z" id="Fill-38" fill="#FAB900"></path>
        <path d="M105.7385,50.3351 C105.8075,54.6951 106.5745,57.8181 107.9945,59.6861 C110.1875,62.5721 114.4825,59.5881 117.8725,61.3321 C121.2615,63.0761 122.6725,64.3551 125.4985,67.4551 C125.8535,67.8441 126.1645,68.2331 126.4325,68.6211 C126.9375,64.7951 127.6105,60.1341 128.4515,54.6381 L105.7385,50.3351 Z" id="Fill-40" fill="#CC3333"></path>
    </g>
</svg>
</template>

<script>
export default {
  name: 'VariableRateSowingIcon',
};
</script>

<style scoped>

</style>
