
<template>
  <!-- Generator: Adobe Illustrator 24.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 200 200" xml:space="preserve">
<g id="KWS_x5F_icon_x5F_SAT_x5F_DM_x5F_monitoring_x5F_EN_Background">
	<polygon id="Hintergrund" class="st0" points="0,0 200,0 200,200 0,200 	"/>
</g>
<g id="KWS_x5F_icon_x5F_SAT_x5F_DM_x5F_monitoring_x5F_EN">
	<g>
		<path class="st1" d="M133.59302,147.91101H132.263v6.612h1.63403c2.60297,0,2.98297-2.10901,2.98297-3.38202
			C136.88,150.28601,136.61401,147.91101,133.59302,147.91101z"/>
		<path class="st1" d="M110.57971,146.21948c-0.84741,0-0.84741,1.04633-0.84741,1.89209c0,0.8457,0,1.89539,0.84741,1.89539
			c0.84576,0,0.84576-1.04968,0.84576-1.89539C111.42548,147.26581,111.42548,146.21948,110.57971,146.21948z"/>
		<path class="st1" d="M120.39771,152.32355c-0.84576,0-0.84576,1.04797-0.84576,1.89374c0,0.84741,0,1.89374,0.84576,1.89374
			c0.84741,0,0.84741-1.04633,0.84741-1.89374C121.24512,153.37152,121.24512,152.32355,120.39771,152.32355z"/>
		<path class="st1" d="M60.258,147.43127c-1.89368,0-1.96722,2.35339-1.96722,3.73401c0,1.34216,0.07355,3.7323,1.92883,3.7323
			c1.87701,0,1.96893-2.37177,1.96893-3.7323C62.18854,149.84149,62.09662,147.43127,60.258,147.43127z"/>
		<polygon class="st1" points="72.54193,92.21326 72.54211,92.21759 72.78894,91.16022 		"/>
		<path class="st2" d="M114.77271,85.26007c-3.20917-1.85284-5.30072-4.89758-6.03754-8.25079l-1.74219,0.44019
			c0.9024,3.7771,3.29694,7.19104,6.92731,9.28705c3.68915,2.12994,7.91882,2.47418,11.68683,1.30078l-0.48975-1.73273
			C121.80042,87.41199,118.04108,87.14703,114.77271,85.26007z"/>
		<path class="st2" d="M111.52393,90.60266c4.8299,2.78857,10.36145,3.25903,15.30475,1.75647l-0.48901-1.73022
			c-4.49329,1.43506-9.5542,1.04285-13.96326-1.50269c-4.28906-2.47632-7.12183-6.50568-8.1969-10.96582l-1.7384,0.43921
			C103.67902,83.48346,106.81372,87.88324,111.52393,90.60266z"/>
		<path class="st2" d="M116.88495,81.88605c-2.26794-1.30939-3.70795-3.50171-4.1358-5.89099l-1.47485,0.37268
			c0.58136,2.74182,2.28046,5.23621,4.90027,6.74872c2.61761,1.51129,5.62451,1.73645,8.28809,0.87152l-0.4137-1.46368
			C121.76715,83.34607,119.151,83.19434,116.88495,81.88605z"/>
		<path class="st3" d="M119.4942,71.08661l5.04889,2.57257c0.98419,0.50146,2.18854,0.11011,2.69-0.87402l2.57251-5.04889
			c0.50146-0.98419,0.11017-2.18854-0.87402-2.69l-5.04889-2.57251c-0.98419-0.50146-2.18854-0.11017-2.69,0.87402l-2.57251,5.04889
			C118.11871,69.3808,118.51001,70.58514,119.4942,71.08661z"/>
		
			<rect x="138.0186" y="64.83009" transform="matrix(0.45399 -0.89101 0.89101 0.45399 12.55908 165.69019)" class="st4" width="6.90459" height="15.53539"/>
		
			<rect x="152.47594" y="72.19647" transform="matrix(0.45399 -0.89101 0.89101 0.45399 13.88982 182.59442)" class="st4" width="6.90463" height="15.53537"/>
		
			<rect x="134.5705" y="71.59734" transform="matrix(0.45399 -0.89101 0.89101 0.45399 4.64709 166.31342)" class="st4" width="6.90462" height="15.5354"/>
		
			<rect x="149.02785" y="78.96371" transform="matrix(0.45399 -0.89101 0.89101 0.45399 5.97744 183.21715)" class="st4" width="6.90463" height="15.53543"/>
		<polygon class="st5" points="128.91919,78.60114 135.50195,65.68182 130.85858,67.19055 127.41052,73.95782 		"/>
		
			<rect x="103.50258" y="55.76743" transform="matrix(0.45399 -0.89101 0.89101 0.45399 1.78823 129.9884)" class="st4" width="6.90463" height="15.53543"/>
		
			<rect x="89.04525" y="48.40107" transform="matrix(0.45399 -0.89101 0.89101 0.45399 0.45776 113.08452)" class="st4" width="6.90459" height="15.53539"/>
		
			<rect x="106.95066" y="49.00017" transform="matrix(0.45399 -0.89101 0.89101 0.45399 9.70031 129.36526)" class="st4" width="6.90462" height="15.5354"/>
		
			<rect x="92.49332" y="41.63378" transform="matrix(0.45399 -0.89101 0.89101 0.45399 8.37023 112.46194)" class="st4" width="6.9046" height="15.53541"/>
		<polygon class="st5" points="117.5672,68.94238 121.01532,62.17511 119.50659,57.53174 112.92383,70.45111 		"/>
		<path class="st6" d="M53.59375,108.3692c-0.04407-0.65173-0.0863-1.31885-0.12708-1.99475
			c-0.44653-0.42358-0.74652-1.14062-0.7569-1.9599c-0.01001-0.71753,0.2099-1.35681,0.55743-1.79858
			c-0.03973-0.86896-0.07526-1.74988-0.10614-2.64062c-0.30933-0.42792-0.50537-1.02325-0.51459-1.68542
			c-0.00787-0.62085,0.15588-1.1842,0.42389-1.61212c-0.00867-0.44104-0.01611-0.8833-0.02191-1.32672
			c-0.00732-0.56116-0.00336-1.13019,0.00623-1.70258c-0.24591-0.40192-0.40289-0.91809-0.4104-1.49072
			c-0.00922-0.70355,0.2066-1.32941,0.54834-1.75848c0.06769-1.02362,0.15753-2.05438,0.26794-3.08765
			c-0.15228-0.33514-0.24677-0.72913-0.25269-1.15594c-0.00909-0.72913,0.237-1.37476,0.6181-1.7771
			c0.14648-1.03058,0.31049-2.05536,0.49048-3.06995c-0.06873-0.22949-0.1098-0.47992-0.1134-0.74408
			c-0.00916-0.66388,0.21423-1.25031,0.56183-1.61267c0.09863-0.48254,0.20044-0.96082,0.30524-1.43481
			c-1.69031-3.91095-3.8576-8.91772-4.37726-10.08325c-0.97278-2.19623-0.92426-5.4054-1.50592-5.36023
			c-1.89087,0.14197-1.47339,18.91998-0.96771,31.72974c1.72223,4.94189,3.45526,10.03577,5.0791,14.87415
			C53.38757,108.55853,53.4881,108.4585,53.59375,108.3692z"/>
		<path class="st7" d="M70.23651,122.28857l1.05725-0.31879l0.03284-0.63782C71.11938,121.75635,71,122,71,122
			S70.73749,122.09924,70.23651,122.28857z"/>
		<path class="st7" d="M55.5636,78.57227l1.23187-0.01672c0.7655-0.01001,1.39899,0.86578,1.414,1.95557
			c0.01501,1.09479-0.59668,1.98737-1.36218,1.99902l-1.23187,0.01508c-0.58185,0.00757-1.08325-0.49634-1.29895-1.21655
			c-0.17999,1.01459-0.34399,2.03937-0.49048,3.06995c0.24963-0.26361,0.55579-0.42523,0.89117-0.4292l1.3656-0.01837
			c0.85077-0.01172,1.54938,0.95941,1.5661,2.16614c0.01508,1.2135-0.65851,2.20465-1.50928,2.21466l-1.36554,0.01837
			c-0.54895,0.00757-1.03333-0.40204-1.31348-1.01855c-0.11041,1.03326-0.20026,2.06403-0.26794,3.08765
			c0.2785-0.34967,0.63904-0.57123,1.04285-0.57648l1.44745-0.02008c0.89758-0.01166,1.64136,1.01624,1.65808,2.29321
			c0.01837,1.27863-0.70032,2.32831-1.5979,2.34167l-1.44745,0.01837c-0.49652,0.00647-0.93774-0.31189-1.24097-0.8075
			c-0.00958,0.57239-0.01355,1.14142-0.00623,1.70258c0.0058,0.44342,0.01324,0.88568,0.02191,1.32672
			c0.29913-0.47754,0.72919-0.78522,1.21576-0.79138l1.48926-0.02002c0.92596-0.01172,1.68811,1.04626,1.70648,2.36005
			c0.01672,1.32043-0.71869,2.39679-1.64301,2.40851l-1.49091,0.02008c-0.45996,0.0058-0.87939-0.25421-1.18689-0.67969
			c0.03088,0.89075,0.06641,1.77167,0.10614,2.64062c0.28955-0.3681,0.66626-0.60132,1.08563-0.60663l1.48254-0.02002
			c0.92932-0.01172,1.68982,1.04633,1.70819,2.3634c0.01672,1.31708-0.71704,2.39349-1.64636,2.40515l-1.48254,0.02008
			c-0.34943,0.00446-0.67578-0.14532-0.948-0.4035c0.04077,0.6759,0.08301,1.34302,0.12708,1.99475
			c0.2403-0.20331,0.51691-0.32867,0.8194-0.33331l1.4859-0.01843c0.92432-0.01337,1.69147,1.04132,1.7099,2.35675
			c0.01672,1.31543-0.72375,2.39514-1.64636,2.40851l-1.20007,0.01508c-0.30481-0.76678-0.59943-1.5542-0.87897-2.37579
			c1.85413,5.52063,4.35754,11.64612,6.6463,14.79333l4.63562-1.39777l7.37665-31.59998
			c-0.00006-0.00146-0.00012-0.00287-0.00018-0.00433l-0.44739,1.90729c-0.14545,0.06183-0.29584,0.10699-0.45465,0.10864
			l-1.44244,0.01837c-0.90088,0.01343-1.64136-1.01953-1.65802-2.29822c-0.01672-1.27692,0.69696-2.32324,1.59784-2.33496
			l1.44244-0.01837c0.30682-0.00372,0.59015,0.12292,0.83728,0.3299c-0.06042-0.88721-0.13507-1.75952-0.21826-2.62189
			c-0.2749,0.48755-0.69019,0.80768-1.16754,0.81427l-1.3656,0.01837c-0.84741,0.01007-1.55273-0.96442-1.56775-2.17621
			c-0.01672-1.20673,0.66351-2.19458,1.51093-2.20624l1.3656-0.01843c0.31665-0.00372,0.61139,0.12866,0.85815,0.3573
			c-0.11108-0.84027-0.22925-1.6698-0.3595-2.47864c-0.24872,0.43024-0.62,0.71179-1.04504,0.71735l-1.23187,0.01672
			c-0.7655,0.01001-1.40063-0.86749-1.414-1.96222c-0.01508-1.09149,0.59668-1.98236,1.36218-1.99237l1.23187-0.01508
			c0.19098-0.00293,0.37366,0.0495,0.54059,0.14624c-0.1217-0.61029-0.24988-1.20508-0.3811-1.79156
			c-0.2196,0.43408-0.57306,0.72107-0.98236,0.72504l-1.1048,0.01508c-0.67859,0.01001-1.24689-0.77222-1.26025-1.74829
			c-0.01172-0.97778,0.53485-1.77673,1.21509-1.7851l1.10486-0.01337c0.12701-0.00189,0.24744,0.03143,0.36322,0.0802
			c-0.14905-0.56329-0.30212-1.11133-0.4585-1.64539c-0.17242,0.45099-0.50238,0.76221-0.88904,0.76788L67.5979,73.1413
			c-0.56995,0.00665-1.04468-0.65021-1.05469-1.46588c-0.01001-0.81732,0.44458-1.48584,1.01624-1.49255l0.92432-0.01172
			c0.08911-0.00128,0.17438,0.01837,0.25732,0.04724c-0.11816-0.35193-0.2373-0.69604-0.35791-1.03131
			c-0.15698,0.21478-0.36621,0.35358-0.60175,0.35681l-0.79395,0.00836c-0.49475,0.00665-0.90088-0.55658-0.91095-1.26196
			c-0.00836-0.70367,0.38275-1.27863,0.8775-1.28534l0.57471-0.00726c-0.10931-0.25781-0.21881-0.50983-0.32904-0.75348
			c-0.10675,0.09747-0.22607,0.16913-0.36279,0.1709l-0.62848,0.00836c-0.38947,0.005-0.71204-0.44122-0.71875-0.99615
			c-0.00665-0.55658,0.30255-1.00952,0.69202-1.01288l0.10876-0.00146c-0.09338-0.17041-0.18665-0.33881-0.27997-0.49829
			c-0.0351,0.01123-0.06757,0.03125-0.10529,0.03192l-0.45959,0.005c-0.28418,0.00336-0.52319-0.32257-0.5282-0.73041
			c-0.00323-0.26324,0.09583-0.48254,0.23853-0.6142c-0.31989-0.43304-0.63568-0.79401-0.94482-1.08228
			c-0.03833,0.34741-0.23438,0.61774-0.49042,0.62073l-0.46466,0.00671c-0.28418,0.00336-0.51984-0.32428-0.52484-0.73376
			c-0.00348-0.28363,0.10919-0.52173,0.27081-0.64612c-0.14093-0.03857-0.27991-0.06335-0.41473-0.06158
			c-0.09796,0.00122-0.19836,0.02338-0.2995,0.05176c0.17816,0.11426,0.30505,0.36121,0.30872,0.6571
			c0.005,0.40948-0.22235,0.74377-0.50812,0.74713l-0.46301,0.00671c-0.24829,0.00287-0.45099-0.2522-0.50293-0.58844
			c-0.26068,0.28455-0.52637,0.63385-0.79523,1.04346c0.17163,0.11816,0.29523,0.36017,0.29999,0.65045
			c0.005,0.40613-0.224,0.73877-0.51147,0.74213l-0.45966,0.00665c-0.0434,0.00049-0.0824-0.01984-0.12286-0.03363
			c-0.0849,0.16187-0.16974,0.33667-0.25476,0.50879l0.1134-0.00183c0.38776-0.0033,0.71204,0.44128,0.71869,0.99622
			c0.00671,0.55487-0.30585,1.00952-0.6936,1.01453l-0.62683,0.00836c-0.1311,0.00171-0.24792-0.06165-0.35437-0.14917
			c-0.10114,0.24371-0.2019,0.49402-0.30237,0.75018l0.55634-0.00812c0.49304-0.00671,0.90253,0.55823,0.91089,1.2619
			c0.00836,0.70532-0.3844,1.28033-0.8775,1.28699l-0.79724,0.01001c-0.23108,0.00238-0.43988-0.1283-0.60156-0.3327
			c-0.11505,0.33801-0.22894,0.68219-0.34186,1.03412c0.08344-0.03198,0.16962-0.05469,0.26031-0.05573l0.92426-0.01172
			c0.57166-0.0083,1.04468,0.64685,1.0564,1.46588c0.01001,0.81567-0.44629,1.48419-1.01794,1.49255L57.28119,73.276
			c-0.40698,0.00476-0.75922-0.32623-0.93658-0.80676c-0.16455,0.57355-0.32416,1.164-0.48102,1.76239
			c0.14026-0.08167,0.29254-0.13068,0.45349-0.13263l1.10315-0.01501c0.67859-0.00836,1.24689,0.77551,1.26025,1.7533
			c0.01337,0.97449-0.5365,1.77173-1.21344,1.78009l-1.10486,0.01501c-0.47668,0.00586-0.89172-0.37708-1.10614-0.93726
			c-0.06293,0.2724-0.12494,0.54626-0.18591,0.82196c-0.1048,0.474-0.2066,0.95227-0.30524,1.43481
			C54.98877,78.71851,55.26306,78.57617,55.5636,78.57227z M59.62286,83.88525l1.36218-0.01843
			c0.85077-0.01001,1.55609,0.95941,1.57281,2.1662c0.01501,1.21179-0.66522,2.20459-1.51599,2.21631l-1.36218,0.01672
			c-0.85077,0.01172-1.55109-0.96277-1.56616-2.17456C58.0968,84.88477,58.77209,83.89691,59.62286,83.88525z M59.40173,89.75513
			l1.43738-0.01837c0.90094-0.01337,1.64807,1.01453,1.66473,2.2915c0.01672,1.27863-0.70361,2.32996-1.60455,2.34167
			l-1.43744,0.01837c-0.89752,0.01172-1.63965-1.01953-1.65637-2.29822C57.78876,90.81311,58.5025,89.76685,59.40173,89.75513z
			 M57.28802,118.16254c-0.6803-1.23517-1.34552-2.56732-1.97229-4.01477l0.84241-0.01166
			c0.89923-0.01172,1.64301,1.01288,1.65973,2.29315C57.82788,117.11792,57.61896,117.73297,57.28802,118.16254z
			 M60.66711,123.39862c-0.56158-0.75879-1.13654-1.58282-1.71149-2.4837c0.28748-0.36774,0.65851-0.6001,1.07471-0.60675
			l1.47919-0.01837c0.68866-0.00836,1.29034,0.59332,1.55945,1.46246L60.66711,123.39862z M58.2807,116.4234
			c-0.01672-1.28027,0.6936-2.32324,1.59619-2.33661l1.43909-0.01843c0.90094-0.01166,1.64471,1.01294,1.66144,2.29321
			c0.01666,1.27869-0.70038,2.32666-1.60126,2.33832l-1.43909,0.02008C59.03448,118.73169,58.29736,117.70038,58.2807,116.4234z
			 M66.33862,118.63538l-0.72705,0.01007c-0.89758,0.01166-1.64136-1.01794-1.65808-2.29657
			c-0.01672-1.28033,0.70032-2.3233,1.5979-2.33496l1.44244-0.02008c0.14539-0.00165,0.28247,0.03174,0.4162,0.08026
			L66.33862,118.63538z M67.8125,112.35736c-0.22565,0.16882-0.47968,0.27576-0.75549,0.27911l-1.47919,0.02008
			c-0.92432,0.01166-1.69482-1.04803-1.71155-2.36511c-0.01672-1.31537,0.7254-2.38843,1.64966-2.40015l1.47925-0.02008
			c0.68024-0.00836,1.27197,0.56158,1.54437,1.38562L67.8125,112.35736z M69.4035,105.58038
			c-0.20056-0.38776-0.32593-0.86078-0.33258-1.37897c-0.01337-1.09308,0.49304-2.01239,1.19672-2.30151L69.4035,105.58038z
			 M70.61932,95.67316l1.11316-0.01508l-1.11981,4.77362c-0.89594-0.0401-1.62299-1.06805-1.63971-2.35504
			C68.95624,96.76294,69.69171,95.68488,70.61932,95.67316z M68.60791,104.20746c0.01672,1.31708-0.72034,2.39349-1.64465,2.40521
			l-1.4809,0.02002c-0.92432,0.01172-1.69318-1.04462-1.7099-2.36169c-0.01666-1.31543,0.72375-2.3952,1.64807-2.40686
			l1.4809-0.01843C67.82568,101.83234,68.59125,102.89203,68.60791,104.20746z M68.5108,98.08148
			c0.01672,1.32043-0.72205,2.39685-1.64636,2.41022l-1.47919,0.01837c-0.92432,0.01172-1.69482-1.04462-1.71155-2.36505
			c-0.01672-1.31207,0.7254-2.39178,1.64801-2.4035l1.4809-0.01837C67.72693,95.70978,68.49243,96.76941,68.5108,98.08148z
			 M68.07373,91.95575c0.01837,1.27863-0.69696,2.32825-1.59454,2.34161L65.0401,94.3158
			c-0.90088,0.01166-1.64801-1.01959-1.66473-2.29822c-0.01508-1.27698,0.70367-2.3233,1.60455-2.33502l1.43744-0.02002
			C67.31659,89.65082,68.05872,90.67877,68.07373,91.95575z M66.12653,88.18298l-1.36554,0.01672
			c-0.84241,0.01166-1.55109-0.96277-1.56616-2.17456c-0.01501-1.20679,0.66522-2.19623,1.50763-2.2063l1.36725-0.01837
			c0.84906-0.01166,1.55109,0.95941,1.56781,2.1662C67.65253,87.18011,66.97559,88.17126,66.12653,88.18298z M67.31927,80.39197
			c0.01337,1.09479-0.59503,1.9873-1.3656,1.99902l-1.22845,0.01501c-0.76886,0.01001-1.4007-0.86743-1.41571-1.96057
			c-0.01337-1.09143,0.59503-1.9823,1.36554-1.99237l1.22687-0.01666C66.67242,78.42633,67.3042,79.30219,67.31927,80.39197z
			 M66.72491,75.73199c0.01166,0.97443-0.52985,1.77173-1.2168,1.78009l-1.09979,0.01501
			c-0.6853,0.00836-1.2536-0.77386-1.26532-1.74829c-0.01337-0.97778,0.53485-1.77673,1.21851-1.7851l1.09979-0.01501
			C66.1499,73.97034,66.71155,74.75421,66.72491,75.73199z M66.07806,71.68182c0.01001,0.81561-0.44629,1.48419-1.01788,1.49255
			l-0.92432,0.01172c-0.57495,0.00836-1.04797-0.65021-1.05969-1.46417c-0.01001-0.81903,0.44629-1.4859,1.02124-1.49426
			l0.92261-0.01172C65.59332,70.20929,66.06635,70.86279,66.07806,71.68182z M65.61224,68.2973
			c0.00836,0.70538-0.38446,1.27869-0.88086,1.28534l-0.79395,0.01001c-0.49475,0.00671-0.90253-0.55658-0.9126-1.2619
			c-0.00836-0.70367,0.38446-1.27863,0.87921-1.28534l0.79388-0.01001C65.1944,67.02869,65.60388,67.59198,65.61224,68.2973z
			 M65.02454,65.43347c0.00671,0.55493-0.30249,1.00958-0.69196,1.01459l-0.62848,0.00836
			c-0.3894,0.005-0.71368-0.44128-0.72034-0.99615c-0.00836-0.55664,0.3042-1.00958,0.6936-1.01459l0.62848-0.00836
			C64.69531,64.43231,65.01788,64.87689,65.02454,65.43347z M63.46161,62.50385l0.46631-0.00507
			c0.28418-0.005,0.51984,0.31927,0.52484,0.72876c0.005,0.40784-0.22229,0.73877-0.50647,0.74377l-0.4646,0.005
			c-0.28418,0.00507-0.51984-0.32257-0.52484-0.7287C62.95184,62.83807,63.17914,62.50714,63.46161,62.50385z M62.90503,110.30383
			c0.01672,1.31708-0.7254,2.39685-1.64801,2.40851l-1.4826,0.02008c-0.92096,0.01172-1.68646-1.04797-1.70319-2.36505
			c-0.01837-1.31543,0.7204-2.38849,1.64136-2.40021l1.4809-0.02002C62.1178,107.93542,62.88831,108.99011,62.90503,110.30383z
			 M61.19629,106.68854l-1.48254,0.02002c-0.92596,0.01172-1.68811-1.04462-1.70483-2.36334
			c-0.01837-1.31543,0.71704-2.39349,1.64301-2.40521l1.48254-0.02008c0.92267-0.01166,1.69318,1.04633,1.7099,2.3634
			S62.11896,106.67682,61.19629,106.68854z M62.77783,98.1579c0.01837,1.32043-0.72205,2.39685-1.64471,2.40851l-1.4809,0.01837
			c-0.92761,0.01343-1.69147-1.04462-1.70819-2.36505c-0.01672-1.31207,0.71875-2.39014,1.64636-2.4035l1.47925-0.01837
			C61.9939,95.78613,62.76111,96.84418,62.77783,98.1579z M61.59479,62.52887l0.46631-0.00665
			c0.28583-0.00336,0.51984,0.32092,0.52484,0.73041c0.005,0.40613-0.21893,0.73877-0.50641,0.74377l-0.46466,0.005
			c-0.28418,0.00336-0.51819-0.32257-0.52319-0.73041C61.08667,62.86151,61.31067,62.53223,61.59479,62.52887z M61.26971,64.47662
			l0.62842-0.00836c0.38611-0.00336,0.71375,0.43958,0.7204,0.99615c0.00671,0.55493-0.30756,1.00952-0.69366,1.01453
			l-0.62842,0.00836c-0.39111,0.00507-0.71039-0.44122-0.71875-0.99615C60.57104,64.93457,60.8786,64.48328,61.26971,64.47662z
			 M60.94244,67.08337l0.79724-0.01007c0.48975-0.00665,0.90424,0.55829,0.9126,1.26196
			c0.00836,0.70532-0.3894,1.28033-0.87915,1.28534l-0.7973,0.01166c-0.49139,0.00671-0.90088-0.55823-0.90924-1.26355
			C60.05658,67.66504,60.45105,67.09003,60.94244,67.08337z M60.70941,70.27209l0.9176-0.01166
			c0.5733-0.00671,1.04968,0.6485,1.05969,1.46582c0.01172,0.81567-0.44794,1.4859-1.02124,1.49255l-0.9176,0.01172
			c-0.57831,0.00836-1.05133-0.65015-1.06134-1.46582C59.67651,70.94739,60.1311,70.28046,60.70941,70.27209z M60.36163,74.04504
			l1.09979-0.01337c0.68524-0.00836,1.25189,0.77551,1.26526,1.75165c0.01172,0.97607-0.53485,1.77173-1.21851,1.78174
			l-1.09979,0.01501c-0.68524,0.00836-1.25189-0.77386-1.26526-1.74829C59.12976,74.854,59.67798,74.05505,60.36163,74.04504z
			 M60.03571,78.51294l1.23181-0.01508c0.7655-0.01166,1.40063,0.86414,1.414,1.95557
			c0.01508,1.09314-0.59668,1.98737-1.36218,1.99738l-1.23187,0.01672c-0.7655,0.01001-1.39728-0.86749-1.41235-1.96228
			C58.66174,79.41382,59.27014,78.52295,60.03571,78.51294z"/>
		<path class="st8" d="M98.71552,120.78284l-0.44116,0.70502c-1.18567-1.23212-2.22791-2.59454-3.14398-4.04694l-4.89197,8.04376
			H71.01196l-19.22815,8.24939l-17.66864,1.27362v5.02765l19.39026-1.39728l19.06598-8.1391h20.45825l5.82977-9.5647
			C98.80914,120.88599,98.76544,120.83148,98.71552,120.78284z"/>
		<path class="st8" d="M140.42554,102.69513l9.04492-4.0094V93.1015L137.987,98.28058
			c0.00555,0.01111,0.00989,0.02295,0.01544,0.03406L138.64435,98C139.38947,99.48682,139.98633,101.05707,140.42554,102.69513z"/>
		<path class="st9" d="M72.78894,91.16022l-0.24683,1.05737l-7.37665,31.59998l-0.09924,0.42523
			c2.5481-0.96307,4.26349-1.61145,5.17029-1.95422C70.73749,122.09924,71,122,71,122s0.11938-0.24365,0.3266-0.66803
			c1.54926-3.1734,8.1925-16.83783,9.54144-20.57214c3.91278-10.86096,6.56702-29.08954,4.89392-30.55542
			c-0.60669-0.52814-0.68024,1.98401-1.53937,3.60693c-0.86249,1.62799-11.28552,16.71429-11.28552,16.71429l-0.01068,0.04578
			c0.00012,0.00031,0.00037,0.00043,0.00049,0.00073L72.78894,91.16022z"/>
		<path class="st9" d="M36.09277,67.80292c0.49139,1.1416,0.7254,2.08929,1.07141,3.58521
			c0.2323,1.01123,1.07642,4.65161,1.14325,9.85309c0.10864,8.42902,0.13538,10.58014,4.15686,22.00433
			c3.25427,9.23297,7.2807,19.48553,10.15729,25.76172l8.03455-3.61432c-0.04187-0.05573-0.08429-0.11987-0.12628-0.17761
			c-2.28876-3.14722-4.79218-9.27271-6.6463-14.79333c-0.01477-0.04346-0.03064-0.08441-0.04535-0.12805
			c-0.17719-0.53149-0.36102-1.07635-0.54156-1.61456c0.00055-0.00073,0.00116-0.00116,0.00171-0.00189
			c-1.62384-4.83838-3.35687-9.93225-5.0791-14.87415c-4.20111-12.05518-8.33691-23.20508-10.66058-27.52979
			c-0.97778-1.82355-3.52173-1.68146-3.55847-1.11987C33.96509,65.71863,35.36407,66.10974,36.09277,67.80292z"/>
		<path class="st10" d="M61.06964,95.79785l-1.47925,0.01837c-0.92761,0.01337-1.66309,1.09143-1.64636,2.4035
			c0.01672,1.32043,0.78058,2.37848,1.70819,2.36505l1.4809-0.01837c0.92267-0.01166,1.66309-1.08807,1.64471-2.40851
			C62.76111,96.84418,61.9939,95.78613,61.06964,95.79785z"/>
		<path class="st10" d="M56.15814,114.13611l-0.84241,0.01166c0.62677,1.44745,1.29199,2.7796,1.97229,4.01477
			c0.33093-0.42957,0.53986-1.04462,0.52985-1.73328C57.80115,115.14899,57.05737,114.12439,56.15814,114.13611z"/>
		<path class="st10" d="M54.34796,100.65503l1.49091-0.02008c0.92432-0.01172,1.65973-1.08807,1.64301-2.40851
			c-0.01837-1.31378-0.78052-2.37177-1.70648-2.36005l-1.48926,0.02002c-0.48657,0.00616-0.91663,0.31384-1.21576,0.79138
			c-0.26801,0.42792-0.43176,0.99127-0.42389,1.61212c0.00922,0.66217,0.20526,1.25751,0.51459,1.68542
			C53.46857,100.40082,53.888,100.66083,54.34796,100.65503z"/>
		<path class="st10" d="M55.96259,112.78271c0.92261-0.01337,1.66309-1.09308,1.64636-2.40851
			c-0.01843-1.31543-0.78558-2.37012-1.7099-2.35675l-1.4859,0.01843c-0.30249,0.00464-0.5791,0.13-0.8194,0.33331
			c-0.10565,0.08929-0.20618,0.18933-0.29541,0.30829c-0.00055,0.00073-0.00116,0.00116-0.00171,0.00189
			c0.18054,0.53821,0.36438,1.08307,0.54156,1.61456c0.01471,0.04364,0.03058,0.08459,0.04535,0.12805
			c0.27954,0.82159,0.57416,1.60901,0.87897,2.37579L55.96259,112.78271z"/>
		<path class="st10" d="M54.41467,106.77795l1.48254-0.02008c0.92932-0.01166,1.66309-1.08807,1.64636-2.40515
			c-0.01837-1.31708-0.77887-2.37512-1.70819-2.3634l-1.48254,0.02002c-0.41937,0.00531-0.79608,0.23853-1.08563,0.60663
			c-0.34753,0.44177-0.56744,1.08105-0.55743,1.79858c0.01038,0.81927,0.31036,1.53632,0.7569,1.9599
			C53.73889,106.63263,54.06525,106.78241,54.41467,106.77795z"/>
		<path class="st10" d="M62.7312,61.4184c0.005,0.40948,0.24066,0.73712,0.52484,0.73376l0.46466-0.00671
			c0.25604-0.00299,0.45209-0.27332,0.49042-0.62073c0.0047-0.04266,0.01654-0.08167,0.01599-0.1264
			c-0.005-0.40784-0.23901-0.73041-0.52484-0.72705l-0.46631,0.00665c-0.086,0.00104-0.16315,0.03986-0.23395,0.09436
			C62.84039,60.89667,62.72772,61.13477,62.7312,61.4184z"/>
		<path class="st10" d="M60.40839,77.58008l1.09979-0.01501c0.68365-0.01001,1.23022-0.80566,1.21851-1.78174
			c-0.01337-0.97614-0.58002-1.76001-1.26526-1.75165l-1.09979,0.01337c-0.68365,0.01001-1.23187,0.80896-1.21851,1.78674
			C59.15649,76.80621,59.72314,77.58844,60.40839,77.58008z"/>
		<path class="st10" d="M61.19348,107.94714l-1.4809,0.02002c-0.92096,0.01172-1.65973,1.08478-1.64136,2.40021
			c0.01672,1.31708,0.78223,2.37677,1.70319,2.36505l1.4826-0.02008c0.92261-0.01166,1.66473-1.09143,1.64801-2.40851
			C62.88831,108.99011,62.1178,107.93542,61.19348,107.94714z"/>
		<path class="st10" d="M59.46185,94.38831l1.43744-0.01837c0.90094-0.01172,1.62128-1.06305,1.60455-2.34167
			c-0.01666-1.27698-0.76379-2.30487-1.66473-2.2915l-1.43738,0.01837c-0.89923,0.01172-1.61298,1.05798-1.59625,2.33496
			C57.8222,93.36877,58.56433,94.40002,59.46185,94.38831z"/>
		<path class="st10" d="M61.13446,101.91992L59.65192,101.94c-0.92596,0.01172-1.66138,1.08978-1.64301,2.40521
			c0.01672,1.31873,0.77887,2.37506,1.70483,2.36334l1.48254-0.02002c0.92267-0.01172,1.66479-1.08813,1.64807-2.40521
			S62.05713,101.90826,61.13446,101.91992z"/>
		<path class="st10" d="M59.67969,88.26605l1.36218-0.01672c0.85077-0.01172,1.53101-1.00452,1.51599-2.21631
			c-0.01672-1.20679-0.72205-2.17621-1.57281-2.1662l-1.36218,0.01843c-0.85077,0.01166-1.52606,0.99951-1.50934,2.20624
			C58.1286,87.30328,58.82892,88.27777,59.67969,88.26605z"/>
		<path class="st10" d="M69.60876,83.75421c-0.84741,0.01166-1.52765,0.99951-1.51093,2.20624
			c0.01501,1.21179,0.72034,2.18628,1.56775,2.17621l1.3656-0.01837c0.47736-0.00659,0.89264-0.32672,1.16754-0.81427
			c0.21606-0.38312,0.34747-0.86719,0.34009-1.40039c-0.00946-0.75952-0.28876-1.42371-0.7063-1.81055
			c-0.24677-0.22864-0.5415-0.36102-0.85815-0.3573L69.60876,83.75421z"/>
		<path class="st10" d="M63.48169,63.97632l0.4646-0.005c0.28418-0.005,0.51147-0.33594,0.50647-0.74377
			c-0.005-0.40948-0.24066-0.73376-0.52484-0.72876l-0.46631,0.00507c-0.28247,0.0033-0.50977,0.33423-0.50476,0.74377
			C62.96185,63.65375,63.19751,63.98138,63.48169,63.97632z"/>
		<path class="st10" d="M60.74786,73.23053l0.9176-0.01172c0.5733-0.00665,1.03296-0.67688,1.02124-1.49255
			c-0.01001-0.81732-0.48639-1.47253-1.05969-1.46582l-0.9176,0.01166c-0.57831,0.00836-1.0329,0.67529-1.02289,1.49261
			C59.69653,72.58038,60.16956,73.23889,60.74786,73.23053z"/>
		<path class="st10" d="M69.14429,78.39392c-0.7655,0.01001-1.37726,0.90088-1.36218,1.99237
			c0.01337,1.09473,0.6485,1.97223,1.414,1.96222l1.23187-0.01672c0.42505-0.00555,0.79633-0.28711,1.04504-0.71735
			c0.20117-0.34796,0.32385-0.7912,0.31714-1.27997c-0.01001-0.81976-0.36993-1.51752-0.87341-1.80939
			c-0.16693-0.09674-0.34961-0.14917-0.54059-0.14624L69.14429,78.39392z"/>
		<path class="st10" d="M70.61267,100.4317l1.11981-4.77362l-1.11316,0.01508c-0.92761,0.01172-1.66309,1.08978-1.64636,2.4035
			C68.98969,99.36365,69.71674,100.3916,70.61267,100.4317z"/>
		<path class="st10" d="M70.13727,89.61438c-0.90088,0.01172-1.61456,1.05804-1.59784,2.33496
			c0.01666,1.27869,0.75714,2.31165,1.65802,2.29822l1.44244-0.01837c0.15881-0.00165,0.3092-0.04681,0.45465-0.10864
			l0.44739-1.90729l0.24701-1.05304l0.13794-0.58807c-0.00012-0.00031-0.00037-0.00043-0.00049-0.00073
			c-0.1347-0.26465-0.3111-0.47943-0.5094-0.64551c-0.24713-0.20697-0.53046-0.33362-0.83728-0.3299L70.13727,89.61438z"/>
		<path class="st10" d="M68.40332,73.94025c-0.68024,0.00836-1.22681,0.80731-1.21509,1.7851
			c0.01337,0.97607,0.58167,1.7583,1.26025,1.74829l1.1048-0.01508c0.4093-0.00397,0.76276-0.29095,0.98236-0.72504
			c0.1499-0.29626,0.2392-0.65906,0.23444-1.05499c-0.01086-0.79559-0.38831-1.45667-0.89868-1.67145
			c-0.11578-0.04877-0.23621-0.08209-0.36322-0.0802L68.40332,73.94025z"/>
		<path class="st10" d="M69.07092,104.20142c0.00665,0.51819,0.13202,0.99121,0.33258,1.37897l0.86414-3.68048
			C69.56396,102.18903,69.05756,103.10834,69.07092,104.20142z"/>
		<path class="st10" d="M65.48969,65.42731c0.00671,0.55493,0.32928,1.00116,0.71875,0.99615l0.62848-0.00836
			c0.13672-0.00177,0.25604-0.07343,0.36279-0.1709c0.19733-0.18018,0.3335-0.48352,0.32916-0.84363
			c-0.00836-0.55658-0.32928-1.00122-0.71875-0.99451l-0.51965,0.0069l-0.10876,0.00146
			C65.79224,64.41779,65.48303,64.87073,65.48969,65.42731z"/>
		<path class="st10" d="M64.91742,63.22119c0.005,0.40784,0.24402,0.73376,0.5282,0.73041l0.45959-0.005
			c0.03772-0.00067,0.07019-0.02069,0.10529-0.03192c0.23395-0.07489,0.40552-0.3587,0.40118-0.71185
			c-0.00671-0.40948-0.23566-0.73376-0.52484-0.73041l-0.4613,0.00665c-0.10144,0.00122-0.19031,0.05481-0.26959,0.12793
			C65.01324,62.73865,64.91418,62.95795,64.91742,63.22119z"/>
		<path class="st10" d="M65.90192,78.4364l-1.22687,0.01666c-0.77051,0.01007-1.37891,0.90094-1.36554,1.99237
			c0.01501,1.09314,0.64685,1.97058,1.41571,1.96057l1.22845-0.01501c0.77057-0.01172,1.37897-0.90424,1.3656-1.99902
			C67.3042,79.30219,66.67242,78.42633,65.90192,78.4364z"/>
		<path class="st10" d="M66.07654,68.29028c0.01007,0.70538,0.4162,1.26862,0.91095,1.26196l0.79395-0.00836
			c0.23553-0.00323,0.44476-0.14203,0.60175-0.35681c0.17163-0.23486,0.28015-0.56177,0.27576-0.93018
			c-0.00836-0.70367-0.41791-1.26862-0.91095-1.26196l-0.21924,0.00275l-0.57471,0.00726
			C66.45929,67.01166,66.06818,67.58661,66.07654,68.29028z"/>
		<path class="st10" d="M67.55945,70.18286c-0.57166,0.00671-1.02625,0.67523-1.01624,1.49255
			c0.01001,0.81567,0.48474,1.47253,1.05469,1.46588l0.92596-0.01172c0.38666-0.00568,0.71661-0.31689,0.88904-0.76788
			c0.08252-0.21576,0.13208-0.46075,0.12885-0.72467c-0.00848-0.69177-0.34979-1.26172-0.80066-1.41864
			c-0.08295-0.02887-0.16821-0.04852-0.25732-0.04724L67.55945,70.18286z"/>
		<path class="st10" d="M60.08746,82.46753l1.23187-0.01672c0.7655-0.01001,1.37726-0.90424,1.36218-1.99738
			c-0.01337-1.09143-0.6485-1.96722-1.414-1.95557l-1.23181,0.01508c-0.76556,0.01001-1.37396,0.90088-1.3606,1.99231
			C58.69019,81.60004,59.32196,82.47754,60.08746,82.46753z"/>
		<path class="st10" d="M65.02002,70.21594l-0.92261,0.01172c-0.57495,0.00836-1.03125,0.67523-1.02124,1.49426
			c0.01172,0.81396,0.48474,1.47253,1.05969,1.46417l0.92432-0.01172c0.57159-0.00836,1.02789-0.67694,1.01788-1.49255
			C66.06635,70.86279,65.59332,70.20929,65.02002,70.21594z"/>
		<path class="st10" d="M63.90405,67.04541c-0.49475,0.00671-0.88757,0.58167-0.87921,1.28534
			c0.01007,0.70532,0.41785,1.26862,0.9126,1.2619l0.79395-0.01001c0.4964-0.00665,0.88922-0.57996,0.88086-1.28534
			c-0.00836-0.70532-0.41785-1.26862-0.91431-1.2619L63.90405,67.04541z"/>
		<path class="st10" d="M64.36151,73.99371c-0.68365,0.00836-1.23187,0.80731-1.21851,1.7851
			c0.01172,0.97443,0.58002,1.75665,1.26532,1.74829l1.09979-0.01501c0.68695-0.00836,1.22845-0.80566,1.2168-1.78009
			c-0.01337-0.97778-0.57501-1.76166-1.26361-1.7533L64.36151,73.99371z"/>
		<path class="st10" d="M66.0697,83.80048l-1.36725,0.01837c-0.84241,0.01007-1.52264,0.99951-1.50763,2.2063
			c0.01508,1.21179,0.72375,2.18622,1.56616,2.17456l1.36554-0.01672c0.84906-0.01172,1.526-1.00287,1.51099-2.21631
			C67.62079,84.75989,66.91876,83.78882,66.0697,83.80048z"/>
		<path class="st10" d="M60.97583,69.63226l0.7973-0.01166c0.48975-0.005,0.88751-0.58002,0.87915-1.28534
			c-0.00836-0.70367-0.42285-1.26862-0.9126-1.26196l-0.79724,0.01007c-0.49139,0.00665-0.88586,0.58167-0.87585,1.28534
			C60.07495,69.07404,60.48444,69.63898,60.97583,69.63226z"/>
		<path class="st10" d="M63.67737,64.44568c-0.3894,0.005-0.70197,0.45795-0.6936,1.01459
			c0.00665,0.55487,0.33093,1.00116,0.72034,0.99615l0.62848-0.00836c0.38947-0.005,0.69867-0.45966,0.69196-1.01459
			c-0.00665-0.55658-0.32922-1.00116-0.71869-0.99615L63.67737,64.44568z"/>
		<path class="st10" d="M66.80261,95.72314l-1.4809,0.01837c-0.92261,0.01172-1.66473,1.09143-1.64801,2.4035
			c0.01672,1.32043,0.78723,2.37677,1.71155,2.36505l1.47919-0.01837c0.92432-0.01337,1.66309-1.08978,1.64636-2.41022
			C68.49243,96.76941,67.72693,95.70978,66.80261,95.72314z"/>
		<path class="st10" d="M66.99518,107.87122l-1.47925,0.02008c-0.92426,0.01172-1.66638,1.08478-1.64966,2.40015
			c0.01672,1.31708,0.78723,2.37677,1.71155,2.36511l1.47919-0.02008c0.27582-0.00336,0.52985-0.11029,0.75549-0.27911
			l0.72705-3.10052C68.26715,108.4328,67.67542,107.86285,66.99518,107.87122z"/>
		<path class="st10" d="M65.55139,114.01392c-0.89758,0.01166-1.61462,1.05463-1.5979,2.33496
			c0.01672,1.27863,0.7605,2.30823,1.65808,2.29657l0.72705-0.01007l1.07141-4.56128
			c-0.13373-0.04852-0.27081-0.08191-0.4162-0.08026L65.55139,114.01392z"/>
		<path class="st10" d="M61.50952,120.28979l-1.47919,0.01837c-0.4162,0.00665-0.78723,0.23901-1.07471,0.60675
			c0.57495,0.90088,1.1499,1.72491,1.71149,2.4837l2.40186-1.64636C62.79987,120.88312,62.19818,120.28143,61.50952,120.28979z"/>
		<path class="st10" d="M66.41736,89.66254l-1.43744,0.02002c-0.90088,0.01172-1.61963,1.05804-1.60455,2.33502
			c0.01672,1.27863,0.76385,2.30988,1.66473,2.29822l1.43909-0.01843c0.89758-0.01337,1.61292-1.06299,1.59454-2.34161
			C68.05872,90.67877,67.31659,89.65082,66.41736,89.66254z"/>
		<path class="st10" d="M66.90143,101.8457l-1.4809,0.01843c-0.92432,0.01166-1.66473,1.09143-1.64807,2.40686
			c0.01672,1.31708,0.78558,2.37341,1.7099,2.36169l1.4809-0.02002c0.92432-0.01172,1.66138-1.08813,1.64465-2.40521
			C68.59125,102.89203,67.82568,101.83234,66.90143,101.8457z"/>
		<path class="st10" d="M59.93707,118.71997l1.43909-0.02008c0.90088-0.01166,1.61792-1.05963,1.60126-2.33832
			c-0.01672-1.28027-0.7605-2.30487-1.66144-2.29321l-1.43909,0.01843c-0.90259,0.01337-1.61292,1.05634-1.59619,2.33661
			C58.29736,117.70038,59.03448,118.73169,59.93707,118.71997z"/>
		<path class="st10" d="M60.11572,64.021c0.28748-0.00336,0.51648-0.336,0.51147-0.74213
			c-0.00476-0.29028-0.12836-0.53229-0.29999-0.65045c-0.0705-0.04852-0.14667-0.08142-0.22986-0.07996l-0.4613,0.005
			c-0.28583,0.005-0.51312,0.33429-0.50647,0.74377c0.00427,0.34589,0.17773,0.61963,0.40363,0.69678
			c0.04047,0.01379,0.07947,0.03412,0.12286,0.03363L60.11572,64.021z"/>
		<path class="st10" d="M61.29645,66.4873l0.62842-0.00836c0.38611-0.005,0.70038-0.45959,0.69366-1.01453
			c-0.00665-0.55658-0.33429-0.99951-0.7204-0.99615l-0.62842,0.00836c-0.39111,0.00665-0.69867,0.45795-0.69202,1.01453
			C60.58606,66.04608,60.90533,66.49237,61.29645,66.4873z"/>
		<path class="st10" d="M61.62537,62.1734l0.46301-0.00671c0.28577-0.00336,0.51312-0.33765,0.50812-0.74713
			c-0.00366-0.2959-0.13055-0.54285-0.30872-0.6571c-0.06738-0.04321-0.14056-0.07086-0.21948-0.06995l-0.46301,0.00665
			c-0.28412,0.00336-0.51141,0.33264-0.50476,0.74048c0.00043,0.0517,0.0144,0.09674,0.02191,0.14532
			C61.17438,61.9212,61.37708,62.17627,61.62537,62.1734z"/>
		<path class="st10" d="M59.41693,66.51172c0.38776-0.005,0.70032-0.45966,0.6936-1.01453
			c-0.00665-0.55493-0.33093-0.99951-0.71869-0.99622l-0.1134,0.00183l-0.51508,0.00824
			c-0.3894,0.005-0.70032,0.4563-0.69196,1.01288c0.00446,0.3681,0.1546,0.6745,0.36432,0.84698
			c0.10645,0.08752,0.22327,0.15088,0.35437,0.14917L59.41693,66.51172z"/>
		<path class="st10" d="M61.61487,64.0014l0.46466-0.005c0.28748-0.005,0.51141-0.33765,0.50641-0.74377
			c-0.005-0.40948-0.23901-0.73376-0.52484-0.73041l-0.46631,0.00665c-0.28412,0.00336-0.50812,0.33264-0.50311,0.74213
			C61.09668,63.67883,61.33069,64.00476,61.61487,64.0014z"/>
		<path class="st10" d="M54.77405,88.3302l1.36554-0.01837c0.85077-0.01001,1.52435-1.00116,1.50928-2.21466
			c-0.01672-1.20673-0.71533-2.17786-1.5661-2.16614l-1.3656,0.01837c-0.33539,0.00397-0.64154,0.16559-0.89117,0.4292
			c-0.3811,0.40234-0.6272,1.04797-0.6181,1.7771c0.00592,0.42682,0.1004,0.8208,0.25269,1.15594
			C53.74072,87.92816,54.2251,88.33777,54.77405,88.3302z"/>
		<path class="st10" d="M55.61542,82.52521l1.23187-0.01508c0.7655-0.01166,1.3772-0.90424,1.36218-1.99902
			c-0.01501-1.08978-0.6485-1.96558-1.414-1.95557l-1.23187,0.01672c-0.30054,0.00391-0.57483,0.14624-0.79871,0.37964
			c-0.3476,0.36237-0.57098,0.94879-0.56183,1.61267c0.0036,0.26416,0.04468,0.51459,0.1134,0.74408
			C54.53217,82.02887,55.03357,82.53278,55.61542,82.52521z"/>
		<path class="st10" d="M54.29565,94.45599l1.44745-0.01837c0.89758-0.01337,1.61627-1.06305,1.5979-2.34167
			c-0.01672-1.27698-0.7605-2.30487-1.65808-2.29321l-1.44745,0.02008c-0.40381,0.00525-0.76434,0.22681-1.04285,0.57648
			c-0.34174,0.42908-0.55756,1.05493-0.54834,1.75848c0.00751,0.57263,0.16449,1.08881,0.4104,1.49072
			C53.35791,94.1441,53.79913,94.46246,54.29565,94.45599z"/>
		<path class="st10" d="M57.28119,73.276l0.92426-0.01172c0.57166-0.00836,1.02795-0.67688,1.01794-1.49255
			c-0.01172-0.81903-0.48474-1.47418-1.0564-1.46588l-0.92426,0.01172c-0.0907,0.00104-0.17688,0.02374-0.26031,0.05573
			c-0.44543,0.17084-0.76941,0.74878-0.76093,1.43854c0.00342,0.23785,0.04987,0.45898,0.12311,0.65741
			C56.52197,72.94977,56.87421,73.28076,57.28119,73.276z"/>
		<path class="st10" d="M58.72308,69.66187c0.4931-0.00665,0.88586-0.58167,0.8775-1.28699
			c-0.00836-0.70367-0.41785-1.26862-0.91089-1.2619l-0.55634,0.00812l-0.24097,0.00354
			c-0.49139,0.005-0.88586,0.58002-0.87579,1.28369c0.00439,0.37451,0.12555,0.7005,0.30768,0.93085
			c0.16168,0.20441,0.37048,0.33508,0.60156,0.3327L58.72308,69.66187z"/>
		<path class="st10" d="M56.36218,77.63239l1.10486-0.01501c0.67694-0.00836,1.22681-0.8056,1.21344-1.78009
			c-0.01337-0.97778-0.58167-1.76166-1.26025-1.7533L56.31708,74.099c-0.16095,0.00195-0.31323,0.05096-0.45349,0.13263
			c-0.45532,0.26514-0.7735,0.90491-0.76331,1.65247c0.00354,0.29492,0.06268,0.56793,0.15576,0.81104
			C55.47046,77.25531,55.8855,77.63824,56.36218,77.63239z"/>
		<path class="st11" d="M98.71552,120.78284l10.71747-17.12842l21.68494,3.61865l9.31152-4.56464
			c-0.00122-0.00452-0.00269-0.00879-0.00391-0.01331c-0.43921-1.63806-1.03607-3.20831-1.78119-4.69513l-0.64191,0.31464
			l-7.58319,3.71686l-23.42487-3.95795L95,117.25244c0.04028,0.06512,0.08954,0.12372,0.13037,0.18848
			c0.91608,1.45239,1.95831,2.81482,3.14398,4.04694L98.71552,120.78284z"/>
		<path class="st8" d="M60.2196,144.61993c-2.29822,0-5.42383,1.59955-5.42383,6.54535c0,4.94574,3.12561,6.54694,5.44385,6.54694
			c2.31659,0,5.4422-1.6012,5.4422-6.54694C65.68182,146.21948,62.55621,144.61993,60.2196,144.61993z M60.2196,154.89758
			c-1.85529,0-1.92883-2.39014-1.92883-3.7323c0-1.38062,0.07355-3.73401,1.96722-3.73401
			c1.83862,0,1.93054,2.41022,1.93054,3.73401C62.18854,152.52582,62.09662,154.89758,60.2196,154.89758z"/>
		<rect x="68.95282" y="151.20203" class="st8" width="9.19452" height="2.90497"/>
		<path class="st8" d="M113.81396,148.11157c-0.01672-2.03912-0.97449-3.49164-3.23425-3.49164
			c-2.26312,0-3.21918,1.45251-3.23755,3.49164c0.01837,2.04248,0.97443,3.49493,3.23755,3.49493
			C112.83948,151.60651,113.79724,150.15405,113.81396,148.11157z M110.57971,150.00696c-0.84741,0-0.84741-1.04968-0.84741-1.89539
			c0-0.84576,0-1.89209,0.84741-1.89209c0.84576,0,0.84576,1.04633,0.84576,1.89209
			C111.42548,148.95728,111.42548,150.00696,110.57971,150.00696z"/>
		<path class="st8" d="M101.07263,148.86707c-0.95605,0-2.00403,0.16547-2.79462,0.75378l-0.0368-0.03845l0.36774-1.68982h5.95697
			v-3.1272h-8.79004l-1.32379,7.37433h3.42145c0.38611-0.58832,0.9928-0.82904,1.85864-0.82904
			c1.10309,0,1.83685,0.66357,1.83685,1.7851c0,1.01123-0.88251,1.7099-1.94885,1.7099c-0.56995,0-1.08478-0.12872-1.45251-0.42291
			c-0.25739-0.20221-0.44122-0.51477-0.51477-0.8457h-3.62366c-0.10864,1.26862,0.75549,4.17517,5.48059,4.17517
			c3.6972,0,5.73639-1.91376,5.73639-4.50616C105.24622,150.1908,103.35413,148.86707,101.07263,148.86707z"/>
		<path class="st8" d="M90.66632,150.68555v-0.0351c0-0.05518,1.69318-0.05518,1.69318-2.74115
			c0-1.08307-1.23187-3.28937-4.94745-3.28937c-1.43243,0-2.75616,0.34766-3.7323,1.0647
			c-0.97443,0.73712-1.58118,1.84027-1.59955,3.3479h3.45648c-0.03674-1.06805,0.77222-1.60126,1.76672-1.60126
			c0.75378,0,1.6731,0.31427,1.6731,1.12323c0,0.9928-0.97443,1.15662-1.74664,1.15662c-0.4964,0-0.79224-0.05347-1.06805-0.07355
			v2.50214c0.27582-0.0368,0.5332-0.09192,1.06805-0.09192c0.84406,0,1.70819,0.20221,1.70819,1.32373
			c0,1.04803-0.66022,1.43414-1.70819,1.43414c-0.64514,0-1.17834-0.14709-1.526-0.57001
			c-0.34937-0.33093-0.51648-0.8457-0.4964-1.526h-3.495c-0.09192,0.95605-0.07355,5.00256,5.68121,5.00256
			c4.1936,0,5.3703-2.46533,5.3703-4.02814C92.76398,150.83429,90.66632,150.83429,90.66632,150.68555z"/>
		<path class="st8" d="M120.39771,150.724c-2.26147,0-3.21753,1.45245-3.2359,3.49329
			c0.01837,2.04083,0.97443,3.49493,3.2359,3.49493c2.26141,0,3.21918-1.4541,3.2359-3.49493
			C123.61688,152.17645,122.65912,150.724,120.39771,150.724z M120.39771,156.11102c-0.84576,0-0.84576-1.04633-0.84576-1.89374
			c0-0.84576,0-1.89374,0.84576-1.89374c0.84741,0,0.84741,1.04797,0.84741,1.89374
			C121.24512,155.0647,121.24512,156.11102,120.39771,156.11102z"/>
		<path class="st8" d="M51.39612,150.68555v-0.0351c0-0.05518,1.69312-0.05518,1.69312-2.74115
			c0-1.08307-1.23181-3.28937-4.94739-3.28937c-1.43408,0-2.75787,0.34766-3.73401,1.0647
			c-0.97443,0.73712-1.57947,1.84027-1.59955,3.3479h3.45819c-0.03674-1.06805,0.77222-1.60126,1.76501-1.60126
			c0.75549,0,1.6731,0.31427,1.6731,1.12323c0,0.9928-0.97443,1.15662-1.74664,1.15662c-0.49469,0-0.79059-0.05347-1.06635-0.07355
			v2.50214c0.27576-0.0368,0.5332-0.09192,1.06635-0.09192c0.84576,0,1.7099,0.20221,1.7099,1.32373
			c0,1.04803-0.66187,1.43414-1.7099,1.43414c-0.64349,0-1.17664-0.14709-1.526-0.57001
			c-0.3493-0.33093-0.51477-0.8457-0.4964-1.526h-3.49329c-0.09192,0.95605-0.07355,5.00256,5.68121,5.00256
			c4.1936,0,5.3703-2.46533,5.3703-4.02814C53.49377,150.83429,51.39612,150.83429,51.39612,150.68555z"/>
		<polygon class="st8" points="118.15466,144.28735 110.61481,158.04321 112.63727,158.04321 120.17706,144.28735 		"/>
		<polygon class="st3" points="150.76898,152.414 150.73102,152.414 148.79303,144.43402 142.84601,144.43402 142.84601,158 
			146.79797,158 146.79797,149.29797 146.836,149.29797 149.19202,158 152.30798,158 154.664,149.29797 154.70203,149.29797 
			154.70203,158 158.65399,158 158.65399,144.43402 152.70703,144.43402 		"/>
		<path class="st3" d="M134.94202,144.43402h-6.85901V158h6.04199c4.73102,0,6.935-2.64099,6.935-6.802
			C141.06,147.77802,139.46399,144.43402,134.94202,144.43402z M133.89703,154.52301H132.263v-6.612h1.33002
			c3.021,0,3.28699,2.375,3.28699,3.22998C136.88,152.414,136.5,154.52301,133.89703,154.52301z"/>
	</g>
</g>
</svg>
</template>

<script>
export default {
  name: 'SatDmMonitoringIcon',
};
</script>

<style scoped>
#KWS_x5F_icon_x5F_SAT_x5F_DM_x5F_monitoring_x5F_EN_Background .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#E8E6D4;}
	#KWS_x5F_icon_x5F_SAT_x5F_DM_x5F_monitoring_x5F_EN .st1{fill:none;}
	#KWS_x5F_icon_x5F_SAT_x5F_DM_x5F_monitoring_x5F_EN .st2{fill:#808080;}
	#KWS_x5F_icon_x5F_SAT_x5F_DM_x5F_monitoring_x5F_EN .st3{fill:#233737;}
	#KWS_x5F_icon_x5F_SAT_x5F_DM_x5F_monitoring_x5F_EN .st4{fill:#6B96AD;}
	#KWS_x5F_icon_x5F_SAT_x5F_DM_x5F_monitoring_x5F_EN .st5{fill:#5A6969;}
	#KWS_x5F_icon_x5F_SAT_x5F_DM_x5F_monitoring_x5F_EN .st6{fill:#00704F;}
	#KWS_x5F_icon_x5F_SAT_x5F_DM_x5F_monitoring_x5F_EN .st7{fill:#FFBA00;}
	#KWS_x5F_icon_x5F_SAT_x5F_DM_x5F_monitoring_x5F_EN .st8{fill:#0D3434;}
	#KWS_x5F_icon_x5F_SAT_x5F_DM_x5F_monitoring_x5F_EN .st9{fill:#00724D;}
	#KWS_x5F_icon_x5F_SAT_x5F_DM_x5F_monitoring_x5F_EN .st10{fill:#FFCC2B;}
	#KWS_x5F_icon_x5F_SAT_x5F_DM_x5F_monitoring_x5F_EN .st11{fill:#FF6D00;}
</style>
