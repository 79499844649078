
<template>
  <svg width="200px" height="200px" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 64 (93537) - https://sketch.com -->
    <title>toolicon/Field_Data_Management</title>
    <desc>Created with Sketch.</desc>
    <g id="toolicon/Field_Data_Management" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-11">
            <polygon id="Fill-1" fill="#E8E6D4" points="0 200 200 200 200 0 0 0"></polygon>
            <polygon id="Fill-2" fill="#257150" points="34.0004 150.9996 63.0004 101.9996 88.0004 101.9996 72.5004 150.9996"></polygon>
            <polygon id="Fill-3" fill="#FAB900" points="82.0004 150.9996 90.5004 101.9996 113.5004 101.9996 120.5004 150.9996"></polygon>
            <polygon id="Fill-4" fill="#D3D1C7" points="170.0004 150.9996 141.0004 101.9996 116.0004 101.9996 131.5004 150.9996"></polygon>
            <path d="M102.0004,48.9996 C115.8074,48.9996 127.0004,60.0716 127.0004,73.7296 C127.0004,82.8356 118.6664,98.2596 102.0004,119.9996 C85.3334,98.2596 77.0004,82.8356 77.0004,73.7296 C77.0004,60.0716 88.1924,48.9996 102.0004,48.9996 M102.0004,53.9996 C90.9544,53.9996 82.0004,62.9546 82.0004,73.9996 C82.0004,85.0456 90.9544,93.9996 102.0004,93.9996 C113.0454,93.9996 122.0004,85.0456 122.0004,73.9996 C122.0004,62.9546 113.0454,53.9996 102.0004,53.9996" id="Fill-5" fill="#5F696E"></path>
            <polygon id="Fill-7" fill="#FF6C00" points="91 85 113 85 113 63 91 63"></polygon>
            <polygon id="Fill-9" fill="#FEFEFE" points="96.1167 74.1119 94.0007 76.6289 101.0577 81.9999 110.0007 69.8479 107.2237 67.9999 100.3537 77.3369"></polygon>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  name: 'FieldManagementIcon',
};
</script>

<style scoped>

</style>
