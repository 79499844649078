
<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 200 200" xml:space="preserve">
	<defs>
  </defs>
<g id="cornseed">
	<g>
		<rect class="st0" width="200" height="200"/>
		<path class="st1" d="M92.2,123.2c0-0.3,0.2-0.4,0.3-0.7c0.4-0.6,1.1-1,1.9-1c0.4,0,0.8,0,1.2,0.1c0.6,0.1,1.1,0.2,1.7,0.3
			c0.3,0,0.5,0.2,0.7,0.4c0.4,0.4,0.7,1,0.6,1.6c0,0.1,0,0.2,0,0.3c-0.1,0.1-0.3,0.1-0.5,0.1c-0.8,0-1.7-0.1-2.5-0.2
			c-0.9-0.2-1.8-0.4-2.7-0.6C92.6,123.4,92.4,123.2,92.2,123.2"/>
		<path class="st1" d="M93.1,63.2c1,0,1.9,0.1,2.8,0.1c0.6,0,1.3,0.2,1.9,0.2c0.3,0,0.5,0.1,0.5,0.5l0,0c0,0.2,0,0.3,0,0.5
			c-0.1,0.3-0.2,0.6-0.4,0.8c-0.1,0.1-0.3,0.2-0.5,0.2c-0.7,0-1.3-0.1-2-0.2c-0.6-0.1-1.2-0.3-1.7-0.7c-0.4-0.2-0.6-0.6-0.6-1
			C93.1,63.5,93,63.4,93.1,63.2"/>
		<path class="st2" d="M98.9,65.4v-0.9c-0.1-0.4-0.4-0.8-0.8-0.9c-1.1-0.2-1.8-0.4-2.9-0.7c-1.3-0.3-2.6-0.6-3.9-0.9
			c-0.8-0.1-1.7-0.1-2.5-0.1c-0.3,0-3.9,0.4-4.9,0.6c-3.4,1.2-6.8,2.5-10,4c-0.9,0.7-1.7,1.4-2.4,2.3c-0.1,0.1-6,7.8-6.4,8
			c-1.4,0.9-2.8,1.9-4.1,3c-0.5,0.8-1.3,1.4-1.9,2.2c-0.2,0.3-0.4,0.6-0.6,0.9c0.5-0.3,0.9-0.7,1.4-1.1c0.9-0.7,1.8-1.4,2.8-2
			c0.4-0.1,1.7-0.8,2-0.8c0.9-0.5,1.7-1.1,2.5-1.7c0.7-0.8,7.5-8.2,7.8-8.5c0.3-0.4,0.8-0.7,1.2-0.9c0.9-0.3,3.5-1.5,4.2-1.8
			c0.7-0.3,4-1.7,4.4-1.9c0.4-0.2,5.8-0.6,6.6-0.6c0.4,0,0.9,0.1,1.2,0.3c1.6,0,3.2,0.1,4.7,0.3c0.3,0,0.5,0.1,0.5,0.5l0,0
			c0,0.1,0,0.1,0,0.2h0.1c0,0,0,0,0.1,0h0.1c0.2,0,0.2,0.2,0.3,0.3c0.1,0.2,0.1,0.4,0.2,0.6C98.9,65.7,98.9,65.5,98.9,65.4"/>
		<path class="st3" d="M125.3,91.5c-0.6-2.3-1.6-4.6-2.2-7.7c-2.1-2.8-3.9-5.9-5.5-9c-2.3-1.2-5.3-2.8-7.8-3.8
			c-3.9-0.6-6,0.2-8.5-2.8c-1.5-1.8-1.1-3.4-0.6-3.8c-0.8,0.6,3.7,0.5,4.6,0.2c0,0,0.7-1.5,0.7-1.5c1.3-0.4,2.7-0.5,4-0.2
			c4.9,1.1,10.6,2.5,11.9,3.7c3,2.6,7.4,7.9,10.5,10.4c2.4,1.9,5.2,2.8,7.6,4.9c7.1,6.2,14,9.9,20.2,18.1
			c5.4,7.2,23.6,36.2,23.6,36.2H155c0,0-3-13.5-8.2-17.1c-3.2-2.2-2-1.5-6.4-0.7c-7.4,1.5-11.1-0.8-16.2,0.6
			c-4.7,1.3-11.6,6.4-16.7,4.8c-1.7-0.5-4.2-4-7,0.3c-0.9,0.1-0.7-2.2-0.6-2.3c0.4-5.6,7.4-4.8,10.7-6.6c2.4-1.3,5.2-4.1,7.7-5.8
			c2.9-2,4.1-3.8,5.4-7.6L125.3,91.5z"/>
		<path class="st1" d="M106,63.2c-1,0-1.9,0.1-2.8,0.1c-0.6,0-1.3,0.2-1.9,0.2c-0.3,0-0.5,0.1-0.5,0.5l0,0c0,0.2,0,0.3,0,0.5
			c0.1,0.3,0.2,0.6,0.4,0.8c0.1,0.1,0.3,0.2,0.5,0.2c0.7,0,1.3-0.1,2-0.2c0.6-0.1,1.2-0.3,1.7-0.7c0.4-0.2,0.6-0.6,0.6-1
			C106,63.5,106,63.4,106,63.2"/>
		<path class="st3" d="M73.7,91.5c0.6-2.3,1.6-4.6,2.2-7.7c2.1-2.8,3.9-5.9,5.5-9c2.4-1.2,5.4-2.8,7.8-3.8c3.9-0.6,6,0.2,8.5-2.8
			c1.5-1.8,1.1-3.4,0.6-3.8c0.8,0.6-3.7,0.5-4.6,0.2c0,0-0.7-1.5-0.7-1.5c-1.3-0.4-2.7-0.5-4-0.2c-4.9,1.1-10.6,2.5-11.9,3.7
			c-3,2.7-7.4,8-10.5,10.4c-2.5,2-5.2,2.8-7.7,5c-7.1,6.2-14,9.8-20.2,18c-5.4,7.2-23.6,36.2-23.6,36.2h26.7c0,0,5.3-13.5,10.4-17.1
			c3.2-2.2,2-1.5,6.4-0.7c7.4,1.5,11.1-0.8,16.2,0.6c4.7,1.3,11.6,6.4,16.7,4.8c1.7-0.5,4.2-4,7,0.3c0.9,0.1,0.7-2.2,0.6-2.3
			c-0.4-5.6-7.4-4.8-10.7-6.6c-2.4-1.3-5.2-4.1-7.7-5.8c-2.9-2-4.1-3.8-5.4-7.6L73.7,91.5z"/>
		<path class="st1" d="M106.9,123.2c0-0.3-0.2-0.4-0.3-0.7c-0.4-0.6-1.1-1-1.9-1c-0.4,0-0.8,0-1.2,0.1c-0.6,0.1-1.1,0.2-1.7,0.3
			c-0.3,0-0.5,0.2-0.7,0.4c-0.4,0.4-0.7,1-0.6,1.6c0,0.1,0,0.2,0,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.8,0,1.7-0.1,2.5-0.2
			c0.9-0.2,1.8-0.4,2.7-0.6C106.4,123.4,106.6,123.2,106.9,123.2"/>
		<path class="st2" d="M100.1,65.4v-0.9c0.1-0.4,0.4-0.8,0.8-0.9c1.1-0.2,1.8-0.4,2.9-0.7c1.3-0.3,2.6-0.6,3.9-0.9
			c0.8-0.1,1.7-0.1,2.5-0.1c0.3,0,3.9,0.4,4.9,0.6c3.4,1.2,6.8,2.5,10,4c0.9,0.6,1.7,1.4,2.4,2.3c0.1,0.1,6,7.8,6.4,8
			c1.4,0.9,2.8,1.9,4.1,3c0.5,0.8,1.3,1.4,1.9,2.2c0.2,0.3,0.4,0.6,0.6,0.9c-0.5-0.3-0.9-0.7-1.4-1.1c-0.9-0.7-1.8-1.4-2.8-2
			c-0.4-0.1-1.7-0.8-2-0.8c-0.9-0.5-1.7-1.1-2.5-1.7c-0.7-0.8-7.5-8.2-7.8-8.5c-0.3-0.4-0.8-0.7-1.2-0.9c-0.9-0.3-3.5-1.5-4.2-1.8
			c-0.7-0.3-4-1.7-4.4-1.9s-5.8-0.6-6.6-0.6c-0.4,0-0.9,0.1-1.2,0.3c-1.6,0-3.2,0.1-4.7,0.3c-0.3,0-0.5,0.1-0.5,0.5l0,0
			c0,0.1,0,0.1,0,0.2H101c0,0,0,0-0.1,0h-0.1c-0.2,0-0.2,0.2-0.3,0.3c-0.1,0.2-0.1,0.4-0.2,0.6C100.1,65.7,100.2,65.5,100.1,65.4"/>
		<path class="st4" d="M93.9,96.7c0,0-1-23.3,0.5-23.4c0.3,0,0.2,1.7,0.7,2.9s3.2,8.1,3.2,8.1v25.6L93.9,96.7z"/>
		<path class="st5" d="M97.8,110.1l8.3-2.5l0.2-4.1l0.5-10.8c-0.1-10.6-3.5-19-5.5-19c-2,0-5.4,10.8-5.2,19.2
			c0.1,5.2,0.5,10.4,1.1,15.6L97.8,110.1z"/>
		<path class="st6" d="M97.5,85.8h0.7c0.4,0,0.8-0.5,0.7-1.1s-0.4-1.1-0.8-1.1h-0.6c-0.4,0-0.8,0.5-0.7,1.1S97.1,85.8,97.5,85.8"/>
		<path class="st6" d="M100.5,93.1h-0.8c-0.5,0-0.9,0.6-0.9,1.3s0.4,1.3,0.9,1.3h0.8c0.5,0,0.9-0.6,0.9-1.3S101,93.1,100.5,93.1"/>
		<path class="st6" d="M97.8,103.3h-0.5c0.3,0.8,0.7,1.5,1.1,2.2c0.2-0.3,0.3-0.6,0.3-1C98.8,103.8,98.3,103.2,97.8,103.3"/>
		<path class="st6" d="M97.6,93.2h-0.8c-0.5,0-0.9,0.6-0.9,1.3s0.4,1.3,0.9,1.3h0.8c0.5,0,0.9-0.6,0.9-1.3S98.1,93.2,97.6,93.2"/>
		<path class="st6" d="M97.1,102.5h0.7c0.5,0,0.9-0.6,0.9-1.3s-0.4-1.3-1-1.3h-0.8c-0.2,0-0.5,0.1-0.6,0.3c0.1,0.3,0.2,0.6,0.3,0.9
			C96.7,101.6,96.9,102.1,97.1,102.5"/>
		<path class="st6" d="M96.9,96.6c-0.5,0-0.9,0.6-0.9,1.3c0,0.7,0.4,1.3,0.9,1.3h0.8c0.5,0,0.9-0.6,0.9-1.3c0-0.7-0.4-1.3-0.9-1.3
			H96.9z"/>
		<path class="st6" d="M101.7,74.5h0.3c0.2,0,0.3-0.2,0.3-0.4s-0.1-0.4-0.3-0.4h-0.3c-0.1,0-0.3,0.2-0.3,0.4S101.6,74.5,101.7,74.5"
			/>
		<path class="st6" d="M100.8,81.1h-0.6c-0.4,0-0.7,0.4-0.7,1s0.3,1,0.7,1h0.6c0.4,0,0.7-0.4,0.7-1S101.1,81.1,100.8,81.1"/>
		<path class="st6" d="M99.8,102.5h0.8c0.5,0,0.9-0.6,0.9-1.3c0-0.7-0.4-1.3-1-1.3h-0.8c-0.5,0-0.9,0.6-0.9,1.3
			S99.3,102.5,99.8,102.5"/>
		<path class="st6" d="M99.7,92.4h0.8c0.5,0,0.9-0.6,0.9-1.3c0-0.7-0.4-1.3-0.9-1.3h-0.8c-0.5,0-0.9,0.6-0.9,1.3
			S99.2,92.4,99.7,92.4"/>
		<path class="st6" d="M99.8,96.5c-0.5,0-0.9,0.6-0.9,1.3s0.4,1.3,0.9,1.3h0.8c0.5,0,0.9-0.6,0.9-1.3s-0.4-1.3-0.9-1.3H99.8z"/>
		<path class="st6" d="M98.9,87.8c0,0.7,0.4,1.2,0.9,1.2h0.7c0.5,0,0.8-0.6,0.8-1.2s-0.4-1.2-0.9-1.2h-0.8
			C99.3,86.5,98.9,87.1,98.9,87.8"/>
		<path class="st6" d="M105.3,86.5c-0.5,0-0.8,0.6-0.8,1.2s0.4,1.2,0.9,1.2h0.7c0.5,0,0.8-0.6,0.8-1.2s-0.4-1.2-0.9-1.2H105.3z"/>
		<path class="st6" d="M101.9,74.7c-0.2,0-0.3,0.2-0.3,0.4s0.1,0.4,0.3,0.4h0.2c0.2,0,0.3-0.2,0.3-0.4c0-0.2-0.1-0.4-0.3-0.4
			L101.9,74.7z"/>
		<path class="st6" d="M100.9,79h-0.5c-0.3,0-0.6,0.4-0.6,0.8s0.3,0.8,0.6,0.8h0.5c0.3,0,0.6-0.4,0.6-0.8S101.2,79,100.9,79"/>
		<path class="st6" d="M105,83.5c-0.4,0-0.8,0.5-0.7,1.1s0.3,1.1,0.8,1.1h0.7c0.4,0,0.8-0.5,0.7-1.1c0-0.6-0.4-1.1-0.8-1.1H105z"/>
		<path class="st6" d="M105.8,95.7l0.6-2.6h-0.6c-0.5,0-0.9,0.6-0.9,1.3S105.3,95.7,105.8,95.7"/>
		<path class="st6" d="M106.3,89.7h-0.8c-0.5,0-0.9,0.6-0.9,1.3s0.4,1.3,0.9,1.3h0.8c0.1,0,0.2,0,0.2-0.1l0.5-2
			C106.9,89.9,106.6,89.7,106.3,89.7"/>
		<path class="st6" d="M104.6,81.1c-0.4,0-0.7,0.4-0.7,1s0.3,1,0.7,1h0.6c0.4,0,0.7-0.4,0.7-1s-0.3-1-0.7-1H104.6z"/>
		<path class="st6" d="M105,97.8c0,0.3,0.1,0.5,0.2,0.8l0.5-2C105.2,96.8,104.9,97.3,105,97.8"/>
		<path class="st6" d="M103,76.3c0,0.3,0.2,0.6,0.4,0.6h0.3c0.2,0,0.4-0.3,0.4-0.6s-0.2-0.6-0.4-0.6h-0.3
			C103.2,75.8,103,76,103,76.3"/>
		<path class="st6" d="M103,75.5h0.3c0.2,0,0.3-0.2,0.3-0.4s-0.1-0.4-0.3-0.4h-0.3c-0.1,0-0.3,0.2-0.3,0.4S102.8,75.5,103,75.5"/>
		<path class="st6" d="M103.2,83.5h-0.7c-0.4,0-0.8,0.5-0.8,1.1c0,0.6,0.4,1.1,0.8,1.1h0.7c0.4,0,0.8-0.5,0.8-1.1
			S103.6,83.5,103.2,83.5"/>
		<path class="st6" d="M103.3,77.9c0,0.4,0.2,0.7,0.5,0.7h0.4c0.3,0,0.5-0.3,0.5-0.7s-0.2-0.7-0.5-0.7h-0.4
			C103.5,77.2,103.3,77.5,103.3,77.9"/>
		<path class="st6" d="M104.1,79c-0.3,0-0.6,0.4-0.6,0.8s0.3,0.8,0.6,0.8h0.5c0.3,0,0.6-0.4,0.6-0.8s-0.3-0.8-0.6-0.8L104.1,79z"/>
		<path class="st6" d="M100.7,85.8c0.4,0,0.8-0.5,0.7-1.1s-0.3-1.1-0.8-1.1H100c-0.4,0-0.8,0.5-0.7,1.1s0.4,1.1,0.8,1.1L100.7,85.8z
			"/>
		<path class="st6" d="M102.2,80.6h0.5c0.3,0,0.6-0.4,0.6-0.8c0-0.5-0.3-0.8-0.6-0.8h-0.5c-0.3,0-0.6,0.4-0.6,0.8
			S101.9,80.6,102.2,80.6"/>
		<path class="st6" d="M102.1,78.6h0.4c0.3,0,0.5-0.3,0.5-0.7s-0.2-0.7-0.5-0.7h-0.4c-0.3,0-0.5,0.3-0.5,0.7S101.9,78.6,102.1,78.6"
			/>
		<path class="st6" d="M102.4,83h0.6c0.4,0,0.7-0.4,0.7-1s-0.3-1-0.7-1h-0.6c-0.4,0-0.7,0.4-0.7,1S102,83,102.4,83"/>
		<path class="st6" d="M102.6,88.9h0.8c0.5,0,0.8-0.6,0.8-1.2s-0.4-1.2-0.9-1.2h-0.8c-0.5,0-0.8,0.6-0.8,1.2S102.1,88.9,102.6,88.9"
			/>
		<path class="st6" d="M100.9,77.2h-0.4c-0.3,0-0.5,0.3-0.5,0.7s0.2,0.7,0.5,0.7h0.4c0.3,0,0.5-0.3,0.5-0.7S101.2,77.2,100.9,77.2"
			/>
		<path class="st6" d="M102,76.9h0.3c0.2,0,0.4-0.3,0.4-0.6s-0.2-0.6-0.4-0.6H102c-0.2,0-0.4,0.2-0.4,0.6S101.8,76.9,102,76.9"/>
		<path class="st6" d="M102.9,95.7h0.8c0.5,0,0.9-0.6,0.9-1.3s-0.4-1.3-1-1.3h-0.8c-0.5,0-0.9,0.6-0.9,1.3
			C101.9,95.2,102.4,95.7,102.9,95.7"/>
		<path class="st6" d="M103,102.5h0.8c0.2,0,0.3-0.1,0.4-0.2l0.4-1.7c-0.1-0.4-0.4-0.7-0.8-0.8H103c-0.5,0-0.9,0.6-0.9,1.3
			C102.1,101.9,102.5,102.5,103,102.5"/>
		<path class="st6" d="M104,103.2c-0.1,0-0.2,0-0.2,0H103c-0.5,0-0.9,0.6-0.9,1.3s0.4,1.3,0.9,1.3h0.4L104,103.2z"/>
		<path class="st6" d="M100.8,106.7H100c-0.2,0-0.5,0.1-0.6,0.3c0.3,0.5,0.6,0.9,1,1.4l1.3-0.9C101.6,107,101.3,106.7,100.8,106.7"
			/>
		<path class="st6" d="M102.7,92.3h0.8c0.5,0,0.9-0.6,0.9-1.3s-0.4-1.3-0.9-1.3h-0.8c-0.5,0-0.9,0.6-0.9,1.3
			C101.8,91.7,102.2,92.3,102.7,92.3"/>
		<path class="st6" d="M103,99.1h0.8c0.5,0,0.9-0.6,0.9-1.3s-0.4-1.3-0.9-1.3h-0.8c-0.5,0-0.9,0.6-0.9,1.3
			C102,98.5,102.5,99.1,103,99.1"/>
		<path class="st6" d="M100.7,103.2h-0.8c-0.5,0-0.9,0.6-0.9,1.3s0.4,1.3,0.9,1.3h0.8c0.5,0,0.9-0.6,0.9-1.3
			C101.6,103.8,101.2,103.2,100.7,103.2"/>
		<path class="st6" d="M99.8,75.6h0.3c0.2,0,0.3-0.2,0.3-0.4s-0.1-0.4-0.3-0.4h-0.3c-0.2,0-0.3,0.2-0.3,0.4S99.6,75.6,99.8,75.6"/>
		<path class="st6" d="M100.7,76.9h0.3c0.2,0,0.4-0.2,0.4-0.6s-0.2-0.6-0.4-0.6h-0.3c-0.2,0-0.4,0.2-0.4,0.6S100.5,76.9,100.7,76.9"
			/>
		<path class="st6" d="M100.9,74.5h0.3c0.2,0,0.3-0.2,0.3-0.4s-0.1-0.4-0.3-0.4h-0.3c-0.2,0-0.3,0.2-0.3,0.4S100.7,74.5,100.9,74.5"
			/>
		<path class="st6" d="M99.3,76.9h0.3c0.2,0,0.4-0.2,0.4-0.6c0-0.3-0.2-0.6-0.4-0.6h-0.3c-0.2,0-0.4,0.2-0.4,0.6
			S99.1,76.9,99.3,76.9"/>
		<path class="st6" d="M100.9,75.5h0.2c0.2,0,0.3-0.2,0.3-0.4s-0.1-0.4-0.3-0.4h-0.3c-0.1,0-0.3,0.2-0.3,0.4
			C100.6,75.4,100.7,75.6,100.9,75.5"/>
		<path class="st6" d="M97.1,89h0.8c0.5,0,0.8-0.6,0.8-1.2c0-0.7-0.4-1.2-0.9-1.2h-0.8c-0.5,0-0.8,0.6-0.8,1.2S96.6,89,97.1,89"/>
		<path class="st6" d="M96.8,92.4h0.8c0.5,0,0.9-0.6,0.9-1.3c0-0.7-0.4-1.3-0.9-1.3h-0.8c-0.5,0-0.9,0.6-0.9,1.3
			S96.3,92.4,96.8,92.4"/>
		<path class="st6" d="M98.5,80.7H99c0.3,0,0.6-0.4,0.6-0.8c0-0.5-0.3-0.8-0.6-0.8h-0.5c-0.3,0-0.6,0.4-0.6,0.8S98.1,80.7,98.5,80.7
			"/>
		<path class="st6" d="M98.8,78.7h0.4c0.3,0,0.5-0.3,0.5-0.7s-0.2-0.7-0.5-0.7h-0.4c-0.3,0-0.5,0.3-0.5,0.7S98.5,78.7,98.8,78.7"/>
		<path class="st6" d="M98,83.1h0.6c0.4,0,0.7-0.4,0.7-1c0-0.6-0.3-1-0.7-1h-0.6c-0.4,0-0.7,0.4-0.7,1S97.6,83.1,98,83.1"/>
		<path class="st4" d="M113,79.7c-0.5,0.9-6.2,8.9-6.2,8.9l-5,19.8c-2.5-2.6-4.4-5.8-5.5-9.3c-3-9.1-6.7-20.3-8.5-23.7
			c-0.5-1-1.9-0.9-1.9-0.6c0,0.3,0.7,0.5,1.1,1.4c0.2,0.6,0.4,1.3,0.5,1.9c0.4,1.7,0.6,3.5,0.6,5.3c0,4.5,0,5.7,2.1,11.8
			c2.5,7.3,5.9,15.9,7,17c0.2,0.1,0.3,0.2,0.5,0.3v1.5c0,0.4,0.3,0.7,0.6,0.7c0,0,0,0,0,0h5.6c0.4,0,0.7-0.3,0.7-0.6c0,0,0,0,0,0
			v-1.5c0.5,0,1-0.2,1.4-0.5c1.6-1.4,0.4-7.4,1.2-9.9c0-0.1,0-0.1,0-0.2c1.5-2.5,2.7-5,3.8-7.7c2.2-5.8,3.8-15.6,2.9-16.4
			C113.5,77.5,113.5,78.8,113,79.7z"/>
	</g>
</g>
</svg>
</template>

<script>
export default {
  name: 'CornseedIcon',
};
</script>

<style scoped>
#cornseed .st0{fill:#E8E6D4;}
	#cornseed .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#F1CAA3;}
	#cornseed .st2{fill-rule:evenodd;clip-rule:evenodd;fill:#DBA776;}
	#cornseed .st3{fill-rule:evenodd;clip-rule:evenodd;fill:#F6B878;}
	#cornseed .st4{fill-rule:evenodd;clip-rule:evenodd;fill:#257150;}
	#cornseed .st5{fill-rule:evenodd;clip-rule:evenodd;fill:#FAB900;}
	#cornseed .st6{fill-rule:evenodd;clip-rule:evenodd;fill:#FADE64;}
</style>
