
<template>
  <svg width="200px" height="200px" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 64 (93537) - https://sketch.com -->
    <title>Crossicon/Consultants</title>
    <desc>Created with Sketch.</desc>
    <g id="Crossicon/Consultants" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon id="Hintergrund" fill="#E8E6D4" points="0 0 200 0 200 200 0 200"></polygon>
        <polygon id="Path-25" fill="#CDB4A6" points="86 101.762194 84.5 131.762194 117 131.762194 115 101.762194"></polygon>
        <path d="M38,143.262194 C39.9169945,123.133752 54.2366676,111.733121 80.9590193,109.060301 L80.9590202,109.06031 C82.9344702,108.862722 84.7559068,110.143957 85.2375921,112.069943 C85.6961905,113.903618 86.1169932,115.134368 86.5,115.762194 C88.7629457,119.471622 92.5434026,121.9294 93.5,123.262194 C95.952917,126.679758 96.5702137,134.262194 99.5,134.262194 C103.113071,134.262194 105.491294,127.459732 108.5,122.262194 C109.336279,120.817523 112.918956,118.237853 115,113.762194 C115.078505,113.593355 115.12842,113.264958 115.149745,112.777003 L115.149747,112.777003 C115.2462,110.569972 117.113544,108.85901 119.320576,108.955464 C119.352159,108.956844 119.383724,108.958599 119.415266,108.960727 C144.947029,110.683787 158.975274,122.11761 161.5,143.262194 C160.5,150.262194 147,154.262194 136.5,154.262194 C129.5,160.762194 107,161.762194 99.5,161.762194 C92,161.762194 72,160.762194 63,154.262194 C58,154.262194 39.5,152.762194 38,143.262194 Z" id="Path-24" fill="#FF6C00"></path>
        <path d="M115,103.262194 C128.5,111.262194 119.5,118.262194 119.5,118.262194 C116.411009,118.262194 113.767044,118.968999 111.526174,120.059155 C105.974395,129.413267 103.798675,137.81398 105,145.262194 L105,145.262194 L97,145.262194 L97,145.262194 L97.0027635,144.852433 C97.0130024,144.094573 97.0517816,143.33687 97.1205869,142.578998 C97.0885128,135.827627 94.575859,128.339251 89.5833351,120.113616 C87.3192161,118.992279 84.6394495,118.262194 81.5,118.262194 C81.5,118.262194 72.5,111.262194 86,103.262194 C86,119.167497 95.6289457,118.003306 99.3260099,129.253292 C99.7490908,129.947676 100.13986,130.639729 100.500385,131.330353 C100.860659,130.638734 101.252035,129.945682 101.674831,129.25096 C105.372749,118.00351 115,119.166097 115,103.262194 Z" id="Combined-Shape" fill="#F2F2F2"></path>
        <path d="M100.198576,108.762194 C110.153176,108.762194 118.692258,99.8059292 122.327325,88.093747 C125.909297,87.891471 126.859221,82.708558 127.856839,78.7621942 C129.5,72.2621942 126,70.7621942 124.177266,70.9247826 C123.976862,56.1015947 121.89182,44.7621942 100.198576,44.7621942 C78.5003205,44.7621942 76.1985758,58.2621942 76.2197472,70.9350561 C74.1985758,70.7621942 71.1985758,71.7621942 72.1985758,78.7621942 C72.1985758,82.908262 75.1985758,87.7621942 77.9720365,87.7740759 C81.5499623,99.6425685 90.1536008,108.762194 100.198576,108.762194 Z" id="Oval" fill="#DAC7BC"></path>
        <path d="M78.5,72.2621942 C77.7739685,71.5276885 77.2739685,71.1069571 77,71 C76.352823,70.7473425 75.9055328,71.3049421 75.8999532,70.7720999 C75.8767469,68.5559645 75.9100959,66.8859959 76,65.7621942 C76.142918,63.9757196 76.4762513,62.9757196 77,62.7621942 L84.5,62.7621942 C80.1666667,66.4288608 78.6666667,71.7621942 80,78.7621942 C79.4370468,79.6535368 78.9370468,77.4868701 78.5,72.2621942 Z" id="Path-28" fill="#645041"></path>
        <path d="M117.785434,78.7621942 C118.265366,75.4026644 117.199132,71.9812284 116.157984,70.8903468 C115.785434,70.5 116.173943,67.3936302 116.285434,66 C116.428352,64.2135255 116.595018,63.1342568 116.785434,62.7621942 L124.285434,62.7621942 C120.285434,68.0955275 118.785434,73.4288608 119.785434,78.7621942 C119.785434,78.7621942 117.785434,78.7621942 117.785434,78.7621942 Z" id="Path-28" fill="#645041" transform="translate(120.142717, 70.762194) scale(-1, 1) translate(-120.142717, -70.762194) "></path>
        <path d="M99.2574758,38.0000201 L100,38.0039033 C125,38.2456125 124,47.2621942 124.202806,66.520485 C124.137992,66.5977319 123.469746,65.4806499 121.989294,63.9247314 C121.75414,65.9808613 117.233605,69.9721035 116.372093,68.529636 C113.165983,63.1615028 105.984619,62.6521266 100.200544,62.7780647 C94.4164692,62.9040028 87.5756289,63.0544851 84.6511628,68.2085831 C84.1395349,69.2621942 78,68.2621942 78,63.7621942 C78,63.7228779 78.0016963,63.6823648 78.0051157,63.6407057 C76.4917518,65.3425091 75.8658911,66.6023599 75.7971939,66.520485 C75,48.7621942 75,37.7621942 100,38.0039033 Z" id="Combined-Shape" fill="#5F696E"></path>
        <path d="M100.173712,61.7783128 C108.245213,61.598483 113.578833,62.9746289 116.538693,67.4997313 L116.731,67.805 L116.854455,67.7617193 C117.270924,67.5960287 117.834911,67.2154337 118.40957,66.7042348 C119.597575,65.6474224 120.5,64.3253994 120.5,63.7621942 C120.5,63.2099094 120.947715,62.7621942 121.5,62.7621942 C122.052285,62.7621942 122.5,63.2099094 122.5,63.7621942 C122.5,65.0634828 121.320215,66.7918291 119.738865,68.1985487 C117.860902,69.8691269 116.045509,70.5913742 115.136349,69.0337271 C112.740063,64.9282113 107.921119,63.6062 100.218261,63.7778166 L99.3285632,63.8008279 C91.3401597,64.0380765 87.9105127,65.0217337 85.9034321,68.6373144 C85.3098216,69.8881836 83.1096613,69.8329079 81.1630581,68.9847513 C78.960197,68.0249402 77.5,66.2068394 77.5,63.7621942 C77.5,63.2099094 77.9477153,62.7621942 78.5,62.7621942 C79.0522847,62.7621942 79.5,63.2099094 79.5,63.7621942 C79.5,65.3248891 80.4311267,66.4842411 81.9619419,67.1512343 C82.5885029,67.4242339 83.2582795,67.5778814 83.798553,67.5914549 C84.0147532,67.5968866 84.1861351,67.5784114 84.2851382,67.548184 L84.2920927,67.4344449 C85.770193,64.9648731 88.1290213,63.4482981 91.3028664,62.6387854 C93.5873921,62.0561003 95.5884463,61.8887202 99.7225054,61.7887786 L100.173712,61.7783128 Z" id="Path" fill="#FF6C00" fill-rule="nonzero"></path>
        <path d="M75,70.2621942 C77.5101661,70.2621942 78.908044,71.3909697 78.9956177,73.5435295 L79,73.7621942 C79.5522847,73.7621942 80,74.2099094 80,74.7621942 L80,82.7621942 C80,83.3127785 79.5550374,83.7594363 79.0050982,83.7621814 C79.179004,92.8818435 84.1459388,98.2940853 94.0289844,100.093649 C94.1560985,98.8605059 94.7199451,98.7694606 96.1427142,98.7627312 L96.5,98.7621942 C98.4329966,98.7621942 99,99.6576247 99,100.762194 C99,101.866764 98.4329966,102.762194 96.5,102.762194 C94.6845866,102.762194 94.0740355,102.762194 94.006474,101.10542 C83.558696,99.2492456 78.1828746,93.4366724 78.0045862,83.7632311 L78,83.7621942 C77.4477153,83.7621942 77,83.3144789 77,82.7621942 L77,74.7621942 C77,74.2099094 77.4477153,73.7621942 78,73.7621942 C78,72.0569127 77.0728284,71.2621942 75,71.2621942 C74.7238576,71.2621942 74.5,71.0383365 74.5,70.7621942 C74.5,70.4860518 74.7238576,70.2621942 75,70.2621942 Z" id="Combined-Shape" fill="#233737" fill-rule="nonzero"></path>
        <g id="Group-14" transform="translate(112.000000, 140.000000)">
            <polygon id="Fill-1" fill="#FFFFFF" points="0 10.8732 10.873 10.8732 10.873 0.0002 0 0.0002"></polygon>
            <polygon id="Fill-2" fill="#247050" points="3.2508 8.5485 2.6708 9.4565 8.2008 9.4565 7.6208 8.5485"></polygon>
            <polygon id="Fill-3" fill="#247050" points="3.7746 7.7268 3.3826 8.3428 7.4886 8.3428 7.0966 7.7268"></polygon>
            <polygon id="Fill-4" fill="#247050" points="4.1661 7.1121 3.8861 7.5511 6.9861 7.5511 6.7051 7.1121"></polygon>
            <g id="Group-13" transform="translate(3.000000, 1.873200)">
                <path d="M3.9331,3.2444 C3.9331,4.0874 3.2631,4.7684 2.4361,4.7684 C1.6091,4.7684 0.9381,4.0874 0.9381,3.2444 C0.9381,2.4024 1.6091,1.7214 2.4361,1.7214 C3.2631,1.7214 3.9331,2.4024 3.9331,3.2444" id="Fill-5" fill="#FF6B00"></path>
                <path d="M4.3383,0.1204 C4.3743,0.1314 4.4153,0.1254 4.4383,0.0944 L4.4513,0.0794 L4.5663,0.0794 L4.5663,0.4564 L4.4293,0.4564 C4.4193,0.4304 4.4083,0.4034 4.3893,0.3794 C4.2983,0.2664 4.1573,0.2234 4.0073,0.2234 C3.8663,0.2234 3.7433,0.2674 3.7433,0.3704 C3.7433,0.4064 3.7713,0.4434 3.8083,0.4594 C3.8663,0.4824 3.9093,0.4874 3.9603,0.4944 C4.0803,0.5124 4.1963,0.5244 4.3163,0.5444 C4.4273,0.5624 4.4983,0.5844 4.5553,0.6624 C4.6203,0.7514 4.6383,0.8644 4.6033,0.9694 C4.5793,1.0584 4.5193,1.1284 4.4423,1.1804 C4.3793,1.2174 4.3083,1.2374 4.2353,1.2524 C4.1193,1.2664 3.9983,1.2654 3.8883,1.2404 L3.6963,1.1974 C3.6773,1.1934 3.6473,1.2004 3.6313,1.2164 C3.6163,1.2304 3.6113,1.2514 3.6113,1.2514 L3.4823,1.2514 L3.4823,0.8534 L3.6053,0.8534 C3.6883,1.0184 3.8723,1.0924 4.0573,1.0984 C4.1593,1.1004 4.2683,1.0954 4.3243,1.0094 C4.3423,0.9714 4.3423,0.9204 4.3163,0.8884 C4.2683,0.8284 4.1903,0.8314 4.1153,0.8244 C3.9813,0.8104 3.8893,0.7964 3.8893,0.7964 C3.7813,0.7764 3.6713,0.7584 3.5833,0.6914 C3.5033,0.6294 3.4653,0.5174 3.4753,0.4274 C3.4813,0.3314 3.5373,0.2314 3.6153,0.1664 C3.6603,0.1294 3.7153,0.1044 3.7693,0.0834 C3.8603,0.0484 3.9753,0.0474 4.0763,0.0544 C4.1703,0.0614 4.2543,0.0884 4.3383,0.1204" id="Fill-7" fill="#1D1D1B"></path>
                <path d="M3.1372,0.303 C3.1402,0.295 3.1422,0.287 3.1422,0.279 C3.1422,0.239 3.1102,0.206 3.0712,0.206 L3.0012,0.206 L3.0012,0.079 L3.4932,0.079 L3.4932,0.206 L3.4762,0.206 C3.4242,0.205 3.3762,0.228 3.3482,0.265 C3.3112,0.315 3.2972,0.371 3.2732,0.423 L2.9512,1.251 L2.7782,1.251 L2.5012,0.459 L2.1882,1.251 L2.0322,1.251 L1.7002,0.292 C1.6822,0.244 1.6582,0.206 1.6052,0.206 L1.5662,0.206 L1.5662,0.079 L2.1542,0.079 L2.1542,0.206 C2.1162,0.207 2.0762,0.2 2.0502,0.227 C2.0082,0.273 2.0322,0.333 2.0502,0.384 L2.1942,0.795 L2.4672,0.079 L2.6952,0.079 C2.6952,0.085 2.8952,0.662 2.9452,0.806 L3.1372,0.303 Z" id="Fill-9" fill="#1D1D1B"></path>
                <path d="M1.0747,0.2844 C1.0847,0.2754 1.0907,0.2634 1.0907,0.2504 C1.0907,0.2254 1.0707,0.2054 1.0467,0.2054 L1.0127,0.2054 L1.0127,0.0794 L1.5117,0.0794 L1.5117,0.2054 L1.4747,0.2054 C1.4057,0.2054 1.3437,0.2824 1.2937,0.3274 L1.0917,0.5134 C1.1457,0.5924 1.5207,1.0274 1.5207,1.0274 C1.5657,1.0764 1.5957,1.1244 1.7247,1.1244 L1.7487,1.1244 L1.7487,1.2514 L1.5177,1.2514 L1.0467,1.2514 L1.0467,1.1244 C1.0967,1.1244 1.1587,1.1294 1.1717,1.0744 C1.1787,1.0444 1.1647,1.0204 1.1457,0.9984 L0.8967,0.6924 L0.7177,0.8594 L0.7177,1.0424 C0.7177,1.0734 0.7407,1.1244 0.7977,1.1244 L0.8597,1.1244 L0.8597,1.2514 L0.2507,1.2514 L0.2507,1.1244 C0.3307,1.1244 0.3747,1.0884 0.3977,1.0274 C0.4137,0.9954 0.4137,0.9284 0.4137,0.9284 L0.4127,0.3634 C0.4127,0.3084 0.4137,0.2054 0.3177,0.2054 L0.2507,0.2054 L0.2507,0.0794 L0.8597,0.0794 L0.8597,0.2054 L0.8097,0.2054 C0.7237,0.2054 0.7177,0.3114 0.7177,0.3474 L0.7177,0.6194 L1.0747,0.2844 Z" id="Fill-11" fill="#1D1D1B"></path>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  name: 'ConsultantsIcon',
};
</script>

<style scoped>

</style>
