
<template>
  <svg width="42px" height="38px" viewBox="0 0 42 38" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Frostwarning</title>
    <g id="Use-Case:-Frost-Event" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="BSS/Tablet/Frostevent_3" transform="translate(-805.000000, -213.000000)">
            <g id="Frostwarning" transform="translate(805.000000, 213.000000)">
                <g id="Group-5">
                    <path d="M23.7657416,2.4283923 L40.9863523,32.6551179 C41.8065216,34.0947319 41.3043625,35.9266482 39.8647485,36.7468175 C39.4122634,37.0046049 38.9004666,37.1401658 38.3797006,37.1401658 L3.93847925,37.1401658 C2.281625,37.1401658 0.938479247,35.79702 0.938479247,34.1401658 C0.938479247,33.6193999 1.07404015,33.1076031 1.33182758,32.6551179 L18.5524383,2.4283923 C19.3726076,0.988778332 21.2045238,0.486619193 22.6441378,1.30678847 C23.1118622,1.57325793 23.4992722,1.96066793 23.7657416,2.4283923 Z" id="Triangle" fill="#CC3333"></path>
                    <polygon id="Shape" fill="#FFFFFF" points="29.5833246 23.9126072 26.0387556 24.9268242 22.5300094 22.9868284 26.0048183 20.9847376 29.5663559 21.9368597 29.9566356 20.4823333 26.8212004 19.6449927 27.6620928 16.5157851 26.2046716 16.1262806 25.2506547 19.6807443 21.7532209 21.6960067 21.7532209 17.6654819 24.3607416 15.065022 23.2936001 14 20.9990573 16.2899854 18.7045145 14 17.637373 15.065022 20.2448937 17.6673636 20.2448937 21.72235 16.6908977 19.7560109 15.6746622 16.2184821 14.2247827 16.6343299 15.1203519 19.7466026 12 20.6403931 12.4166754 22.0873928 15.959359 21.0731758 19.4681052 23.0131716 15.9951817 25.0152624 12.4336441 24.0631403 12.0433644 25.5157851 15.1769142 26.3550073 14.3379072 29.4823333 15.7953284 29.8718378 16.7493453 26.317374 20.2448937 24.3039933 20.2448937 28.3326364 17.637373 30.934978 18.7045145 32 20.9990573 29.708133 23.2936001 32 24.3607416 30.934978 21.7532209 28.3326364 21.7532209 24.27765 25.3072169 26.2439891 26.3234524 29.7815179 27.7733319 29.3656701 26.8796481 26.2515158 30 25.3596069"></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  name: 'FrostwarningIcon',
};
</script>

<style scoped>

</style>
