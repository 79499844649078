
<template>
  <svg width="200px" height="200px" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 64 (93537) - https://sketch.com -->
    <title>Notification/Marketing</title>
    <desc>Created with Sketch.</desc>
    <g id="Notification/Marketing" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon id="Fill-1-Copy" fill="#E8E6D4" points="0 200 200 200 200 0 0 0"></polygon>
        <path d="M144.414236,96.1673611 L163.959375,100.888542 L162.328819,107.638542 L142.783681,102.917361 L144.414236,96.1673611 Z M161.792361,69.6958333 L164.504167,76.0888889 L141.588194,85.809375 L138.876389,79.4163194 L161.792361,69.6958333 Z M137.136806,49.0510417 L143.502778,51.8260417 L135.467361,70.2590278 L129.101389,67.4840278 L137.136806,49.0510417 Z" id="Combined-Shape" fill="#FAB900"></path>
        <path d="M63.6002734,83.8729858 L80.2402374,129.590911 L77.2326285,130.482234 C74.7286902,131.231222 72.2336446,132.003787 69.7691394,132.860118 L68.2942743,133.38445 L64.1276772,134.900967 C59.8370407,136.462631 55.2570628,134.937073 52.6476344,131.583293 C44.6023459,133.12385 36.43378,128.600897 33.5789378,120.757283 C30.678949,112.789629 34.0739399,104.197292 41.0602751,100.127379 C40.9066797,95.9774635 43.320921,91.9530694 47.4383216,90.316727 L47.7216825,90.2088852 L52.0113793,88.6475633 C55.4978477,87.3785926 58.948915,86.0123578 62.3340135,84.462822 L63.6002734,83.8729858 Z M100.46067,61.2039791 L124.06006,126.04277 L116.404618,126.181866 C107.615868,126.365317 99.6464293,126.793572 87.619356,129.431517 L86.773613,129.619169 L69.5983875,82.430625 C80.9792958,76.5432896 87.5719602,71.6281103 94.5550557,66.0010008 L100.46067,61.2039791 Z M43.0234081,107.047268 C39.8375318,109.734992 38.4350738,114.270165 39.9519332,118.437702 C41.4339842,122.509604 45.278212,125.060096 49.3422404,125.183383 L49.626107,125.188035 L43.0234081,107.047268 Z" id="Shape" fill="#9CB9C8"></path>
        <path d="M125.170351,43.9432947 C121.388351,43.9432947 118.388351,46.9432947 118.388351,50.7252947 L118.388351,125.595295 C118.388351,129.378295 121.388351,132.378295 125.170351,132.378295 C128.953351,132.378295 131.953351,129.378295 131.953351,125.595295 L131.953351,50.7252947 C131.953351,46.9432947 128.953351,43.9432947 125.170351,43.9432947" id="Fill-5" fill="#3D738E" transform="translate(125.170851, 88.160795) rotate(-20.000000) translate(-125.170851, -88.160795) "></path>
        <path d="M90.2158722,138.518045 C84.9978722,137.997045 79.7808722,137.605045 74.6948722,137.605045 L70.1288722,137.605045 L69.4768722,137.605045 L72.6068722,156.388045 C73.3898722,161.344045 77.6948722,164.866045 82.6508722,164.866045 C88.9108722,164.866045 93.6068722,159.257045 92.5638722,153.127045 L90.2158722,138.518045 Z" id="Fill-3" fill="#5D686E" transform="translate(81.093104, 151.235545) rotate(-20.000000) translate(-81.093104, -151.235545) "></path>
    </g>
</svg>
</template>

<script>
export default {
  name: 'SaveMykwsEmailServiceIcon',
};
</script>

<style scoped>

</style>
