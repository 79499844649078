
<template>
  <svg width="200px" height="200px" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 64 (93537) - https://sketch.com -->
    <title>Crossicon/Shop</title>
    <desc>Created with Sketch.</desc>
    <g id="Crossicon/Shop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon id="Hintergrund" fill="#E8E6D4" points="0 0 200 0 200 200 0 200"></polygon>
        <rect id="Rectangle" fill="#AA7373" x="50" y="82" width="98" height="70"></rect>
        <rect id="Rectangle" fill="#233737" fill-rule="nonzero" x="68" y="97" width="62" height="55"></rect>
        <polygon id="Fill-9" fill="#233737" fill-rule="nonzero" points="34 157.01 167 157.01 167 154 34 154"></polygon>
        <polygon id="Path" fill="#5A6969" fill-rule="nonzero" points="74 75 74 79.999 125 79.999 125 75 141 75 141 79.999 150 80 150 83 48 83 48 80 58 79.999 58 75"></polygon>
        <polygon id="Fill-43" fill="#FF6C00" fill-rule="nonzero" points="47 77 150 77 150 40 47 40"></polygon>
        <path d="M99.008,65.032 C102.016,65.032 103.856,63.64 103.856,61.288 C103.856,59.432 102.704,58.328 100.688,57.864 L98.352,57.32 C97.6,57.128 97.264,56.808 97.264,56.328 C97.264,55.72 97.824,55.304 98.784,55.304 C99.84,55.304 100.464,55.784 100.592,56.648 L103.488,56.648 C103.36,54.344 101.696,53 98.8,53 C95.6,53 94.272,54.648 94.272,56.584 C94.272,58.376 95.408,59.4 97.216,59.832 L99.456,60.36 C100.32,60.568 100.816,60.84 100.816,61.496 C100.816,62.232 100.192,62.632 99.072,62.632 C97.76,62.632 97.04,62.088 96.96,61 L94,61 C94.128,63.608 95.984,65.032 99.008,65.032 Z M108.795826,64.664 L108.795826,60.008 L112.619826,60.008 L112.619826,64.664 L115.611826,64.664 L115.611826,53.272 L112.619826,53.272 L112.619826,57.4 L108.795826,57.4 L108.795826,53.272 L105.803826,53.272 L105.803826,64.664 L108.795826,64.664 Z M123.207652,65.032 C126.471652,65.032 128.855652,62.696 128.855652,58.984 C128.855652,55.288 126.471652,53 123.207652,53 C119.943652,53 117.591652,55.288 117.591652,58.984 C117.591652,62.696 119.943652,65.032 123.207652,65.032 Z M123.207652,62.584 C121.559652,62.584 120.583652,61.24 120.583652,58.984 C120.583652,56.776 121.559652,55.448 123.207652,55.448 C124.855652,55.448 125.863652,56.776 125.863652,58.984 C125.863652,61.24 124.855652,62.584 123.207652,62.584 Z M133.827478,64.664 L133.827478,60.968 L135.939478,60.968 C138.611478,60.968 140.179478,59.416 140.179478,57.096 C140.179478,54.792 138.595478,53.272 136.083478,53.272 L130.835478,53.272 L130.835478,64.664 L133.827478,64.664 Z M135.491478,58.6 L133.827478,58.6 L133.827478,55.688 L135.603478,55.688 C136.611478,55.688 137.235478,56.168 137.235478,57.128 C137.235478,58.088 136.643478,58.6 135.491478,58.6 Z" id="SHOP" fill="#FFFFFF" fill-rule="nonzero"></path>
        <rect id="Rectangle" fill="#878F92" x="69" y="98" width="60" height="9"></rect>
        <g id="Group-2" transform="translate(47.000000, 40.000000)" fill-rule="nonzero">
            <polygon id="Fill-43" fill="#FFFFFF" points="0 37 37 37 37 0 0 0"></polygon>
            <polygon id="Fill-45" fill="#257150" points="27.9054765 32.1780159 9.08862766 32.1780159 11.0609802 29.0876634 25.933124 29.0876634"></polygon>
            <polygon id="Fill-47" fill="#257150" points="11.5101542 28.3867468 25.4831929 28.3867468 24.1487318 26.2924743 12.8446153 26.2924743"></polygon>
            <polygon id="Fill-49" fill="#257150" points="23.7722433 25.696175 22.8163527 24.1991651 14.1773729 24.1991651 13.2233751 25.696175"></polygon>
            <path d="M23.5928009,17.4158029 C23.5928009,20.2807369 21.3119134,22.6023545 18.4972414,22.6023545 C15.6825694,22.6023545 13.4016819,20.2807369 13.4016819,17.4158029 C13.4016819,14.5508689 15.6825694,12.2292512 18.4972414,12.2292512 C21.3119134,12.2292512 23.5928009,14.5508689 23.5928009,17.4158029" id="Fill-51" fill="#FF6D00"></path>
            <path d="M24.9719334,6.78529862 C25.093076,6.82190504 25.2350399,6.7987852 25.310754,6.6947459 L25.3580754,6.64272625 L25.7442173,6.64272625 L25.7442173,7.92587759 L25.2804684,7.92587759 C25.2482899,7.83725152 25.2104328,7.74862545 25.144183,7.66577934 C24.835648,7.28237527 24.3567563,7.13209628 23.845686,7.13209628 C23.3667943,7.13209628 22.9484738,7.28430192 22.9484738,7.63495289 C22.9484738,7.75825872 23.0393308,7.88349121 23.1680448,7.93743751 C23.3649014,8.01643031 23.512544,8.03184354 23.6866864,8.05689004 C24.0955426,8.11468965 24.4911488,8.16092934 24.8943264,8.22643556 C25.2747898,8.29001513 25.517075,8.36322797 25.710146,8.62717952 C25.9316097,8.9315908 25.992181,9.31692153 25.8729313,9.6714258 C25.7934315,9.97776374 25.5852177,10.2128155 25.324004,10.388141 C25.1101116,10.5172268 24.8697194,10.5865863 24.6236485,10.6347526 C24.2261494,10.6848456 23.8135075,10.6790657 23.4406155,10.5962196 L22.7875814,10.4497939 C22.7232244,10.4343807 22.6172246,10.4594272 22.5661176,10.5133735 C22.5169034,10.5634665 22.4998677,10.6308993 22.4998677,10.6308993 L22.0569402,10.6308993 L22.0569402,9.28031511 L22.4752607,9.28031511 C22.7591886,9.84097133 23.3876157,10.0895097 24.0141499,10.1107028 C24.3643277,10.1203361 24.733434,10.0991429 24.9246121,9.80629156 C24.9832905,9.67913242 24.9832905,9.50573359 24.8962193,9.39591433 C24.733434,9.19168904 24.4646489,9.20324897 24.2147923,9.17820247 C23.7548291,9.13196278 23.4425084,9.08186978 23.4425084,9.08186978 C23.0771878,9.01829021 22.7005101,8.95471064 22.4014394,8.72929216 C22.1307615,8.51543361 22.0020475,8.13588284 22.034226,7.8256916 C22.0550474,7.50008713 22.2462255,7.16292274 22.5112249,6.94135757 C22.6664388,6.81227177 22.8500455,6.72942567 23.0355451,6.65621283 C23.3459729,6.53868695 23.7359006,6.53290699 24.0803998,6.55795349 C24.398399,6.58492664 24.6861126,6.67355271 24.9719334,6.78529862" id="Fill-53" fill="#171714"></path>
            <path d="M20.8848858,7.4062591 C20.8943501,7.37928595 20.9000286,7.35231279 20.9000286,7.32148634 C20.9000286,7.18469393 20.792136,7.07487467 20.6577435,7.07487467 L20.4211369,7.07487467 L20.4211369,6.64330425 L22.0982044,6.64330425 L22.0982044,7.07487467 L22.0357403,7.07487467 C21.8615978,7.07294801 21.6988125,7.15001416 21.602277,7.2771733 C21.4754559,7.44671882 21.4300274,7.63745754 21.3467419,7.81278302 L20.252673,10.633404 L19.662103,10.633404 L18.7213552,7.93801551 L17.6537862,10.633404 L17.1237875,10.633404 L15.9937544,7.36772602 C15.9312902,7.20588712 15.8498976,7.07487467 15.6700766,7.07487467 L15.5375769,7.07487467 L15.5375769,6.64330425 L17.5364293,6.64330425 L17.5364293,7.07487467 C17.4115011,7.07872797 17.2752157,7.05753478 17.1862516,7.14616085 C17.0423948,7.3022198 17.1237875,7.50837174 17.1843587,7.68177057 L17.6727147,9.08052113 L18.6058911,6.64330425 L19.378175,6.64330425 C19.3800679,6.66257078 20.059602,8.62968417 20.2280659,9.11712755 L20.8848858,7.4062591 Z" id="Fill-55" fill="#171714"></path>
            <path d="M13.8652415,7.34229419 C13.89742,7.31339439 13.9182414,7.27293466 13.9182414,7.22476832 C13.9182414,7.14192221 13.8519915,7.07448934 13.7705989,7.07448934 L13.6551349,7.07448934 L13.6551349,6.64291892 L15.3530237,6.64291892 L15.3530237,7.07448934 L15.2280955,7.07448934 C14.9933817,7.07448934 14.7794894,7.33651423 14.6091326,7.48679322 L13.9220271,8.12066227 C14.1075267,8.38846713 15.3833094,9.8700638 15.3833094,9.8700638 C15.5385233,10.035756 15.6388445,10.1995216 16.0798792,10.1995216 L16.1574861,10.1995216 L16.1574861,10.6330187 L15.3738451,10.6330187 L13.7705989,10.6330187 L13.7705989,10.1995216 C13.9428485,10.1995216 14.1529551,10.2149348 14.1945979,10.0319027 C14.219205,9.92593675 14.1699908,9.84887061 14.1094195,9.77180446 L13.2614215,8.73141148 L12.6481372,9.29784766 L12.6481372,9.92208345 C12.6481372,10.0280494 12.727637,10.1995216 12.9226009,10.1995216 L13.1346004,10.1995216 L13.1346004,10.6330187 L11.0619266,10.6330187 L11.0619266,10.1995216 C11.3344974,10.1995216 11.4821399,10.0781424 11.5635326,9.8700638 C11.6146396,9.76217119 11.6146396,9.53097275 11.6146396,9.53097275 L11.6108539,7.61009905 C11.6108539,7.4251403 11.6146396,7.07448934 11.2909618,7.07448934 L11.0619266,7.07448934 L11.0619266,6.64291892 L13.1346004,6.64291892 L13.1346004,7.07448934 L12.9661365,7.07448934 C12.6727443,7.07448934 12.6481372,7.43670022 12.6481372,7.5580794 L12.6481372,8.48287316 L13.8652415,7.34229419 Z" id="Fill-57" fill="#171714"></path>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  name: 'ShopIcon',
};
</script>

<style scoped>

</style>
