
<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200">
  <g fill="none" fill-rule="evenodd">
    <polygon fill="#E8E6D4" points="0 200 200 200 200 0 0 0"/>
    <polygon fill="#AFB4B6" points="145.01 76.11 151.588 82.687 151.588 165.951 139.263 165.951 139.263 81.913"/>
    <polygon fill="#AFB4B6" points="130.018 91.247 130.019 165.951 117.694 165.951 117.693 103.692"/>
    <polygon fill="#AFB4B6" points="107.129 114.359 107.129 165.951 94.804 165.951 94.804 119.681 101.86 119.68"/>
    <polygon fill="#AFB4B6" points="84.24 119.685 84.24 165.951 71.915 165.951 71.915 119.688"/>
    <polygon fill="#AFB4B6" points="63.111 119.921 63.112 165.951 50.787 165.951 50.786 132.362"/>
    <polygon fill="#FF6C00" points="159.69 44.07 128.25 52.33 134.78 58.86 93.87 100.18 55.35 100.18 39.63 116.04 44.41 131.75 61.27 114.73 99.79 114.72 145 69.08 151.43 75.51"/>
  </g>
</svg>
</template>

<script>
export default {
  name: 'SuKwsMarketIcon',
};
</script>

<style scoped>

</style>
